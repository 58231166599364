import CookieConsents from 'utils/cookieConsents'


// N.B.: This isn't about determinining whether an iframe is
// *replaceable* (i.e., with a placeholder UI), it's about whether it
// is *blockable*.  The two criteria are different, so don't get them
// twisted in your head and don't try to put anything in here that
// inspects the size of the element.
//
export default function isBlockableIframe(iframe) {
  if ( iframe.tagName !== 'IFRAME' ) {
    return false
  }

  if ( iframe.dataset.autoblockIgnore ) {
    return false
  }

  // Iframe has already been handled and we have a blocked message ID for it.
  if ( iframe.dataset.termlyIframeId ) {
    return false
  }

  // I have to confess to being confused by this. Why is the iframe
  // not blockable if it has no categories? I'm too scared to touch
  // this.
  if ( !iframe.dataset.categories ) {
    return false
  }

  const categories = iframe.dataset.categories.split(',')

  return !CookieConsents.isAnyConsented(categories)
}
