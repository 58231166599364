import ACModel from '../ac-model'
import ACString from '../ac-string'


export default function makeACModel({ acString, vendorIDs }) {
  const model = new ACModel()

  // When we have an AC string already in place from a previous
  // visit, we use it to set the consents on the model. This also
  // has the effect of "registering," if you will, which vendor IDs
  // are valid for the current website...
  if ( acString ) {
    ACString.decode(acString, model)
  }

  // However, the *canonical* list of known vendors comes from the
  // backend and is passed-in via the `vendorIDs` argument. It is
  // entirely possible--even likely--that since the AC string was
  // last created, vendors have either been added or removed. So, we
  // have to call `setVendorIDs()` here to prune consents for
  // removed vendorIDs and we also have to add vendorIDs to the
  // model for the newly-added vendors.
  model.setVendorIDs(vendorIDs)

  return model
}
