import Categories, {
  ESSENTIAL,
} from './categories'


export default {
  OPT_IN: buildMapping({ defaultValue: false }),
  OPT_OUT: buildMapping({ defaultValue: true }),
}

function buildMapping({ defaultValue }) {
  const entries = Categories.values
    .map((category) => {
      const value = ( category === ESSENTIAL ) ? true : defaultValue

      return [category, value]
    })

  return Object.fromEntries(entries)
}
