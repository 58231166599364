import React from 'react'
import PropTypes from 'prop-types'

import { useIntl } from '@termly_web/common'

import ConsentConfigProvider from 'components/ConsentConfigProvider'
import DisplayManager from 'components/DisplayManager'
import GACMProvider from 'components/GACMProvider'
import GVLProvider from 'components/GVLProvider'
import TCFProvider from 'components/TCFProvider'
import ThemeProvider from 'components/ThemeProvider'
import WebsiteProvider from 'components/WebsiteProvider'


export default function LocalizedAppContent({ children, consentConfig, website }) {
  const { locale } = useIntl()

  return (
    <GVLProvider
      lang={ locale }
      websiteUUID={ website.uuid }
    >
      <GACMProvider
        websiteUUID={ website.uuid }
      >
        <TCFProvider
          lang={ locale }
          websiteUUID={ website.uuid }
        >
          <WebsiteProvider
            website={ website }
          >
            <ConsentConfigProvider
              consentConfig={ consentConfig }
            >
              <ThemeProvider>
                <DisplayManager>
                  { children }
                </DisplayManager>
              </ThemeProvider>
            </ConsentConfigProvider>
          </WebsiteProvider>
        </TCFProvider>
      </GACMProvider>
    </GVLProvider>
  )
}

LocalizedAppContent.propTypes = {
  children: PropTypes.node,
  consentConfig: ConsentConfigProvider.propTypes.consentConfig,
  website: WebsiteProvider.propTypes.website,
}
