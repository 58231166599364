export default function augmentElementDataset(originalElement) {
  const element = originalElement.cloneNode()

  // I'm not sure why, but in Cypress tests, at least, there are times when
  // document.currentScript is not defined.
  //
  // There are a number of situatiions which would cause
  // `currentScript` to return null, one of which is documented at
  // MDN:
  //
  // "It's important to note that this will not reference the
  // <script> element if the code in the script is being called as a
  // callback or event handler; it will only reference the element
  // while it's initially being processed."
  // -- https://developer.mozilla.org/en-US/docs/Web/API/Document/currentScript
  //
  if ( !document.currentScript ) {
    element.dataset.termlyUnknownParent = '1'

    return element
  }

  const { dataset } = document.currentScript

  // This is essential for handling temporarily-unblocked iframes, which may
  // try to add additional script tags from domains which are blocked.
  if ( dataset.autoblockIgnore ) {
    element.dataset.autoblockIgnore = dataset.autoblockIgnore
  }

  // If someone assigns a custom `data-categories` to a script and that script
  // creates elements, we want the created elements to have the same categories
  // so they'll be treated the same way. It's confusing to have an IG script,
  // to have a data-categories="advertising" and its children created as
  // the default "social_networking"
  if ( dataset.categories ) {
    element.dataset.categories = dataset.categories
  }

  return element
}
