import {
  createContext,
  useContext,
} from 'react'

const GVLContext = createContext({})


export default GVLContext

export function useGVL() {
  return useContext(GVLContext)
}
