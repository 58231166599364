import { eventConsent } from 'utils/events'

import {
  unblockAll,
  unblockByCategory,
} from 'utils/unblockers'

import { ALLOW_ALL_CONSENT } from 'constants/categories'


export default function enableConsentScripts(categories, config) {
  // eslint-disable-next-line eqeqeq
  if ( categories == null ) {
    unblockAll()
  } else {
    unblockByCategory(categories, config)
  }

  const consentCategories = categories ?? Object.keys(ALLOW_ALL_CONSENT)

  document.dispatchEvent(eventConsent(consentCategories))
}
