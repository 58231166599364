import CookieConsents from 'utils/cookieConsents'

import isBlockableNode from '../isBlockableNode'
import needsBlocking from '../needsBlocking'
import setDataCategories from '../setDataCategories'

import {
  isIframe,
  isScript,
} from '../tagUtils'

import {
  iframeBlocker,
  scriptBlocker,
} from './blockers'


/**
 * @param {MutationRecord[]} mutations
 */
function mutationCallback(mutations) {
  if ( CookieConsents.isAllConsented() ) {
    return
  }

  mutations.forEach(({ addedNodes }) => {
    addedNodes.forEach((node) => {
      // This skips over things like text nodes.
      if ( node.nodeType !== Node.ELEMENT_NODE ) {
        return
      }

      if ( !isBlockableNode(node) ) {
        return
      }

      // Adding the data-categories attribute early here allows us to
      // make DOM queries to find elements of interest later on (such
      // as when trying to decide if consent changes should trigger a
      // reload of the page). But if there are already categories in
      // place, we skip this step because those categories were likely
      // put there by the site maintainer as part of manual blocking
      // or category overriding.
      if ( !node.dataset.categories ) {
        setDataCategories(node)
      }

      if ( !needsBlocking(node) ) {
        return
      }

      if ( isScript(node) ) {
        scriptBlocker(node)
        return
      }

      if ( isIframe(node) ) {
        iframeBlocker(node)
        return
      }
    })
  })
}

// TODO: Don't export this object...let the caller construct the
// MutationObserver with the `mutationCallback()` exported above.
export default new MutationObserver(mutationCallback)
