import {
  createContext,
  useContext,
} from 'react'

const TCFContext = createContext({})


export default TCFContext

export function useTCF() {
  return useContext(TCFContext)
}
