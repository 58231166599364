import { TERMLY_CODE_SNIPPET_SUPPORT } from 'constants/domIds'


export default function insertDomElements() {
  if ( document.getElementById(TERMLY_CODE_SNIPPET_SUPPORT) ) {
    console.debug(`Element ${ TERMLY_CODE_SNIPPET_SUPPORT } already exists in the DOM`)
    return
  }

  const div = document.createElement('div')
  div.id = TERMLY_CODE_SNIPPET_SUPPORT

  // We're prepending so that the buttons in the banner will be the
  // first tabbable elements on the page (it's an a11y thing, baby).
  // I'm just praying that this doesn't trash someone's layout.
  document.body.prepend(div)
}
