import noop from 'utils/noop'


export default {
  // We're defaulting to an empty string so that we don't have to resort to using
  // alternations (apiBase || '') where this value is actually used. This is helpful/
  // necessary when we're importing resource-blocker into the dashboard, where we
  // don't get to leverage the results of utils/getConfig().
  apiBase: '',

  consentOrigin: void 0,

  // N.B.: There is no resumeAutoBlocker() at the moment because when the user
  // makes a configuration change or clicks "decline all," the page is completely
  // reloaded and this state is lost. If/When we ever get to the point where we're
  // not reloading the page, we'll have to revisit this; but for now, KISS.
  pauseAutoBlocker() {
    this._isAutoBlockerPaused = true
  },

  isAutoBlockerPaused() {
    return Boolean(this._isAutoBlockerPaused)
  },

  getAutoBlockerOverrides() {
    return this._autoBlockerOverrides || {
      install: noop,
      remove: noop,
    }
  },

  getDefaultConsents() {
    return this._defaultConsents
  },

  setAutoBlockerOverrides(overrides) {
    this._autoBlockerOverrides = overrides
  },

  setDefaultConsents(defaultConsents) {
    this._defaultConsents = defaultConsents
  },

  setSupportedLanguages(languages) {
    const lcLanguages = languages.map((lang) => lang.toLowerCase())

    this._supportedLanguages = new Set(lcLanguages)
  },

  isLanguageSupported(language = '') {
    if ( !this._supportedLanguages ) {
      console.debug('Termly.isLanguageSupported() called before Termly.setSupportedLanguages()')
      return false
    }

    return this._supportedLanguages.has(language.toLowerCase())
  },
}
