import EventAPI from 'utils/event-api'

import getConsentedCategories from './getConsentedCategories'
import getWhitelist from './getWhitelist'


export default function triggerGetUpdatedCookieWhitelistByTermly(args) {
  const {
    consentMode,
    consentState,
    cookieConsent,
    cookies,
    visitorId,
  } = args

  const categories = getConsentedCategories({
    consentMode,
    cookieConsent,
  })

  const cookieWhitelist = getWhitelist(cookieConsent, cookies)

  // Due to some rather unfortunate wording in our public
  // documentation, some people (like the Group One website-building
  // team), decided that it would be a good idea to define
  // `getUpdatedCookieWhitelistByTermly()` *after* resource-blocker is
  // loaded. So, it looks like we're forever stuck with this bloody
  // thumb here. I originally had this function shoved into the
  // EventAPI to maintain backwards compatibility...but that only
  // works if you're not doing silly things like defining functions
  // too late.
  if ( window.getUpdatedCookieWhitelistByTermly ) {
    window.getUpdatedCookieWhitelistByTermly({
      categories,
      cookies: cookieWhitelist,
      uuid: visitorId,
    })
  }

  EventAPI.dispatch('consent', {
    categories,
    consentState,
    cookies: cookieWhitelist,
    uuid: visitorId,
  })
}

