import createReplacement from '../createReplacement'
import getURLFromElem from '../getURLFromElem'
import isBigEnoughToReplace from '../isBigEnoughToReplace'

const BLOCKED_NODES_WITH_PLACEHOLDER_SELECTOR = '[data-src][data-termly-placeholder-id]'


export default function findAndReplacePlaceholders({ consentConfig, supportedLocales }) {
  const blockedNodesWithPlaceholderIDs =
    Array.from(document.querySelectorAll(BLOCKED_NODES_WITH_PLACEHOLDER_SELECTOR))

  blockedNodesWithPlaceholderIDs
    .map((node) => {
      const placeholderID = node.dataset.termlyPlaceholderId

      const placeholder = document.getElementById(placeholderID)

      if ( !placeholder ) {
        console.error('[Termly] Could not find placeholder element with ID %o',
                      placeholderID)
      }

      return {
        node,
        placeholder,
      }
    })
    .filter(({ placeholder }) => {
      return ( placeholder && isBigEnoughToReplace(placeholder) )
    })
    .forEach(({ placeholder, node }) => {
      const url = getURLFromElem(node).toString()

      const replacement = createReplacement({
        consentConfig,
        referenceNode: placeholder,
        supportedLocales,
        url,
      })

      placeholder.replaceChildren(replacement)
    })
}
