import React from 'react'
import ReactDOM from 'react-dom'

import { ReactQueryClientProvider } from '@termly_web/common'

import generateID from 'utils/generateID'
import { unblockByURLSubstring } from 'utils/unblockers'

import BlockedContent from 'components/BlockedContent'
import ConsentConfigProvider from 'components/ConsentConfigProvider'
import ResourceBlockerIntlProvider from 'components/ResourceBlockerIntlProvider'
import ThemeProvider from 'components/ThemeProvider'

import getURLFromElem from './getURLFromElem'

const THEME_PROVIDER_STYLE = {
  height: '100%',
}

const iframeID = generateID('termly-iframe')


export default function createReplacement({ consentConfig, referenceNode, supportedLocales, url: _url }) {
  const dom = document.createElement('div')

  dom.style.cssText = referenceNode.style.cssText
  dom.style.width = `${ referenceNode.offsetWidth }px`
  dom.style.height = `${ referenceNode.offsetHeight }px`
  dom.style.setProperty('--termly-blocked-content-max-height', `${ referenceNode.height }px`)
  dom.style.setProperty('--termly-blocked-content-max-width', `${ referenceNode.width }px`)
  dom.style.display = 'flex'
  dom.style.flexDirection = 'column'
  dom.style.justifyContent = 'center'
  dom.style.overflow = 'hidden'
  dom.className = 'termly-blocked-content t-blocked-content-container'
  dom.dataset.blockedCategories = referenceNode.dataset.categories

  const id = iframeID.next().value
  dom.dataset.blockedIframeId = id
  referenceNode.dataset.termlyIframeId = id

  const url = ( _url ) ? new URL(_url) : getURLFromElem(referenceNode)

  const handleAcceptClick = () => {
    if ( _url ) {
      dom.remove()
    }
    // Since we have an existing function to unblock by src substring,
    // we're passing in the `origin` (e.g., 'https://foo.com') on the
    // off chance that 'foo.com' might appear in a query string or
    // something crazy like that.
    unblockByURLSubstring(url.origin, {
      isTemporary: true,
    })
  }

  // We wrap the invocation of displayPreferenceModal() in a function
  // because it most likely has not yet been created when this
  // component is rendered...it's a mess.
  const handleManagePrefsClick = () => {
    if ( !window.displayPreferenceModal ) {
      console.error('displayPreferenceModal() is not defined')
      return
    }

    window.displayPreferenceModal()
  }

  ReactDOM.render(
    <ReactQueryClientProvider>
      <ResourceBlockerIntlProvider
        supportedLocales={ supportedLocales }
      >
        <ConsentConfigProvider
          consentConfig={ consentConfig }
        >
          <ThemeProvider
            style={ THEME_PROVIDER_STYLE }
          >
            <BlockedContent
              handleAcceptClick={ handleAcceptClick }
              handleManagePrefsClick={ handleManagePrefsClick }
              site={ url.hostname }
            />
          </ThemeProvider>
        </ConsentConfigProvider>
      </ResourceBlockerIntlProvider>
    </ReactQueryClientProvider>,
    dom)

  return dom
}
