import { SCRIPT_BLOCKED_TYPE } from '../constants/elementTypes'

import setDataCategories from './setDataCategories'
import { isScript } from './tagUtils'



/**
 * Replaces `src` with `data-src`, sets `data-categories` and `data-autoblocked`
 * N.B.: the node is modified in place
 *
 * @param {HTMLElement} node
 * @param {String} [src]
 */
export default function setBlockerAttributes(node, src = node.getAttribute('src')) {
  node.removeAttribute('src')
  node.setAttribute('data-src', src)

  if ( isScript(node) ) {
    node.setAttribute('type', SCRIPT_BLOCKED_TYPE)
  }

  if ( !node.dataset.categories ) {
    setDataCategories(node, src)
  }

  node.setAttribute('data-autoblocked', 1)
}
