export default [
  'apis.google.com/js/',
  'api.twitter.com/',
  'app.termly.io/',

  // These are mostly to mitigate the pain of moving connect.facebook.net
  // into the blockingList. The hope is that we'll alleviate most of our
  // problems for the immediate problem. We'll have to revisit this add
  // globs or some such...
  'connect.facebook.net/en_GB/sdk.js',
  'connect.facebook.net/en_UK/sdk.js',
  'connect.facebook.net/en_US/sdk.js',

  '.trmly.net/',
  'jquery.min.js',
  'jquery/',
  '//localhost.termly.io',
  // Google's reCaptcha is inserted into a user's page via a <script> tag
  // with src="https://www.google.com/recaptcha/api.js?render=<identifier>"
  // The user can mark that as essential, but there are iframes that are
  // brought into the document *after* the initial processing of the JS file,
  // (so there's no `document.currentScript` available to us) that points to
  // //www.google.com/recaptcha/api2/*. There's no discernable way to
  // fix this, so we're going to hope and pray that simply exempting
  // `recaptcha/api`, we'll be a little future-proof and hopefully won't run
  // afoul of any Imperial Entanglements.
  'www.google.com/recaptcha/api',
]
