const STRIP_LOCALE_REGEXP = /-.*$/


/**
 * Returns the best-matching preferred locale from the list of supported
 * (available) languages. Matching is case-insensitive, and a match on the
 * language itself is preferred over an exact match on a language further
 * down the list. For example, if `preferredLocales` is ['fr-ca', 'en', 'de'],
 * and `supportedLanguages` contains ['en', 'fr'], then we will return 'fr'.
 * See [TER-15826] for details.
 *
 *
 * @param {Object} args
 * @param {String[]} args.supportedLocales - the reference list of language
 *   codes from which the user is allowed to choose
 * @param {String[]} args.preferredLocales - the list of language codes that
 *   the user says he wants, sorted in order of decreasing preference (i.e.,
 *   the first language is the one he really wants, the last is the least-
 *   desired)
 *
 * @returns {String,undefined}
 */
export default function selectLocale({ supportedLocales, preferredLocales }) {
  if ( !supportedLocales?.length || !preferredLocales?.length ) {
    return
  }

  const supported = new Set(supportedLocales.map(normalize))
  const preferred = preferredLocales
    .map(normalize)
    .reduce((collector, item) => {
      if ( item.includes('-') ) {
        return collector.concat(item, stripLocale(item))
      }

      return collector.concat(item)
    }, [])

  return findMatch(supported, preferred)
}

function findMatch(supported, preferred) {
  return preferred.find((locale) => {
    return supported.has(locale)
  })
}

function normalize(string) {
  return string.toLowerCase()
}

function stripLocale(code) {
  return code.replace(STRIP_LOCALE_REGEXP, '')
}
