import {
  ADVERTISING,
  ANALYTICS,
  ESSENTIAL,
  PERFORMANCE,
  SOCIAL_NETWORKING,
  UNCLASSIFIED,
} from 'constants/categories'


export const GCM_DEFAULTS = {
  ad_personalization: 'denied',
  ad_storage: 'denied',
  ad_user_data: 'denied',
  analytics_storage: 'denied',
  functionality_storage: 'denied',
  personalization_storage: 'denied',
  security_storage: 'granted',
  social_storage: 'denied',
  // Not really a thing, but this is what I do in the GTM template, so
  // I'm going to use it here to be consistent.
  unclassified_storage: 'denied',
}

export const GCM_TO_TERMLY = {
  ad_personalization: ADVERTISING,
  ad_storage: ADVERTISING,
  ad_user_data: ADVERTISING,
  analytics_storage: ANALYTICS,
  functionality_storage: PERFORMANCE,
  personalization_storage: PERFORMANCE,
  security_storage: ESSENTIAL,
  social_storage: SOCIAL_NETWORKING,
  // Not really a thing, but this is what I do in the GTM template, so
  // I'm going to use it here to be consistent.
  unclassified_storage: UNCLASSIFIED,
}
