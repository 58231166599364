import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import Termly from 'termly-namespace'

import GVLContext from './GVLContext'

import useWebsiteGVLQuery from './hooks/useWebsiteGVLQuery'


export default function GVLProvider({ children, lang, websiteUUID }) {
  const {
    isInitialLoading,
    websiteGVL,
  } = useWebsiteGVLQuery({
    lang,
    websiteUUID,
  }, {
    enabled: Boolean(Termly.tcf),
  })

  const value = useMemo(() => ({
    gvl: websiteGVL,
  }), [websiteGVL])

  // If the GVL fetch takes a litle time, we'll wind up rendering the non-TCF banner
  // and then flip to the TCF banner when the GVL is done loading. So, it's best to
  // just not render anything at all until we're done fetching the data we need.
  if ( isInitialLoading ) {
    console.debug('[Termly] Loading GVL...')
    return null
  }

  return (
    <GVLContext.Provider
      value={ value }
    >
      { children }
    </GVLContext.Provider>
  )
}

GVLProvider.propTypes = {
  websiteUUID: PropTypes.string.isRequired,

  children: PropTypes.node,
  lang: PropTypes.string,
}
