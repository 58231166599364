import React, {
  useEffect,
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import {
  IntlProvider as ReactIntlProvider,
  useIntl,
} from 'react-intl'

import selectLocale from './lib/selectLocale'
import useLocaleStringsFile from './hooks/useLocaleStringsFile'

const DEFAULT_LOCALE = 'en'

const RTL_LANGUAGES = new Set([
  'ar',
])


export default function IntlProvider(props) {
  const {
    children,
    localeFileURLTemplate,
    preferredLocales,
    rootElem,
    supportedLocales,
    ...otherProps
  } = props

  const locale = useMemo(() => {
    return selectLocale({
      preferredLocales,
      supportedLocales,
    }) || DEFAULT_LOCALE
  }, [preferredLocales, supportedLocales])

  const { localeStrings } = useLocaleStringsFile({
    locale,
    urlTemplate: localeFileURLTemplate,
  })

  // We used to simply use <div dir={ dir }>{ children }</div>, below,
  // but that broke 'height: 100%' layouts in the dashboard. So, I
  // added 'height: 100%' to the div, but that broke some customer
  // sites that were using resource-blocker. So, it seems to me that
  // the best thing to do is to apply this property directly to the
  // DOM as high as possible.
  //
  // HOWEVER, since resource-blocker runs on other people's sites, we
  // don't want to modify the DOM outside of that area where we have
  // control. Imagine the customer's surprise when his
  // improperly-setup website was suddenly flipped to RTL because we
  // decided to show the end user Arabic. That would be really
  // bad. So, normally we'll default to document.body when we own the
  // entire DOM, but for other cases, we'll need to supply a
  // `rootElem` so we know precisely where to apply the `dir`
  // attribute.
  //
  // By the way, we're not using the CSS style `direction: rtl`
  // because that, apparently, is not inherited by table cells. See
  // https://developer.mozilla.org/en-US/docs/Web/CSS/direction for
  // more.
  useEffect(() => {
    const dir = ( RTL_LANGUAGES.has(locale) ) ? 'rtl' : 'ltr'

    // We used to use `document.body` as a default argument in the
    // props, but apparently when lazy-loading, `document.body` is
    // not available in the arguments...so we have to wait until
    // we get in here to do the fallback.
    const docRoot = rootElem || document.body

    docRoot.setAttribute('dir', dir)
  }, [locale, rootElem])

  return (
    <ReactIntlProvider
      { ...otherProps }
      defaultLocale={ DEFAULT_LOCALE }
      locale={ locale }
      messages={ localeStrings }
    >
      { children }
    </ReactIntlProvider>
  )
}

IntlProvider.propTypes = {
  localeFileURLTemplate: PropTypes.string.isRequired,
  preferredLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
  supportedLocales: PropTypes.arrayOf(PropTypes.string).isRequired,

  children: PropTypes.node,
  rootElem: PropTypes.shape({
    setAttribute: PropTypes.func.isRequired,
  }),
}

export {
  useIntl
}
