import cleanWebsiteUUID from './cleanWebsiteUUID'


export default function getConfigFromURL({ src, websiteUUID: givenWebsiteUUID }) {
  const params = new URLSearchParams(src.search)

  const masterConsentsOrigin = params.get('masterConsentsOrigin')
  const useAutoBlock = ( params.get('autoBlock') === 'on' )
  const websiteUUID = cleanWebsiteUUID(givenWebsiteUUID)

  return {
    isLatestScript: true,
    masterConsentsOrigin,
    useAutoBlock,
    websiteUUID,
  }
}
