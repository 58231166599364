import { setDocDict } from 'utils'


export default function initDocuments(previewDocs, docDict) {
  const updatedDocDict = {}

  previewDocs.forEach((doc) => {
    const { document_version, uuid } = doc
    const oldDoc = docDict ? docDict[uuid] : null

    updatedDocDict[uuid] = oldDoc
    // if user old setting is off, we need to set it on
    if (oldDoc && !oldDoc.notify) {
      oldDoc.showBanner = true
    }

    if (!oldDoc || document_version > oldDoc.version) {
      setDocDict(updatedDocDict, uuid, true, document_version, true)
    }
  })

  return { updatedDocDict }
}
