import React, {
  useEffect,
} from 'react'

import UINames from 'constants/ui-names'

import App from 'components/App'
import ResourceBlocker from 'components/ResourceBlocker'
import { useDisplayManager } from 'components/DisplayManager'


export default function ConsentPromptApp({ consentConfig, website }) {
  return (
    <App
      consentConfig={ consentConfig }
      website={ website }
    >
      <Content />
    </App>
  )
}

ConsentPromptApp.propTypes = {
  consentConfig: App.propTypes.consentConfig,
  website: App.propTypes.website,
}


// This component is necessary because <App /> is what sets up the
// DisplayManager context
//
function Content() {
  const { pushActiveUI } = useDisplayManager()

  useEffect(() => {
    pushActiveUI(UINames.PROMPT)
  }, [pushActiveUI])

  return (
    <ResourceBlocker />
  )
}
