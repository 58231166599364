import createReplacement from '../../createReplacement'
import isBigEnoughToReplace from '../../isBigEnoughToReplace'


export default function replaceIframe({ consentConfig, iframe, supportedLocales }) {
  // If the space allocated for an iframe is big enough, we'll inject
  // an "Enable this content?" banner in into the DOM, right before
  // the iframe. But we don't do this for tiny iframes because we don't
  // want to disrupt the flow of the page.
  if ( isBigEnoughToReplace(iframe) ) {
    const replacement = createReplacement({
      consentConfig,
      referenceNode: iframe,
      supportedLocales,
    })

    iframe.parentNode.insertBefore(replacement, iframe)
  }

  // Regardless of whether we're able to show a "enable this
  // content?" banner, we need to hide the iframe because no consent
  // has been given for it.
  iframe.dataset.display = iframe.style.display || 'block'

  if ( !iframe.dataset.src ) {
    iframe.dataset.src = iframe.getAttribute('src')
    iframe.removeAttribute('src')
  }

  iframe.style.display = 'none'
}
