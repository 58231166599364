import {
  isIframe,
  isScript,
} from '../tagUtils'

import makeCreateAttribute from '../makeCreateAttribute'
import makeSetAttribute from '../makeSetAttribute'

import augmentElementDataset from './augmentElementDataset'

/**
 * @param {Function} nativeCreateElement
 *
 * @returns {Function}
 */
export default function makeCreateElement(nativeCreateElement) {
  /**
   * An override for `document.createElement()` that overrides the
   * setters for the `type` and `src` attributes, with the goal being
   * to thwart efforts to load prohibited resources.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Document/createElement}
   *
   * @param {String} tagName
   * @param {Object} [options]
   *
   * @returns {Element} the new element
   */
  return (tagName, options) => {
    const element = nativeCreateElement.bind(document)(tagName, options)

    if ( !isScript(element) && !isIframe(element) ) {
      return element
    }

    const augmentedElement = augmentElementDataset(element)

    augmentedElement.setAttribute = makeSetAttribute(augmentedElement)

    return Object.defineProperties(augmentedElement, {
      type: makeCreateAttribute('type'),
      src: makeCreateAttribute('src'),
    })
  }
}
