import axios from 'axios'

import { selectLocale } from '@termly_web/common'

import Termly from 'termly-namespace'

import ConsentDefaults from 'constants/consentDefaults'
import CurrentRegionSettings from 'constants/current-region-settings'
import { ESSENTIAL } from 'constants/categories'
import { ON_WIDGET_LOAD_COMPLETE } from 'constants/lifeCycle'

import {
  genTltplId,
  getCookie,
  setCookie,
  uuidCookie,
} from 'utils/cookie'

import GTM from 'utils/gtm'
import URL from 'utils/url'

import { triggerGetUpdatedCookieWhitelistByTermly } from 'utils/updateCookieConsent'

import CookieConsents from 'utils/cookieConsents'
import enableConsentScripts from 'utils/enableConsentScripts'
import releaseResources from 'utils/releaseResources'
import { ONE_YEAR_IN_SECS } from 'utils/time'
import { unblockByURLSubstring } from 'utils/unblockers'

import {
  renderConsentPromptApp,
  renderGPCHandlerApp,
} from '../render'

import calculateIsOverConsentQuota from './calculateIsOverConsentQuota'
import consentAllForScanner from './consentAllForScanner'
import dispatchInitializedEvent from './dispatchInitializedEvent'
import findAndReplacePlaceholders from './findAndReplacePlaceholders'
import initDocuments from './initDocuments'
import initializePreferencesLink from './initializePreferencesLink'
import isUsingGPC from './isUsingGPC'
import registerEvents from './registerEvents'
import replaceAndWatchForIframes from './replaceAndWatchForIframes'
import shouldRenderReminder from './shouldRenderReminder'
import statisticUniqueUserViews from './statisticUniqueUserViews'


export default function onAllApiRespond({ location, websiteConfig }) {
  const {
    consent_origin: consentOrigin,
    website,
  } = websiteConfig

  // This will only need to be done here if we're using the legacy
  // URL (/embed.min.js)
  if ( !CurrentRegionSettings.isInitialized() ) {
    CurrentRegionSettings.init(website, location)
  }

  // The consent_domain doesn't come in via the `website` argument to
  // this function because the argument has to be backwards-compatible
  // with the payload returned by the legacy website API call.
  Termly.consentOrigin = consentOrigin

  const regionSetting = CurrentRegionSettings.getSettings()

  dispatchInitializedEvent({
    location,
    regionSetting,
    website,
  })

  findAndReplacePlaceholders({
    consentConfig: regionSetting,
    supportedLocales: regionSetting.language_setting,
  })

  replaceAndWatchForIframes({
    consentConfig: regionSetting,
    supportedLocales: regionSetting.language_setting,
  })

  initializePreferencesLink({
    regionSetting,
    website,
  })

  if ( !website.has_cookie_report ) {
    console.warn('[Termly] The CMP is disabled because we have not ' +
                 'been able to successfully complete a scan of this website.')

    disableWidget()

    return
  }

  if ( !regionSetting.enable_widget ) {
    console.debug('[Termly] The CMP is disabled for region %o. ' +
                  'Unblocking all scripts',
                  regionSetting.region)

    disableWidget()

    return
  }

  console.debug('[Termly] Region %o has consent_mode set to %o',
                regionSetting.region,
                regionSetting.consent_mode)

  Termly.tcf?.setTCFSettings(regionSetting.tcf_settings)

  if ( regionSetting.enable_google_consent_mode ) {
    enableGCM()
  }

  Termly.setSupportedLanguages(regionSetting.language_setting)

  registerEvents()

  const tluid = genTltplId(website.uuid)

  statisticUniqueUserViews(tluid)

  const isOverConsentQuota = calculateIsOverConsentQuota({
    consentsCount: website.consents_count,
    provisions: website.provisions,
  })

  if ( !isOverConsentQuota ) {
    const { updatedDocDict } = initDocuments(website.documents, getCookie(tluid))
    setCookie(tluid, updatedDocDict)
  }

  // Used by termly/cookie_scanner
  window.termlyUnblockingCookies = () => consentAllForScanner()

  const cookieConsent = CookieConsents.getConsents(ONE_YEAR_IN_SECS)

  if ( cookieConsent ) {
    enableConsentScripts(CookieConsents.getConsentedCategories())

    GTM.send(cookieConsent)
  } else {
    if ( regionSetting.consent_mode === 'opt_out' ) {
      Termly.setDefaultConsents(ConsentDefaults.OPT_OUT)

      Termly.pauseAutoBlocker()

      enableConsentScripts()

      GTM.send(ConsentDefaults.OPT_OUT)
    } else {
      Termly.setDefaultConsents(ConsentDefaults.OPT_IN)

      // Just in case someone does something silly like
      // <script type="text/plain" data-categories="essential"></script>
      //
      // Why would someone manually block an essential script? I don't know,
      // but the Cypress tests check for this very condition, so apparently
      // *some* joker out there is doing it.
      enableConsentScripts([ESSENTIAL])

      GTM.send(ConsentDefaults.OPT_IN)
    }
  }

  if ( !isOverConsentQuota ) {
    const cookiePolicy = website.documents.find((doc) => doc.name.includes('Cookie'))

    if ( shouldRenderReminder({ cookiePolicy }) ) {
      Termly.tcf?.expireConsents()

      if ( isUsingGPC(regionSetting) ) {
        console.debug('[Termly] GPC signal detected')

        renderGPCHandlerApp({
          consentConfig: regionSetting,
          website,
        })
      } else {
        renderConsentPromptApp({
          consentConfig: regionSetting,
          website,
        })
      }
    }

    if ( cookiePolicy ) {
      const uuidFromCookie = getCookie(uuidCookie)

      const locale = selectLocale({
        preferredLocales: window.navigator.languages,
        supportedLocales: regionSetting.language_setting,
      })

      const url = URL.getCookies({
        locale,
        region: regionSetting.region,
      })

      axios.get(url)
        .then(({ data }) => {
          const { cookies } = data

          triggerGetUpdatedCookieWhitelistByTermly({
            consentMode: regionSetting.consent_mode,
            consentState: CookieConsents.getConsentSettings(),
            cookieConsent,
            cookies,
            visitorId: uuidFromCookie,
          })

          // We have to put the cookies *somewhere* so that when we invoke
          // `triggerGetUpdatedCookieWhitelistByTermly()` in `updateCookieConsent()`,
          // we can pass them along again. This feels really gross, but what was here
          // originally was storing them directly on the `window`, which is worse.
          // I'd like to come up with a sexier way to handle this, but that's out of
          // scope for the current round of work.
          Termly.cookies = cookies
        })

    } else {
      console.warn('No cookie policy was found for this account. Did you create one at http://app.termly.io ?')
    }
  }

  dispatchOnWidgetLoadCompleteEvent()
}

function dispatchOnWidgetLoadCompleteEvent() {
  const event = new Event(ON_WIDGET_LOAD_COMPLETE)

  window.dispatchEvent(event)
}

function disableWidget() {
  Termly.tcf?.disable()

  Termly.getAutoBlockerOverrides().remove()

  releaseResources()
}

function enableGCM() {
  if ( window.TERMLY_FORCE_DISABLE_GCM ) {
    console.warn('[Termly] This region is configured in the Termly dashboard to '
                 + 'enable Google Consent Mode, but it has been explicitly disabled '
                 + 'via window.TERMLY_FORCE_DISABLE_GCM')
    return
  }

  console.debug('[Termly] Initializing Google Consent Mode')

  // If we don't pause AutoBlocker, then any child scripts are subject
  // to blocking. The sketchy part, however, is that it doesn't seem to
  // be possible to know, for sure, when it is safe to turn autoblocker
  // back on...so basically, we're turning it off and leaving it off.
  //
  // I'm not sure if this is "safe" but I'm not sure how else to get
  // around the problem. Time will tell, I suppose.
  Termly.pauseAutoBlocker()

  // When using the Google CMP, we have a bit of a chicken-and-egg
  // problem: we block most gtag scripts with various categories,
  // but for all /gtag/js scripts under the CMP, we have to treat
  // them as "essential"...but by the time we've gotten to this
  // point in the code, the autoblocker has already run. So, we need
  // a minimal-impact way of unblocking those specific scripts.
  //
  // This is an EXPERIMENTAL method for unblocking stuff. We're
  // trying this out on a very small subset of nodes to see if it
  // causes any issues. If it doesn't, then I hope to completely
  // replace our current "reload the entire page if we need to
  // unblock stuff" approach that we currently use.
  unblockByURLSubstring('googletagmanager.com/gtag/')

  GTM.initCMP()
}
