import {
  saveACString,
  saveTCString,
  saveTCFConsentableContentVersion,
} from './tcfStore'

import applyConsentStringsToDOM from './applyConsentStringsToDOM'


export default function makeSaveConsent({ ccVersion, cmpApi }) {
  return ({ acString, tcString }) => {
    saveACString(acString)
    saveTCFConsentableContentVersion(ccVersion)
    saveTCString(tcString)

    applyConsentStringsToDOM({
      acString,
      tcString,
    })

    // This has to be done at least after the call to saveACString() because
    // the CMP API (initialized in initTCF()) needs to have the AC string to
    // pass it along to the TCF API. There's no way to pass that information
    // to the CMP API directly.
    cmpApi.update(tcString)
  }
}
