import axios from 'axios'
import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import URL from 'utils/url'

const DEFAULT_VENDORS = []


export default function useGACMVendorsQuery({ websiteUUID }, options) {
  const url = URL.gacmVendors({
    websiteUUID,
  })

  const cacheKey = useMemo(() => {
    return ['gacm-vendors', websiteUUID]
  }, [websiteUUID])

  const {
    data = {},
    ...others
  } = useQuery(cacheKey,
               () => axios.get(url),
               options)


  // NOTE: The backend currently returns an object, but we want an array of
  // objects. To be removed in TER-13953.
  const vendors = ( !data.data?.vendors || isObject(data.data?.vendors) ) ? DEFAULT_VENDORS : data.data?.vendors

  return {
    ...others,
    vendors,
  }
}

function isObject(o) {
  return o?.constructor === Object
}
