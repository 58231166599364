import qs from 'qs'


/**
 * Modifies elements in place in the DOM.
 *
 * @param {NodeList} elements - elements that contain TCF macros that need interpolating
 * @param {Object} consentStrings
 * @param {String} consentStrings.acString
 * @param {String} consentStrings.tcString
 */
export default function replaceConsentMacros(src, { acString, tcString }) {
  // N.B.: this will have the unfortunate side-effect of changing
  // relative URLs into absolute URLs, but all of the alternatives
  // I've tried all take uninterpolated macros (e.g. '${GDPR_PD}')
  // and escapes them. I don't know if this is an issue or not, but
  // is scares me to think that some other script is going to go
  // looking for '${GDPR_PD}' but they won't find it because it's
  // been turned into '%24%7BGDPR_PD%7D'. So, I'm taking a
  // calculated risk that making a src a fully-qualified URL is
  // going to be less harmful than escaping something that shouldn't
  // be.
  //
  // In reality, I don't think this case is going to be at all
  // likely, since a 3p tracking image is going to be
  // fully-qualified from the start, but I just thought I'd call
  // this out as a conscious decision.
  const url = new URL(src, window.location)

  const queryArgs = qs.parse(url.search, {
    ignoreQueryPrefix: true,
  })

  if ( queryArgs.gdpr ) {
    // We only set this to 1 because we wouldn't be doing all of these
    // TCF-related machinations if the GDPR weren't applicable.
    queryArgs.gdpr = 1
  }

  if ( acString && queryArgs.addtl_consent ) {
    queryArgs.addtl_consent = acString
  }

  if ( tcString && queryArgs.gdpr_consent ) {
    queryArgs.gdpr_consent = tcString
  }

  // We're intentionally not doing anything with gdpr_pd...because
  // we don't really know what to do with it. See
  // https://termly.slack.com/archives/C05PWUGJFB8/p1701975852779049?thread_ts=1701968904.253719&cid=C05PWUGJFB8

  url.search = qs.stringify(queryArgs, {
    addQueryPrefix: true,
    // As noted above, we're trying really hard to munge the
    // URL as little as possible.
    encode: false,
  })

  return url.toString()
}
