import Cookie from '../cookie'

const HEADER_NAME = Cookie.csrfHeaderName


/**
 * Request interceptor: for each request to the server,
 * attach the CSRF token if it exists.
 *
 * @param {Object} request
 *
 * @returns {Object} request
 */
export default function requestInterceptor(request) {
  // TODO: This is horribly broken and needs to be fixed. See
  // https://termly.atlassian.net/browse/TER-10624
  //
  // We should only have to set the header and cookie when we're doing a
  // POST request, but the legacy code I'm replacing did this for *all*
  // requests...so that's what I'm going to do until we can address this
  // properly.
  if ( !Cookie.getCsrfCookie() ) {
    Cookie.setCsrfCookie()
  }

  return {
    ...request,
    headers: {
      ...request.headers,
      [HEADER_NAME]: Cookie.getCsrfCookie(),
    },
  }
}
