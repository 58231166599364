/**
 * Checks for consents that have either CHANGED or ADDED. This function does not
 * care if `newConsents` is missing keys that are extant in `oldConsents`.
 *
 * @param {Object} oldConsents - consent categories mapped to an arbitrary value
 * @param {Object} newConsents - consent categories mapped to an arbitrary value
 *
 * @returns {Object|undefined} if `newConsents` has added or changed values when
 *   compared to `oldConsents`, returns the added/changed key-value pairs in an
 *   Object. If there are no added/changed values, `undefined` is returned
 *   instead.
 */
export default function makeConsentDelta(oldConsents, newConsents) {
  const newEntries = Object.entries(newConsents || {})

  const delta = newEntries.reduce((collector, [category, value]) => {
    if ( oldConsents[category] === value ) {
      return collector
    }

    return {
      ...collector,
      [category]: value,
    }
  }, {})

  return ( Object.keys(delta).length ) ? delta : void 0
}
