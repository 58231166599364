const EVENT_NAME = 'userPrefUpdate'


/**
 * @param {Object} cookieConsent - mapping of categry name to Boolean consent value.
 */
export default function makeUserPrefUpdateEvent(cookieConsent = {}) {
  // This is a legacy value. It's pretty much impossible to tell who,
  // if anyone, is using this, so we're stuck with it...probably
  // forever.
  const cookiesAccepted = makeCookiesAccepted(cookieConsent)

  return {
    event: EVENT_NAME,
    cookiesAccepted,
    termlyConsentSettings: cookieConsent,
  }
}

/**
 * @private
 *
 * @param {Object} cookieConsent - mapping of category to Boolean consent value
 *
 * @returns {String[]} the sorted names of only the consented categories
 */
function makeCookiesAccepted(cookieConsent) {
  return Object.entries(cookieConsent)
    .filter(([category, value]) => Boolean(value))
    .map(([category, value]) => category)
}
