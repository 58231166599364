import { defineMessages } from 'react-intl'


// N.B.: top-level keys must correspond to the cookie category names
// that are returned by the service
//
export default defineMessages({
  essential: {
    id: 'cookie-categories.essential',
    defaultMessage: 'Essential',
  },

  essentialWithCount: {
    id: 'cookie-categories.essential-with-count',
    defaultMessage: 'Essential ({count})',
  },

  performance: {
    id: 'cookie-categories.performance',
    defaultMessage: 'Performance',
  },

  performanceWithCount: {
    id: 'cookie-categories.performance-with-count',
    defaultMessage: 'Performance ({count})',
  },

  analytics: {
    id: 'cookie-categories.analytics',
    defaultMessage: 'Analytics',
  },

  analyticsWithCount: {
    id: 'cookie-categories.analytics-with-count',
    defaultMessage: 'Analytics ({count})',
  },

  advertising: {
    id: 'cookie-categories.advertising',
    defaultMessage: 'Advertising',
  },

  advertisingWithCount: {
    id: 'cookie-categories.advertising-with-count',
    defaultMessage: 'Advertising ({count})',
  },

  // Yes, a non-standard key, but I'm trying to slowly bring sanity to
  // this area of the codebase.
  // eslint-disable-next-line camelcase
  social_networking: {
    id: 'cookie-categories.social-networking',
    defaultMessage: 'Social',
  },

  // eslint-disable-next-line camelcase
  social_networkingWithCount: {
    id: 'cookie-categories.social-with-count',
    defaultMessage: 'Social ({count})',
  },

  unclassified: {
    id: 'cookie-categories.unclassified',
    defaultMessage: 'Unclassified',
  },

  unclassifiedWithCount: {
    id: 'cookie-categories.unclassified-with-count',
    defaultMessage: 'Unclassified ({count})',
  },
})
