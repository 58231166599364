import insertDomElements from './insertDomElements'
import sanitizeLocalStorage from './sanitizeLocalStorage'
import startProcess from './startProcess'

import {
  setApiBase,
  setWebsiteUuid,
} from './state'


export default function initialize({ apiBase, websiteUUID }) {
  sanitizeLocalStorage(websiteUUID)

  setApiBase(apiBase)
  setWebsiteUuid(websiteUUID)

  const promise = new Promise((resolve) => {
    if ( isDocumentReady() ) {
      resolve()
      return
    }

    document.addEventListener('readystatechange', () => {
      if ( isDocumentReady() ) {
        resolve()
      }
    })
  })

  promise.then(() => {
    insertDomElements()
    startProcess()
  })
}

function isDocumentReady() {
  return document.readyState === 'interactive' || document.readyState === 'complete'
}
