import { UNCLASSIFIED } from 'constants/categories'

import { getCategoryForURL } from '../utils/blockingList'


export default function setDataCategories(node, src = node.getAttribute('src')) {
  const url = new URL(src, window.location)

  const category = getCategoryForURL(url) || UNCLASSIFIED

  node.setAttribute('data-categories', category)
}
