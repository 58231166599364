import makeAttributeSetter from './makeAttributeSetter'


/**
 * Creates an override of the get() and set() that's aware of
 * the blocklist and user-specified blocking behavior.
 *
 * @param {String} attr - the name of the attribute to override
 *
 * @returns {Object}
 */
export default function createAttributeOverride(attr) {
  return {
    configurable: true,

    set: makeAttributeSetter(attr),

    get() {
      return this.getAttribute(attr)
    },
  }
}
