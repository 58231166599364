import Categories from 'constants/categories'


/**
 * Extracts the consent category key-values pairs from the supplied
 * object and returns them as a new object.
 *
 * @param {Object} object
 *
 * @returns {Object}
 */
export default function filterConsents(object) {
  return Object.entries(object)
    .filter(([key, _value]) => {
      return Categories.has(key) || key === 'do_not_sell'
    })
    .reduce((consents, [category, value]) => ({
      ...consents,
      [category]: Boolean(value),
    }), {})
}
