import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import WebsiteContext from './WebsiteContext'


export default function WebsiteProvider({ children, website }) {
  const policiesByType = useMemo(() => {
    return website.documents.reduce((policies, document) => ({
      ...policies,
      [document.template_type]: document,
    }), {})
  }, [website])

  const value = useMemo(() => ({
    cookiePolicy: policiesByType.Cookie,
    policiesByType,
    showLogo: website.show_logo,

    // TODO: These are temporary refactoring aids...I hope.
    documents: website.documents,
    url: website.url,
    uuid: website.uuid,
  }), [policiesByType, website])

  return (
    <WebsiteContext.Provider
      value={ value }
    >
      { children }
    </WebsiteContext.Provider>
  )
}

WebsiteProvider.propTypes = {
  children: PropTypes.node,
  website: PropTypes.shape({
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        template_type: PropTypes.string,
      }),
    ),
    show_logo: PropTypes.bool,
    url: PropTypes.string,
    uuid: PropTypes.string,
  }),
}
