import URI from 'urijs'

import Termly from 'termly-namespace'

import {
  getApiBase,
  getWebsiteUuid,
} from './state'


const website = () => {
  const apiBase = getApiBase()

  if ( !apiBase ) {
    throw new Error('URL.website() called before the API base was initialized in state')
  }

  const websiteUuid = getWebsiteUuid()

  if ( !websiteUuid ) {
    throw new Error('URL.website() called before the website UUID was initialized in state')
  }

  return `${ apiBase }/api/v1/snippets/websites/${ websiteUuid }`
}

const statistic = () => `${ website() }/statistics`

const getCountry = () => {
  const apiBase = getApiBase()

  return `${ apiBase }/api/v1/ip`
}

const getCookies = ({ locale, region }) => {
  const baseURL = new URI(`${ website() }/cookies`)
    .query({
      lang: locale,
    })

  if ( Termly.tcf && region === 'eu' ) {
    baseURL.addQuery('include_tcf_cookies', 'false')
  }

  return baseURL.toString()
}

const saveCookieConsent = () => {
  if ( Termly.consentOrigin ) {
    const websiteUuid = getWebsiteUuid()

    return `${ Termly.consentOrigin }/v1/websites/${ websiteUuid }/cookie-consent`
  }

  return `${ website() }/cookie_consents`
}

const docLink = (name, uuid) => {
  if ( !name ) {
    return ''
  }

  const apiBase = getApiBase()

  return `${ apiBase }/policy-viewer/policy.html?policyUUID=${ uuid }`
}

const fullTcfGVL = () => {
  const apiBase = getApiBase()

  return `${ apiBase }/resource-blocker/support/complete-tcf-gvl`
}

const tcfGVL = ({ lang, websiteUUID }) => {
  const apiBase = getApiBase()

  return `${ apiBase }/resource-blocker/support/${ websiteUUID }/tcf-gvl?lang=${ lang }`
}

const gacmVendors = ({ websiteUUID }) => {
  const apiBase = getApiBase()

  return `${ apiBase }/resource-blocker/support/${ websiteUUID }/gacm-vendors`
}

export default {
  docLink,
  fullTcfGVL,
  gacmVendors,
  getCookies,
  getCountry,
  saveCookieConsent,
  statistic,
  tcfGVL,
  website,
}
