import CurrentRegionSettings from 'constants/current-region-settings'
import { GCM_DEFAULTS } from 'constants/gcm'

import GTM from './gtm'


export default function sendGCMConsentDefaults() {
  const gcmSettings = CurrentRegionSettings.getGoogleConsentModeSettings()

  if ( !gcmSettings ) {
    console.debug('[Termly] No google_consent_mode_settings found in region config. Using static defaults.')
    GTM.sendConsentDefault(GCM_DEFAULTS)
    return
  }

  const defaults = getGCMRegionSettings(gcmSettings)

  GTM.sendConsentDefault(defaults)
}

function getGCMRegionSettings(settings) {
  const {
    enable_wait_for_update: enableWaitForUpdate,
    ...gcmSettings
  } = settings

  if ( !enableWaitForUpdate ) {
    delete gcmSettings.wait_for_update
  }

  return gcmSettings
}
