import globToRegExp from 'glob-to-regexp'


export default function makeIsDomainHandledByTCF(domainGlobs) {
  // We're trying to figure out how to interpret `*`. In the
  // meantime, we need to weed it out in this context so that
  // we don't completely disable auto-blocker [TER-13480].
  const regexes = domainGlobs
    .filter((pattern) => pattern !== '*')
    .map(globToRegExp)

  return (hostname) => {
    return regexes.some((regex) => regex.test(hostname))
  }
}
