import React, {
  useEffect,
} from 'react'

import PropTypes from 'prop-types'

import UINames from 'constants/ui-names'

import App from 'components/App'
import { useDisplayManager } from 'components/DisplayManager'
import ResourceBlocker from 'components/ResourceBlocker'


export default function PreferencesApp({ consentConfig, website }) {
  // See comment in Content, below
  const renderedAt = new Date()

  return (
    <App
      consentConfig={ consentConfig }
      website={ website }
    >
      <Content
        renderedAt={ renderedAt }
      />
    </App>
  )
}

PreferencesApp.propTypes = {
  consentConfig: App.propTypes.consentConfig,
  website: App.propTypes.website,
}

// This component is necessary because <App /> is what sets up the
// DisplayManager context
//
function Content({ renderedAt }) {
  const { pushActiveUI } = useDisplayManager()

  // `renderedAt` is used to force the `useEffect` hook to re-run every time
  // we render PreferencesApp. Without this little hack, the effect will only
  // be run once and never again...which means that if the PreferencesApp is
  // dismissed, it'll be impossible to bring it back without a page reload.
  // We can't use `activeUI` (from `useDisplayManager()`) either, because that
  // gets us into nasty loops. If there's a better solution to this, it's eluding
  // me.
  //
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    pushActiveUI(UINames.PREFERENCES)
  }, [pushActiveUI, renderedAt])
  /* eslint-enable */

  return (
    <ResourceBlocker />
  )
}

Content.propTypes = {
  renderedAt: PropTypes.instanceOf(Date),
}

