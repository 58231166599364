export default class ACModel {
  constructor(vendorIDs) {
    this._consents = new Map()

    this._addVendorIDs(vendorIDs)
  }

  get consented() {
    const reducer = makeConsentReducer(Boolean)

    return this.reduce(reducer, [])
  }

  get unconsented() {
    const reducer = makeConsentReducer((isConsented) => !isConsented)

    return this.reduce(reducer, [])
  }

  get size() {
    return this._consents.size
  }

  get(vendorID) {
    return this._consents.get(vendorID)
  }

  set(vendorID) {
    this._consents.set(vendorID, true)
  }

  setVendorIDs(vendorIDs) {
    this._pruneDeletedVendorIDs(vendorIDs)
    this._addVendorIDs(vendorIDs)
  }

  setAll() {
    this._forEach(({ vendorID }) => {
      this.set(vendorID)
    })
  }

  unset(vendorID) {
    this._consents.set(vendorID, false)
  }

  unsetAll() {
    this._forEach(({ vendorID }) => {
      this.unset(vendorID)
    })
  }

  reduce(reducer, initialValue) {
    return Array.from(this._consents.entries())
      .reduce((collector, [vendorID, isConsented]) => {
        return reducer(collector, {
          isConsented,
          vendorID,
        })
      }, initialValue)
  }

  //
  // PRIVATE METHODS
  //

  _addVendorIDs(vendorIDs) {
    // Initialization to `undefined` is *functionally*
    // indistinguishable from an "unset" consent (both `false` and
    // `void 0` are considered to be "unset"). The only reason I'm
    // using `void 0` here instead of `false` is to make it easier to
    // distinguish between values that were initialized and those that
    // were explicitly "unset"...which can be helpful during
    // debugging.
    vendorIDs?.forEach((vendorID) => {
      if ( this._consents.has(vendorID) ) {
        return
      }

      this._consents.set(vendorID, void 0)
    })
  }

  _forEach(visitor) {
    this._consents.forEach((isConsented, vendorID) => {
      visitor({
        isConsented,
        vendorID,
      })
    })
  }

  _pruneDeletedVendorIDs(vendorIDs) {
    const newVendorIDs = new Set(vendorIDs)

    // Remember, `this` is not visible inside of `() => {}`.
    const consents = this._consents

    this._forEach(({ vendorID }) => {
      if ( newVendorIDs.has(vendorID) ) {
        return
      }

      consents.delete(vendorID)
    })
  }
}

function makeConsentReducer(consentFilter) {
  return (collector, { isConsented, vendorID }) => {
    if ( consentFilter(isConsented) ) {
      return collector.concat(vendorID)
    }

    return collector
  }
}
