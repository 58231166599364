import { setDocDict } from 'utils'

import { getItem, setItem } from './localStorage'

export const templateCookie = '__tltpl'
export const uuidCookie = '__tluid'

// TODO: This function is wholly unnecessary
export const setCookie = (cname, cvalue) => {
  setItem(cname, cvalue)
}

export const getCookie = (name = templateCookie) => getItem(name, 0)

export const updateDocDict = (documents) => {
  // all the documents share the same website id
  const websiteId = documents && documents.length > 0 && documents[0].website_id
  const tluid = genTltplId(websiteId)
  const docDict = getCookie(tluid) || {}

  documents.forEach(({ uuid, document_version }) => {
    setDocDict(docDict, uuid, true, document_version, false)
  })
  setCookie(tluid, docDict)
}

export const genTltplId = (websiteId) => {
  return `${ templateCookie }_${ websiteId }`
}

