import { TERMLY_STATISTIC } from 'utils'

import {
  getItem,
  setItem,
} from 'utils/localStorage'

import statisticViewApiCall from './statisticViewApiCall'


export default function statisticUniqueUserViews(tluid) {
  const currentDate = new Date()
  const currentTimestamp = currentDate.getTime()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  const firstDayOfMonthTimestamp = new Date(currentYear, currentMonth, 1).getTime()
  const ttl = Math.round((currentTimestamp - firstDayOfMonthTimestamp) / 1000)
  const uniqueUserViewItem = getItem(TERMLY_STATISTIC, ttl)

  if ( uniqueUserViewItem ) {
    statisticViewApiCall(['page_view'])
  } else {
    statisticViewApiCall(['unique_user_view', 'page_view'])
    setItem(TERMLY_STATISTIC, tluid)
  }
}

