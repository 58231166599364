import React from 'react'
import PropTypes from 'prop-types'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import queryClient from './queryClient'


export default function ReactQueryClientProvider({ children }) {
  return (
    <QueryClientProvider
      client={ queryClient }
    >
      { children }
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

ReactQueryClientProvider.propTypes = {
  children: PropTypes.node,
}
