import {
  useCallback,
  useMemo,
} from 'react'

import updateCookieConsent from 'utils/updateCookieConsent'
import { updateDocDict } from 'utils/cookie'

import { useGACM } from 'components/GACMProvider'
import { useTCF } from 'components/TCFProvider'
import { useConsentConfig } from 'components/ConsentConfigProvider'
import { useWebsite } from 'components/WebsiteProvider'


// TODO: There is still a fair amount of cleanup that needs to be done here. I made
// this hook by simply moving all of the code you see here from `hoc/preview.js`,
// and made as few changes as possible so that I reduce my risk of breaking things.
//
// It didn't seem like a good idea to put this into ConsentConfigProvider because
// of the dependency we have here on WebsiteProvider.
//
export default function useSaveConsent() {
  const consentConfig = useConsentConfig()

  const {
    cookiePolicy,
    documents,
  } = useWebsite()

  const {
    acceptAll: acceptAllGACM,
    declineAll: declineAllGACM,
    makeACString,
  } = useGACM()

  const {
    acceptAll: acceptAllTCF,
    declineAll: declineAllTCF,
    makeTCString,
    saveConsent: saveTCFConsent,
  } = useTCF()

  const acceptPolicy = useCallback((saveCookieConsent) => {
    updateDocDict(documents)

    saveCookieConsent()
  }, [documents])

  const saveConsent = useCallback(({ acString, cookieConsent, tcString }) => {
    const _acString = acString || makeACString()
    const _tcString = tcString || makeTCString()

    saveTCFConsent({
      acString: _acString,
      tcString: _tcString,
    })

    acceptPolicy(
      updateCookieConsent({
        acString: _acString,
        consentMode: consentConfig.consent_mode,
        cookieConsent,
        cookiePolicy,
        eventType: consentConfig.display_style,
        tcString: _tcString,
      })
    )
  }, [acceptPolicy, consentConfig, cookiePolicy, makeACString, makeTCString, saveTCFConsent])

  const acceptAll = useCallback(() => {
    acceptAllGACM()
    acceptAllTCF()

    saveConsent({
      cookieConsent: {
        advertising: true,
        analytics: true,
        essential: true,
        performance: true,
        social_networking: true,
        unclassified: true,
      },
    })
  }, [acceptAllGACM, acceptAllTCF, saveConsent])

  const declineAll = useCallback((additionalArgs) => {
    declineAllGACM()
    declineAllTCF()

    saveConsent({
      cookieConsent: {
        advertising: false,
        analytics: false,
        essential: true,
        performance: false,
        social_networking: false,
        unclassified: false,
        ...additionalArgs,
      },
    })
  }, [declineAllGACM, declineAllTCF, saveConsent])

  return useMemo(() => ({
    acceptAll,
    declineAll,
    saveConsent,
  }), [acceptAll, declineAll, saveConsent])
}
