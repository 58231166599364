let apiBase

export function setApiBase(base) {
  apiBase = base
}

export function getApiBase() {
  return apiBase
}

let websiteUuid

export function getWebsiteUuid() {
  return websiteUuid
}

export function setWebsiteUuid(uuid) {
  websiteUuid = uuid
}
