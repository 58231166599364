import {
  ADVERTISING,
  ANALYTICS,
  ESSENTIAL,
  PERFORMANCE,
  SOCIAL_NETWORKING,
} from 'constants/categories'


//
// PLEASE add in alphabetical order
//

export default {
  '0fmm.com': ADVERTISING,
  '105app.com': PERFORMANCE,
  '109.109.138.174': PERFORMANCE,
  '121d8.com': PERFORMANCE,
  '176.74.183.134': ESSENTIAL,
  '185.2.100.179': PERFORMANCE,
  '1dmp.io': ADVERTISING,
  '1rx.io': ADVERTISING,
  '1xrun.com': ESSENTIAL,
  '254a.com': ADVERTISING,
  '2adpro.com': ADVERTISING,
  '2mdn.net': ADVERTISING,
  '2o7.net': ANALYTICS,
  '301network.com': PERFORMANCE,
  '33across.com': ADVERTISING,
  '360yield.com': ADVERTISING,
  '365dm.com': PERFORMANCE,
  '365webservices.co.uk': PERFORMANCE,
  '3gl.net': ANALYTICS,
  '3lift.com': ADVERTISING,
  '4finance.com': ESSENTIAL,
  '55labs.com': ADVERTISING,
  '5milesapp.com': PERFORMANCE,
  '5min.com': PERFORMANCE,
  '77tracking.com': ANALYTICS,
  '9msn.com.au': PERFORMANCE,
  '9xb.com': ADVERTISING,
  'a3cloud.net': ADVERTISING,
  'a4.tl': ADVERTISING,
  'aabacosmallbusiness.com': PERFORMANCE,
  'aamsitecertifier.com': ADVERTISING,
  'aaxdetect.com': ADVERTISING,
  'ably.io': ESSENTIAL,
  'abmr.net': ADVERTISING,
  'accuweather.com': PERFORMANCE,
  'acq.io': ADVERTISING,
  'acsbap.com': ESSENTIAL,
  'acsbapp.com': ESSENTIAL,
  'active-agent.com': ADVERTISING,
  'actonsoftware.com': ADVERTISING,
  'acuityplatform.com': ADVERTISING,
  'ad-srv.net': ADVERTISING,
  'ad1x.com': ADVERTISING,
  'ad6media.fr': ADVERTISING,
  'ada.support': PERFORMANCE,
  'adacado.com': ADVERTISING,
  'adadvisor.net': ADVERTISING,
  'adadyn.com': ADVERTISING,
  'adalyser.com': ANALYTICS,
  'adaos-ads.net': ADVERTISING,
  'adap.tv': ADVERTISING,
  'adaptive.co.uk': PERFORMANCE,
  'adblade.com': ADVERTISING,
  'adbrn.com': ADVERTISING,
  'adcmps.com': PERFORMANCE,
  'addevent.com': ESSENTIAL,
  'addthis.com': SOCIAL_NETWORKING,
  'addthisedge.com': SOCIAL_NETWORKING,
  'addthisevent.com': PERFORMANCE,
  'addtoany.com': SOCIAL_NETWORKING,
  'addtocalendar.com': ESSENTIAL,
  'adeasy.ru': ADVERTISING,
  'adecs.co.uk': PERFORMANCE,
  'adentifi.com': ADVERTISING,
  'adextent.com': ADVERTISING,
  'adfarm.mediaplex.com': ADVERTISING,
  'adforgeinc.com': ADVERTISING,
  'adformdsp.net': ADVERTISING,
  'adgenie.co.uk': ADVERTISING,
  'adgrx.com': ADVERTISING,
  'adhigh.net': ADVERTISING,
  'adingo.jp': ADVERTISING,
  'adis.ws': ADVERTISING,
  'adition.com': ADVERTISING,
  'adjug.com': ADVERTISING,
  'adkernel.com': ADVERTISING,
  'adkontekst.pl': ADVERTISING,
  'adledge.com': ESSENTIAL,
  'adlegend.com': ADVERTISING,
  'adlooxtracking.com': ADVERTISING,
  'adlux.com': ADVERTISING,
  'admarketplace.net': ADVERTISING,
  'admatic.com.tr': ADVERTISING,
  'admaym.com': ADVERTISING,
  'admedia.com': ADVERTISING,
  'admedo.com': ADVERTISING,
  'admeld.com': ADVERTISING,
  'admixer.net': ADVERTISING,
  'admized.com': ADVERTISING,
  'adnext.fr': ADVERTISING,
  'adnium.com': ADVERTISING,
  'adnostic.co.uk': ADVERTISING,
  'adobe.com': PERFORMANCE,
  'adobetag.com': ADVERTISING,
  'adocean.pl': ADVERTISING,
  'adomik.com': ANALYTICS,
  'adotmob.com': ADVERTISING,
  'adrdgt.com': ADVERTISING,
  'adrecover.com': ADVERTISING,
  'adrianquevedo.com': PERFORMANCE,
  'adriver.ru': ADVERTISING,
  'adroll.com': ADVERTISING,
  'adrta.com': ESSENTIAL,
  'ads-creativesyndicator.com': ADVERTISING,
  'ads.linkedin.com.': ADVERTISING,
  'adscale.de': ADVERTISING,
  'adschoom.com': ADVERTISING,
  'adscience.nl': ADVERTISING,
  'adsco.re': ADVERTISING,
  'adserver01.de': ADVERTISING,
  'adservice.google.': ADVERTISING,
  'adsiduous.com': ADVERTISING,
  'adsnative.com': ADVERTISING,
  'adsonar.com': ADVERTISING,
  'adspeed.net': ADVERTISING,
  'adspruce.com': ADVERTISING,
  'adstheaa.com': ADVERTISING,
  'adswizz.com': ADVERTISING,
  'adsymptotic.com': ADVERTISING,
  'adtech.de': ADVERTISING,
  'adtechus.com': ADVERTISING,
  'adtpix.com': ADVERTISING,
  'adtrue.com': ADVERTISING,
  'adunity.com': ADVERTISING,
  'adventori.com': ADVERTISING,
  'advertserve.com': ADVERTISING,
  'advolution.de': ESSENTIAL,
  'adwise.bg': ADVERTISING,
  'adx1.com': ADVERTISING,
  'adyapper.com': ADVERTISING,
  'adyen.com': ESSENTIAL,
  'adyoulike.net': ADVERTISING,
  'adzerk.net': ADVERTISING,
  'adzip.co': ADVERTISING,
  'aerserv.com': ADVERTISING,
  'affec.tv': ADVERTISING,
  'affiliatetechnology.com': ADVERTISING,
  'affiliatly.com': ADVERTISING,
  'affino.com': ADVERTISING,
  'affirm.com': ESSENTIAL,
  'afy11.net': ADVERTISING,
  'agcdn.com': ADVERTISING,
  'agendamedia.co.uk': ADVERTISING,
  'agilitycms.com': PERFORMANCE,
  'agilone.com': ADVERTISING,
  'agkn.com': ADVERTISING,
  'agoramedia.com': ADVERTISING,
  'ahalogy.com': SOCIAL_NETWORKING,
  'aheadworks.com': ESSENTIAL,
  'aimediagroup.com': ADVERTISING,
  'aimtell.com': PERFORMANCE,
  'air.tv': ADVERTISING,
  'aira.net': ADVERTISING,
  'airpr.com': ANALYTICS,
  'ajax.cloudflare.com': ESSENTIAL,
  'akanoo.com': ANALYTICS,
  'albacross.com': ADVERTISING,
  'alephd.com': ADVERTISING,
  'algolia.io': ESSENTIAL,
  'alphassl.com': ESSENTIAL,
  'alphr.com': PERFORMANCE,
  'altitude-arena.com': ADVERTISING,
  'altitudeplatform.com': ADVERTISING,
  'altocloud.com': ANALYTICS,
  'amazon-adsystem.com': ADVERTISING,
  'ampcid.google.com': ANALYTICS,
  'ampproject.org': PERFORMANCE,
  'an.yandex.ru': ADVERTISING,
  'analytics.fairfax.com.au': ANALYTICS,
  'analytics.google.com': ANALYTICS,
  'analytics.twitter.com': ADVERTISING,
  'analyticsbeta.cimm2.com': ANALYTICS,
  'anametrix.com': ANALYTICS,
  'ancoraplatform.com': ADVERTISING,
  'andbeyond.media': ADVERTISING,
  'angsrvr.com': ADVERTISING,
  'animatedjs.com': ESSENTIAL,
  'answerdash.com': PERFORMANCE,
  'aol.co.uk': ADVERTISING,
  'aperfectpocketdata.com': PERFORMANCE,
  'aph.com': PERFORMANCE,
  'api.ceros.com': ANALYTICS,
  'api.usehero.com': PERFORMANCE,
  'apligraf.com.br': ESSENTIAL,
  'app.ecqid.com': PERFORMANCE,
  'app.multiscreenstore.com': ESSENTIAL,
  'app.link': ADVERTISING,
  'appboycdn.com': ANALYTICS,
  'appelsiini.net': ESSENTIAL,
  'appier.net': ADVERTISING,
  'apple.com': ADVERTISING,
  'apptus.com': ANALYTICS,
  'aralego.com': ADVERTISING,
  'arcgisonline.com': ESSENTIAL,
  'ardentcreative.co.uk': PERFORMANCE,
  'areyouahuman.com': ESSENTIAL,
  'arnoldclark.com': PERFORMANCE,
  'aspnetcdn.com': ESSENTIAL,
  'assets-cdk.com': PERFORMANCE,
  'assets-global.website-files.com': ESSENTIAL,
  'assets.ansira.net': ESSENTIAL,
  'assets.squarespace.com': ESSENTIAL,
  'aswpsdkus.com': ADVERTISING,
  'atdmt.com': ANALYTICS,
  'atemda.com': ADVERTISING,
  'atgsvcs.com': ANALYTICS,
  'ati-host.net': ANALYTICS,
  'aticdn.net': ANALYTICS,
  'atlasrtx.com': PERFORMANCE,
  'atlassbx.com': SOCIAL_NETWORKING,
  'atomvault.net': PERFORMANCE,
  'attentivemobile.com': ADVERTISING,
  'attributionapp.com': ADVERTISING,
  'atwola.com': ADVERTISING,
  'audienceinsights.net': ANALYTICS,
  'audiencemanager.de': ADVERTISING,
  'audioboo.fm': SOCIAL_NETWORKING,
  'audtd.com': ADVERTISING,
  'augur.io': ANALYTICS,
  'auth0.com': ESSENTIAL,
  'authorize.net': ESSENTIAL,
  'autopilothq.com': ADVERTISING,
  'avail.net': ADVERTISING,
  'avanser.com.au': ANALYTICS,
  'avcosystems.com': ESSENTIAL,
  'avg.com': ESSENTIAL,
  'avis-verifies.com': ANALYTICS,
  'avmws.com': ADVERTISING,
  'avocet.io': ADVERTISING,
  'aweber.com': ADVERTISING,
  'awempire.com': ADVERTISING,
  'axf8.net': ANALYTICS,
  'axs.com': PERFORMANCE,
  'ayads.co': ADVERTISING,
  'backinstock.org': ADVERTISING,
  'baifendian.com': ADVERTISING,
  'bam-x.com': ANALYTICS,
  'bankrate.com': ESSENTIAL,
  'bannerflow.com': ADVERTISING,
  'bannersnack.com': ADVERTISING,
  'barclaycardsmartpay.com': ESSENTIAL,
  'baynote.net': ANALYTICS,
  'bbb.org': ANALYTICS,
  'bd4travel.com': ANALYTICS,
  'beampulse.com': ANALYTICS,
  'beeketing.com': ADVERTISING,
  'beetailer.com': SOCIAL_NETWORKING,
  'begun.ru': PERFORMANCE,
  'behavioralmailing.com': ADVERTISING,
  'bet365affiliates.com': ADVERTISING,
  'betrad.com': ADVERTISING,
  'betterbannerscloud.com': ADVERTISING,
  'betweendigital.com': ADVERTISING,
  'bfmio.com': ADVERTISING,
  'bidr.io': ADVERTISING,
  'bidswitch.net': ADVERTISING,
  'bidtheatre.com': ADVERTISING,
  'bigcommerce.com': ADVERTISING,
  'bing.com': PERFORMANCE,
  'bit.ly': ESSENTIAL,
  'bitgravity.com': PERFORMANCE,
  'bizibly.com': ADVERTISING,
  'bizinformation.org': ANALYTICS,
  'bizrate.com': ANALYTICS,
  'blismedia.com': ADVERTISING,
  'bluecava.com': ADVERTISING,
  'bluecore.com': ANALYTICS,
  'bluekai.com': ADVERTISING,
  'boldapps.net': ESSENTIAL,
  'boldchat.com': PERFORMANCE,
  'bolt.com': ESSENTIAL,
  'bonniercorp.com': PERFORMANCE,
  'bonzai.co': ADVERTISING,
  'boomtrain.net': ADVERTISING,
  'boostable.com': ADVERTISING,
  'bootcss.com': ESSENTIAL,
  'bootstrapcdn.com': ESSENTIAL,
  'booxscale.com': ADVERTISING,
  'bouncex.net': ANALYTICS,
  'box.com': PERFORMANCE,
  'boxever.com': ANALYTICS,
  'bpay.co.uk': ESSENTIAL,
  'braintreegateway.com': ESSENTIAL,
  'brand-server.com': ADVERTISING,
  'brandmetrics.com': ANALYTICS,
  'brandview.com': ANALYTICS,
  'breakingburner.com': SOCIAL_NETWORKING,
  'brealtime.com': ADVERTISING,
  'bridgetrack.com': ADVERTISING,
  'brilig.com': ADVERTISING,
  'brontops.com': ADVERTISING,
  'browsiprod.com': ADVERTISING,
  'brsvr.com': ADVERTISING,
  'bsd.net': ADVERTISING,
  'bstatic.com': PERFORMANCE,
  'btncdn.com': ADVERTISING,
  'btrll.com': ADVERTISING,
  'bttrack.com': ADVERTISING,
  'btttag.com': ANALYTICS,
  'bufferapp.com': SOCIAL_NETWORKING,
  'bugherd.com': ESSENTIAL,
  'builder.io': PERFORMANCE,
  'bunchbox.co': ANALYTICS,
  'burt.io': ANALYTICS,
  'businesscatalyst.com': PERFORMANCE,
  'buto.tv': ADVERTISING,
  'buysellads.net': ADVERTISING,
  'buzzfed.com': SOCIAL_NETWORKING,
  'bwbx.io': SOCIAL_NETWORKING,
  'byside.com': ANALYTICS,
  'c1exchange.com': ADVERTISING,
  'c3tag.com': ANALYTICS,
  'cachefly.net': ESSENTIAL,
  'cafonline.org': ESSENTIAL,
  'callrail.com': ANALYTICS,
  'camads.net': PERFORMANCE,
  'campaigner.com': ADVERTISING,
  'campanja.com': ADVERTISING,
  'canddi.com': ADVERTISING,
  'cannedbanners.com': ADVERTISING,
  'canopylabs.com': ANALYTICS,
  'captifymedia.com': ADVERTISING,
  'captivate.ai': ADVERTISING,
  'captora.com': ADVERTISING,
  'carbonads.com': ADVERTISING,
  'cardlytics.com': ADVERTISING,
  'cartstack.com': PERFORMANCE,
  'caspianmedia.com': ADVERTISING,
  'cast.rocks': ESSENTIAL,
  'cavisson.com': ANALYTICS,
  'ccm2.net': SOCIAL_NETWORKING,
  'ccmp.eu': ADVERTISING,
  'cdn-net.com': ESSENTIAL,
  'cdn.bubble.io': ESSENTIAL,
  'cdn.jsdelivr.net': ESSENTIAL,
  'cdn.mouseflow.com': ESSENTIAL,
  'cdn.shopify.com': ESSENTIAL,
  'cdn.tambourine.com': ESSENTIAL,
  'cdn.usehero.com': PERFORMANCE,
  'cdn77.org': ESSENTIAL,
  'cdnds.net': PERFORMANCE,
  'cdninstagram.com': SOCIAL_NETWORKING,
  'cdnjs.cloudflare.com': ESSENTIAL,
  'cdnplanet.com': ANALYTICS,
  'cdnslate.com': PERFORMANCE,
  'celebrus.com': ANALYTICS,
  'celtra.com': ADVERTISING,
  'certona.net': ANALYTICS,
  'certum.pl': ESSENTIAL,
  'cfjump.com': ADVERTISING,
  'channel.me': PERFORMANCE,
  'chargeads.com': ADVERTISING,
  'chartbeat.net': ANALYTICS,
  'chatwoot.com': PERFORMANCE,
  'checkm8.com': ADVERTISING,
  'checkout.com': ESSENTIAL,
  'checkrate.co.uk': ESSENTIAL,
  'checktestsite.com': ADVERTISING,
  'chitika.net': ADVERTISING,
  'choicestream.com': ADVERTISING,
  'chtah.com': ADVERTISING,
  'cint.com': SOCIAL_NETWORKING,
  'civiccomputing.com': PERFORMANCE,
  'clarity.microsoft.com': ANALYTICS,
  'clearbrain.com': ANALYTICS,
  'clearrise.com': PERFORMANCE,
  'clerk.io': ANALYTICS,
  'click4assistance.co.uk': PERFORMANCE,
  'clickability.com': PERFORMANCE,
  'clickable.net': SOCIAL_NETWORKING,
  'clickadu.com': ADVERTISING,
  'clickagy.com': ADVERTISING,
  'clickdensity.com': ANALYTICS,
  'clickdimensions.com': ADVERTISING,
  'clickfuse.com': ADVERTISING,
  'clicktale.net': ANALYTICS,
  'clicktracks.com': ADVERTISING,
  'clicktripz.com': PERFORMANCE,
  'clikpic.com': PERFORMANCE,
  'cloud-iq.com': ANALYTICS,
  'cloudflareinsights.com': ANALYTICS,
  'cloudfront.net': ESSENTIAL,
  'cloudinary.com': PERFORMANCE,
  'cloudsponge.com': ADVERTISING,
  'clrstm.com': ADVERTISING,
  'cludo.com': ESSENTIAL,
  'cnbc.com': PERFORMANCE,
  'cnetcontent.com': PERFORMANCE,
  'cnstrc.com': ESSENTIAL,
  'co-buying.com': ADVERTISING,
  'codigo.se': ANALYTICS,
  'cogocast.net': ADVERTISING,
  'coherentpath.com': ESSENTIAL,
  'colbenson.com': ESSENTIAL,
  'collective-media.net': ADVERTISING,
  'colpirio.com': ANALYTICS,
  'comm100.com': PERFORMANCE,
  'communicatoremail.com': ADVERTISING,
  'comodo.net': ESSENTIAL,
  'comodoca4.com': ESSENTIAL,
  'compete.com': ANALYTICS,
  'concert.io': ADVERTISING,
  'condenast.co.uk': PERFORMANCE,
  'conductrics.com': ANALYTICS,
  'confirmit.com': ANALYTICS,
  'connatix.com': ADVERTISING,
  'connect.facebook.net': ADVERTISING,
  'connectevents.com.au': PERFORMANCE,
  'connexity.net': ANALYTICS,
  'connextra.com': PERFORMANCE,
  'consumedmedia.com': ADVERTISING,
  'contactatonce.com': PERFORMANCE,
  'content.ad': ADVERTISING,
  'contentful.com': ESSENTIAL,
  'contentmedia.eu': PERFORMANCE,
  'contentreserve.com': PERFORMANCE,
  'contentspread.net': ADVERTISING,
  'contentsquare.net': ANALYTICS,
  'contextweb.com': ADVERTISING,
  'converge-digital.com': ADVERTISING,
  'conversio.com': ADVERTISING,
  'conversionlogic.net': ADVERTISING,
  'convertexperiments.com': ANALYTICS,
  'convertro.com': ADVERTISING,
  'conviva.com': PERFORMANCE,
  'cookie-script.com': ESSENTIAL,
  'cookiebot.com': ESSENTIAL,
  'cookieq.com': ESSENTIAL,
  'cookiereports.com': ESSENTIAL,
  'cooladata.com': ANALYTICS,
  'coral.coralproject.net': PERFORMANCE,
  'coremetrics.eu': ANALYTICS,
  'coull.com': ADVERTISING,
  'counciladvertising.net': ADVERTISING,
  'covet.pics': PERFORMANCE,
  'cpex.cz': PERFORMANCE,
  'cpx.to': ADVERTISING,
  'cpxinteractive.com': ADVERTISING,
  'cqcounter.com': ANALYTICS,
  'crcom.co.uk': ESSENTIAL,
  'creafi-online-media.com': ADVERTISING,
  'createjs.com': ESSENTIAL,
  'creative-serving.com': PERFORMANCE,
  'creativecdn.com': ADVERTISING,
  'creativesyndicator.com': ADVERTISING,
  'creators.co': PERFORMANCE,
  'crisp.chat': PERFORMANCE,
  'criteo.net': ADVERTISING,
  'crowdignite.com': ADVERTISING,
  'crowdskout.com': ADVERTISING,
  'crowdtwist.com': ADVERTISING,
  'crsspxl.com': ADVERTISING,
  'crwdcntrl.net': ADVERTISING,
  'csi.gstatic.com': ESSENTIAL,
  'ctctcdn.com': ADVERTISING,
  'ctnsnet.com': ADVERTISING,
  'ctscdn.com': PERFORMANCE,
  'customer.io': ADVERTISING,
  'custora.com': ANALYTICS,
  'd-bi.fr': ADVERTISING,
  'd116tqlcqfmz3v.cloudfront.net': ADVERTISING,
  'd1447tq2m68ekg.cloudfront.net': ANALYTICS,
  'd191y0yd6d0jy4.cloudfront.net': ANALYTICS,
  'd1af033869koo7.cloudfront.net': PERFORMANCE,
  'd1azc1qln24ryf.cloudfront.net': ESSENTIAL,
  'd1eoo1tco6rr5e.cloudfront.net': ADVERTISING,
  'd1gwclp1pmzk26.cloudfront.net': PERFORMANCE,
  'd1l6p2sc9645hc.cloudfront.net': ANALYTICS,
  'd1q62gfb8siqnm.cloudfront.net': ANALYTICS,
  'd1stxfv94hrhia.cloudfront.net': ADVERTISING,
  'd1va5oqn59yrvt.cloudfront.net': PERFORMANCE,
  'd1w78njrm56n7g.cloudfront.net': PERFORMANCE,
  'd1z2jf7jlzjs58.cloudfront.net': ANALYTICS,
  'd22xmn10vbouk4.cloudfront.net': ANALYTICS,
  'd24n15hnbwhuhn.cloudfront.net': ANALYTICS,
  'd26b395fwzu5fz.cloudfront.net': ANALYTICS,
  'd26opx5dl8t69i.cloudfront.net': ANALYTICS,
  'd2blwevgjs7yom.cloudfront.net': ANALYTICS,
  'd2eeipcrcdle6.cloudfront.net': ANALYTICS,
  'd2gfdmu30u15x7.cloudfront.net': ANALYTICS,
  'd2hkbi3gan6yg6.cloudfront.net': ANALYTICS,
  'd2j3qa5nc37287.cloudfront.net': ADVERTISING,
  'd2jjzw81hqbuqv.cloudfront.net': ADVERTISING,
  'd2oh4tlt9mrke9.cloudfront.net': ANALYTICS,
  'd2r1yp2w7bby2u.cloudfront.net': ANALYTICS,
  'd2uncb19xzxhzx.cloudfront.net': ADVERTISING,
  'd2vig74li2resi.cloudfront.net': ANALYTICS,
  'd2wy8f7a9ursnm.cloudfront.net': ESSENTIAL,
  'd2zah9y47r7bi2.cloudfront.net': ANALYTICS,
  'd31j93rd8oukbv.cloudfront.net': ANALYTICS,
  'd31qbv1cthcecs.cloudfront.net': ANALYTICS,
  'd31y97ze264gaa.cloudfront.net': ADVERTISING,
  'd32hwlnfiv2gyn.cloudfront.net': ANALYTICS,
  'd335luupugsy2.cloudfront.net': ADVERTISING,
  'd36mpcpuzc4ztk.cloudfront.net': PERFORMANCE,
  'd3701cc9l7v9a6.cloudfront.net': PERFORMANCE,
  'd37gvrvc0wt4s1.cloudfront.net': ESSENTIAL,
  'd38nbbai6u794i.cloudfront.net': ANALYTICS,
  'd3alqb8vzo7fun.cloudfront.net': ADVERTISING,
  'd3c3cq33003psk.cloudfront.net': ESSENTIAL,
  'd3ezl4ajpp2zy8.cloudfront.net': ADVERTISING,
  'd3hmp0045zy3cs.cloudfront.net': ANALYTICS,
  'd3j0zfs7paavns.cloudfront.net': PERFORMANCE,
  'd3l7tj34e9fc43.cloudfront.net': PERFORMANCE,
  'd3qxef4rp70elm.cloudfront.net': ADVERTISING,
  'd3qxwzhswv93jk.cloudfront.net': ADVERTISING,
  'd3tjaysgumg9lf.cloudfront.net': ESSENTIAL,
  'd3vbj265bmdenw.cloudfront.net': ANALYTICS,
  'd6tizftlrpuof.cloudfront.net': ANALYTICS,
  'd8rk54i4mohrb.cloudfront.net': ANALYTICS,
  'd9hhrg4mnvzow.cloudfront.net': ADVERTISING,
  'd9jmv9u00p0mv.cloudfront.net': ANALYTICS,
  'dailymail.co.uk': PERFORMANCE,
  'dailymotion.com': PERFORMANCE,
  'dashhudson.com': PERFORMANCE,
  'datahc.com': PERFORMANCE,
  'daumcdn.net': SOCIAL_NETWORKING,
  'dcoengine.com': ADVERTISING,
  'dcslsoftware.com': PERFORMANCE,
  'dd6zx4ibq538k.cloudfront.net': ANALYTICS,
  'dds6m601du5ji.cloudfront.net': ADVERTISING,
  'de17a.com': ADVERTISING,
  'dealer.com': PERFORMANCE,
  'dealtime.com': PERFORMANCE,
  'decibelinsight.net': ANALYTICS,
  'deepintent.com': ADVERTISING,
  'delicious.com': SOCIAL_NETWORKING,
  'delineo.com': ADVERTISING,
  'delivery.net': ADVERTISING,
  'demandbase.com': ADVERTISING,
  'demandjump.com': ANALYTICS,
  'demandware.edgesuite.net': PERFORMANCE,
  'deployads.com': ADVERTISING,
  'devatics.io': ANALYTICS,
  'developermedia.com': ADVERTISING,
  'deximedia.com': ADVERTISING,
  'dg1f2pfrgjxdq.cloudfront.net': ADVERTISING,
  'dialogtech.com': ADVERTISING,
  'dianomioffers.co.uk': ADVERTISING,
  'digicert.com': ESSENTIAL,
  'digitallook.com': PERFORMANCE,
  'digitalscirocco.net': ANALYTICS,
  'digitru.st': ANALYTICS,
  'directededge.com': SOCIAL_NETWORKING,
  'displaymarketplace.com': ADVERTISING,
  'disqus.com': SOCIAL_NETWORKING,
  'disquscdn.com': SOCIAL_NETWORKING,
  'districtm.io': ADVERTISING,
  'divido.com': ESSENTIAL,
  'djnf6e5yyirys.cloudfront.net': ADVERTISING,
  'dkpklk99llpj0.cloudfront.net': ANALYTICS,
  'dm.gg': PERFORMANCE,
  'dmca.com': ESSENTIAL,
  'dmcdn.net': PERFORMANCE,
  'dmtracker.com': PERFORMANCE,
  'dmtry.com': ADVERTISING,
  'dn1i8v75r669j.cloudfront.net': ADVERTISING,
  'dn3y71tq7jf07.cloudfront.net': ANALYTICS,
  'dnn506yrbagrg.cloudfront.net': ANALYTICS,
  'dntrax.com': ADVERTISING,
  'dntx.com': ADVERTISING,
  'donreach.com': SOCIAL_NETWORKING,
  'dotmailer-surveys.com': ANALYTICS,
  'doubleclick.net': ADVERTISING,
  'doublepimp.com': ADVERTISING,
  'dowjoneson.com': PERFORMANCE,
  'dpbolvw.net': ADVERTISING,
  'dpclk.com': ADVERTISING,
  'dpmsrv.com': ADVERTISING,
  'dpstatic.com': ESSENTIAL,
  'drct2u.com': PERFORMANCE,
  'drift.click': PERFORMANCE,
  'drift.com': PERFORMANCE,
  'driftt.com': PERFORMANCE,
  'driving.co.uk': PERFORMANCE,
  'dsms0mj1bbhn4.cloudfront.net': SOCIAL_NETWORKING,
  'dsp.io': ANALYTICS,
  'dsply.com': ANALYTICS,
  'dsyszv14g9ymi.cloudfront.net': ANALYTICS,
  'dt07.net': ADVERTISING,
  'dtrck.net': ADVERTISING,
  'dtscout.com': ADVERTISING,
  'dummyimage.com': ESSENTIAL,
  'dwcdn.net': ESSENTIAL,
  'dwin1.com': ADVERTISING,
  'dwmvwp56lzq5t.cloudfront.net': ADVERTISING,
  'dyjnzf8evxrp2.cloudfront.net': ADVERTISING,
  'dynamicconverter.com': ESSENTIAL,
  'dynamicyield.com': PERFORMANCE,
  'dynapis.info': ANALYTICS,
  'dyntrk.com': ADVERTISING,
  'e-shots.eu': ADVERTISING,
  'e-travel.com': PERFORMANCE,
  'e-webtrack.net': ADVERTISING,
  'easy-ads.com': ADVERTISING,
  'easypolls.net': ANALYTICS,
  'ebdr3.com': ADVERTISING,
  'ebiquitymedia.com': ANALYTICS,
  'ebz.io': ADVERTISING,
  'ecommercefeed.net': ADVERTISING,
  'econda-monitor.de': ANALYTICS,
  'ecorebates.com': ADVERTISING,
  'ectnews.com': PERFORMANCE,
  'ecustomeropinions.com': PERFORMANCE,
  'edaa.eu': ESSENTIAL,
  'edge-cdn.net': PERFORMANCE,
  'edgecastdns.net': ESSENTIAL,
  'edgefonts.net': ESSENTIAL,
  'editiondigital.com': ADVERTISING,
  'edot.co.za': PERFORMANCE,
  'eff1.net': ADVERTISING,
  'effectivemeasure.net': ADVERTISING,
  'effiliation.com': ADVERTISING,
  'efm.me': ANALYTICS,
  'egain.com': ANALYTICS,
  'egiftify.com': ESSENTIAL,
  'ehosts.net': PERFORMANCE,
  'ekmpinpoint.co.uk': ANALYTICS,
  'ekomi.de': ANALYTICS,
  'elastera.net': PERFORMANCE,
  'elasticad.net': ADVERTISING,
  'elevaate.technology': ESSENTIAL,
  'elicitapp.com': ESSENTIAL,
  'elitechnology.com': ANALYTICS,
  'elogia.net': ADVERTISING,
  'email-attitude.com': ADVERTISING,
  'emailcenteruk.com': ADVERTISING,
  'emap.com': PERFORMANCE,
  'embed.ly': PERFORMANCE,
  'emediate.eu': ADVERTISING,
  'emerse.com': ADVERTISING,
  'emlfiles.com': ADVERTISING,
  'empathybroker.com': ESSENTIAL,
  'emsecure.net': ADVERTISING,
  'emxdgt.com': ADVERTISING,
  'enfusen.com': ANALYTICS,
  'engagio.com': ADVERTISING,
  'ensighten.com': ESSENTIAL,
  'entrust.net': ESSENTIAL,
  'eolcdn.com': ADVERTISING,
  'episerver.net': PERFORMANCE,
  'epoq.de': ANALYTICS,
  'eqads.com': ADVERTISING,
  'equiniti.com': ESSENTIAL,
  'errorception.com': ESSENTIAL,
  'esearchvision.com': ANALYTICS,
  'ethn.io': ANALYTICS,
  'etouches.com': PERFORMANCE,
  'etracker.de': ANALYTICS,
  'euroland.com': ESSENTIAL,
  'eventbrite.co.uk': PERFORMANCE,
  'events.framer.com': ESSENTIAL,
  'everestjs.net': PERFORMANCE,
  'everesttech.net': ESSENTIAL,
  'evgnet.com': ANALYTICS,
  'evidon.com': ANALYTICS,
  'evisitanalyst.com': ANALYTICS,
  'evolvemediallc.com': PERFORMANCE,
  'evq1.com': PERFORMANCE,
  'eway.com.au': ESSENTIAL,
  'exactag.com': ADVERTISING,
  'exe.bid': ADVERTISING,
  'exelator.com': ADVERTISING,
  'exoclick.com': ADVERTISING,
  'expedia.ca': PERFORMANCE,
  'expedia.co.jp': PERFORMANCE,
  'expedia.co.uk': PERFORMANCE,
  'expedia.com': PERFORMANCE,
  'expedia.com.au': PERFORMANCE,
  'expedia.de': PERFORMANCE,
  'expedia.fr': PERFORMANCE,
  'expedia.it': PERFORMANCE,
  'experianmarketingservices.digital': ESSENTIAL,
  'exponea.com': ANALYTICS,
  'exponential.com': ADVERTISING,
  'extend.tv': ADVERTISING,
  'extole.io': ADVERTISING,
  'extreme-dm.com': ANALYTICS,
  'eyeota.net': ADVERTISING,
  'eyereturn.com': ADVERTISING,
  'eyeviewads.com': ADVERTISING,
  'eysys.com': ADVERTISING,
  'ezakus.net': ADVERTISING,
  'ezoic.net': ANALYTICS,
  'f3d.io': PERFORMANCE,
  'facebook.com': SOCIAL_NETWORKING,
  'fam-ad.com': ADVERTISING,
  'fast-thinking.co.uk': ADVERTISING,
  'fastapi.net': ADVERTISING,
  'fastclick.net': ANALYTICS,
  'fastly-insights.com': ANALYTICS,
  'fastly.net': ESSENTIAL,
  'fbcdn-photos-e-a.akamaihd.net': SOCIAL_NETWORKING,
  'fbcdn.net': SOCIAL_NETWORKING,
  'fbsbx.com': SOCIAL_NETWORKING,
  'feedbackify.com': ANALYTICS,
  'feedmagnet.com': ANALYTICS,
  'feefo.com': ANALYTICS,
  'fetchback.com': ADVERTISING,
  'fidelity-media.com': ADVERTISING,
  'fiftyone.com': ESSENTIAL,
  'filepicker.io': PERFORMANCE,
  'filesusr.com': ESSENTIAL,
  'fimserve.com': ADVERTISING,
  'finsburymedia.com': ADVERTISING,
  'firepush.io': ESSENTIAL,
  'firestore.googleapis.com': ESSENTIAL,
  'firstimpression.io': ADVERTISING,
  'fits.me': ANALYTICS,
  'five.tv': ADVERTISING,
  'fivetran.com': ANALYTICS,
  'flashtalking.com': ADVERTISING,
  'flaticons.net': ESSENTIAL,
  'flexshopper.com': ESSENTIAL,
  'flipboard.com': SOCIAL_NETWORKING,
  'flite.com': ADVERTISING,
  'flixfacts.co.uk': ANALYTICS,
  'flockler.com': SOCIAL_NETWORKING,
  'flowplayer.org': PERFORMANCE,
  'flxpxl.com': ADVERTISING,
  'fontawesome.com': ESSENTIAL,
  'fontdeck.com': ESSENTIAL,
  'fonts.gstatic.com': ESSENTIAL,
  'fonts.net': ESSENTIAL,
  'foodity.com': ADVERTISING,
  'footprint.net': ESSENTIAL,
  'force24.co.uk': ADVERTISING,
  'foreseeresults.com': ANALYTICS,
  'fortawesome.com': ESSENTIAL,
  'forter.com': ESSENTIAL,
  'forward3d.com': ADVERTISING,
  'fospha.com': ANALYTICS,
  'foursixty.com': PERFORMANCE,
  'fout.jp': ADVERTISING,
  'foxentry.cz': ESSENTIAL,
  'foxycart.com': ESSENTIAL,
  'fqtag.com': ESSENTIAL,
  'freegeoip.net': ESSENTIAL,
  'freeskreen.com': ADVERTISING,
  'freespee.com': PERFORMANCE,
  'freetobook.com': PERFORMANCE,
  'fresh8.co': ADVERTISING,
  'freshchat.com': ESSENTIAL,
  'freshworksapi.com': ESSENTIAL,
  'frienefit.com': ADVERTISING,
  'frontend.symphonyhotelmarketing.com': ESSENTIAL,
  'fstech.co.uk': PERFORMANCE,
  'ftjcfx.com': ADVERTISING,
  'fuel451.com': PERFORMANCE,
  'fuelx.com': ADVERTISING,
  'fullstory.com': ANALYTICS,
  'fwmrm.net': PERFORMANCE,
  'fyber.com': ADVERTISING,
  'gaug.es': ANALYTICS,
  'gccdn.net': ESSENTIAL,
  'gdgt.com': PERFORMANCE,
  'gdmdigital.com': ADVERTISING,
  'geckotribe.com': SOCIAL_NETWORKING,
  'gemius.pl': ADVERTISING,
  'genesismediaus.com': ADVERTISING,
  'geni.us': ANALYTICS,
  'genieventures.co.uk': ADVERTISING,
  'geoplugin.net': ESSENTIAL,
  'georeferencer.com': ESSENTIAL,
  'geotrust.com': ESSENTIAL,
  'getambassador.com': ADVERTISING,
  'getclicky.com': ANALYTICS,
  'getdrip.com': ADVERTISING,
  'getletterpress.com': ADVERTISING,
  'getmein.com': PERFORMANCE,
  'getresponse.com': ADVERTISING,
  'getscenario.com': ANALYTICS,
  'getsitecontrol.com': ESSENTIAL,
  'getsocial.io': SOCIAL_NETWORKING,
  'gettyimages.co.uk': PERFORMANCE,
  'gexperiments1.com': ANALYTICS,
  'gforcesinternal.co.uk': PERFORMANCE,
  'gfycat.com': ESSENTIAL,
  'gigya.com': ANALYTICS,
  'giphy.com': PERFORMANCE,
  'glassdoor.com': PERFORMANCE,
  'gleam.io': ADVERTISING,
  'glimr.io': ANALYTICS,
  'global-e.com': PERFORMANCE,
  'globalsign.net': ESSENTIAL,
  'globalwebindex.net': ANALYTICS,
  'globase.com': ADVERTISING,
  'gmads.net': ADVERTISING,
  'gnatta.com': PERFORMANCE,
  'go2cloud.org': ADVERTISING,
  'goadservices.com': ESSENTIAL,
  'gointerpay.net': ESSENTIAL,
  'gomoxie.solutions': ESSENTIAL,
  'goodlayers2.com': PERFORMANCE,
  'google-analytics.com': ANALYTICS,
  'google.': PERFORMANCE,
  'googleadservice.com': ADVERTISING,
  'googleadservices.com': ADVERTISING,
  'googleapis.com': ESSENTIAL,
  'googlecommerce.com': ESSENTIAL,
  'googlesyndication.com': ADVERTISING,
  'googletagmanager.com': {
    _default: ESSENTIAL,
    '/gtag/js': {
      id: new Map([
        [/^AW-.+/, ADVERTISING],
        [/^DC-.+/, ADVERTISING],
        [/^G-.+/, ANALYTICS],
        [/^UA-.+/, ANALYTICS],
      ]),
    },
  },
  'googletagservices.com': ADVERTISING,
  'googlevideo.com': PERFORMANCE,
  'goroost.com': ESSENTIAL,
  'goshoplogic.com': ADVERTISING,
  'goshowoff.com': PERFORMANCE,
  'gotraffic.net': PERFORMANCE,
  'govmetric.com': ANALYTICS,
  'granify.com': ANALYTICS,
  'grapeshot.co.uk': ADVERTISING,
  'gravatar.com': SOCIAL_NETWORKING,
  'gravity.com': ADVERTISING,
  'greatmagazines.co.uk': PERFORMANCE,
  'groovygecko.net': PERFORMANCE,
  'grvcdn.com': ANALYTICS,
  'gsipartners.com': PERFORMANCE,
  'gssprt.jp': ADVERTISING,
  'guardian.co.uk': ADVERTISING,
  'gumgum.com': ADVERTISING,
  'gumtree.com': PERFORMANCE,
  'hatena.ne.jp': PERFORMANCE,
  'hawksearch.com': ESSENTIAL,
  'hawksearch.info': ESSENTIAL,
  'hbpl.co.uk': PERFORMANCE,
  'heapanalytics.com': ANALYTICS,
  'heatmap.it': ANALYTICS,
  'heias.com': ADVERTISING,
  'helpscout.net': PERFORMANCE,
  'hexagon-analytics.com': ANALYTICS,
  'hextom.com': ESSENTIAL,
  'hiberniacdn.com': ESSENTIAL,
  'hifx.com': ESSENTIAL,
  'highcharts.com': ESSENTIAL,
  'histats.com': ANALYTICS,
  'hits.io': ANALYTICS,
  'hitslink.com': ANALYTICS,
  'hitsprocessor.com': ANALYTICS,
  'hmcdn.baidu.com': ANALYTICS,
  'hootsuite.com': ANALYTICS,
  'hotjar.com': ANALYTICS,
  'hotjar.io': ANALYTICS,
  'hotmart.com': PERFORMANCE,
  'hotukdeals.com': ANALYTICS,
  'hotwords.com.br': ADVERTISING,
  'hoverr.media': ADVERTISING,
  'hubspot.net': ADVERTISING,
  'hubvisor.io': ADVERTISING,
  'hullapp.io': ESSENTIAL,
  'hupso.com': ANALYTICS,
  'hwcdn.net': ESSENTIAL,
  'hyfn.com': ADVERTISING,
  'hyperinzerce.cz': ADVERTISING,
  'i-mobile.co.jp': ADVERTISING,
  'i-transactads.com': ADVERTISING,
  'iadvize.com': PERFORMANCE,
  'iasds01.com': ADVERTISING,
  'ib-ibi.com': ADVERTISING,
  'ibillboard.com': ADVERTISING,
  'ibmcloud.com': ANALYTICS,
  'ibpxl.com': PERFORMANCE,
  'ic-live.com': ADVERTISING,
  'ic.com.au': ESSENTIAL,
  'icnetwork.co.uk': SOCIAL_NETWORKING,
  'id-visitors.com': ADVERTISING,
  'identrust.com': ESSENTIAL,
  'idio.co': PERFORMANCE,
  'idtargeting.com': ADVERTISING,
  'iesnare.com': ESSENTIAL,
  'ifactory.com': PERFORMANCE,
  'ifdnrg.com': PERFORMANCE,
  'igodigital.com': ANALYTICS,
  'ijento.com': ADVERTISING,
  'iljmp.com': ANALYTICS,
  'im-apps.net': ADVERTISING,
  'images-blogger-opensocial.googleusercontent.com': PERFORMANCE,
  'imallcdn.net': PERFORMANCE,
  'imedia.cz': ESSENTIAL,
  'imgix.net': ESSENTIAL,
  'imgsafe.org': PERFORMANCE,
  'imgur.com': ESSENTIAL,
  'impact-ad.jp': ADVERTISING,
  'imrg.org': ANALYTICS,
  'imrworldwide.com': ANALYTICS,
  'inbenta.com': PERFORMANCE,
  'incisivemedia.com': PERFORMANCE,
  'indeed.com': PERFORMANCE,
  'indexww.com': ADVERTISING,
  'inferencemobile.com': ADVERTISING,
  'infinity-tracking.net': ANALYTICS,
  'influid.co': PERFORMANCE,
  'infolinks.com': ADVERTISING,
  'infusionsoft.com': ADVERTISING,
  'inktad.com': ADVERTISING,
  'inktel.com': PERFORMANCE,
  'inmobi.com': ADVERTISING,
  'inmz.net': ADVERTISING,
  'inner-active.mobi': ADVERTISING,
  'innomdc.com': ANALYTICS,
  'innovid.com': ADVERTISING,
  'inpagevideo.nl': ADVERTISING,
  'inside-graph.com': PERFORMANCE,
  'insightexpressai.com': ANALYTICS,
  'inskinmedia.com': ADVERTISING,
  'insnw.net': ESSENTIAL,
  'inspectlet.com': ANALYTICS,
  'instaembedder.com': PERFORMANCE,
  'instagram.com': SOCIAL_NETWORKING,
  'instansive.com': ESSENTIAL,
  'instantservice.com': ADVERTISING,
  'instinctiveads.com': ADVERTISING,
  'integration.mywebsite-editor.com': ESSENTIAL,
  'intelli-direct.com': ADVERTISING,
  'intenthq.com': ANALYTICS,
  'intentiq.com': ADVERTISING,
  'intentmedia.net': ADVERTISING,
  'intercom.com': PERFORMANCE,
  'intercom.io': PERFORMANCE,
  'interstateanalytics.com': ANALYTICS,
  'intilery-analytics.com': PERFORMANCE,
  'investis.com': ESSENTIAL,
  'invodo.com': ADVERTISING,
  'ioam.de': ANALYTICS,
  'ipage.com': PERFORMANCE,
  'iperceptions.com': PERFORMANCE,
  'ipify.org': ESSENTIAL,
  'ipinfo.io': ESSENTIAL,
  'ipredictive.com': ADVERTISING,
  'ipstatp.com': SOCIAL_NETWORKING,
  'ipv6test.net': ANALYTICS,
  'iqfp1.com': ADVERTISING,
  'iqm.cc': ESSENTIAL,
  'ispot.tv': ADVERTISING,
  'issue.by': PERFORMANCE,
  'ist-track.com': ADVERTISING,
  'isu.pub': PERFORMANCE,
  'iteratehq.com': ANALYTICS,
  'iubenda.com': ESSENTIAL,
  'iwantthatflight.com.au': PERFORMANCE,
  'ixiaa.com': ADVERTISING,
  'izooto.com': ADVERTISING,
  'jaywing.com': ADVERTISING,
  'jd.com': PERFORMANCE,
  'jellyfish.net': ADVERTISING,
  'jifo.co': ESSENTIAL,
  'jivosite.com': PERFORMANCE,
  'jivox.com': ADVERTISING,
  'jobvite.com': PERFORMANCE,
  'jotformpro.com': ESSENTIAL,
  'jpress.co.uk': PERFORMANCE,
  'jquery.com': ESSENTIAL,
  'jquerytools.org': ESSENTIAL,
  'js-cdn.dynatrace.com': ANALYTICS,
  'js.intercomcdn.com': PERFORMANCE,
  'jsdelivr.net': ESSENTIAL,
  'jsonip.com': ANALYTICS,
  'jsrdn.com': ADVERTISING,
  'jtdiscuss.com': SOCIAL_NETWORKING,
  'judge.me': ADVERTISING,
  'juicyads.com': ADVERTISING,
  'jumptap.com': ADVERTISING,
  'justpremium.com': ADVERTISING,
  'jwplayer.com': PERFORMANCE,
  'kaizenplatform.net': ANALYTICS,
  'kaltura.com': PERFORMANCE,
  'kameleoon.com': ANALYTICS,
  'kampyle.com': ANALYTICS,
  'kargo.com': ADVERTISING,
  'kau.li': ADVERTISING,
  'kelkoo.com': PERFORMANCE,
  'keyade.com': ANALYTICS,
  'keynote.com': ANALYTICS,
  'keywee.co': ADVERTISING,
  'kiosked.com': ADVERTISING,
  'klarna.com': ESSENTIAL,
  'klaviyo.com': ADVERTISING,
  'klevu.com': ESSENTIAL,
  'klick2contact.com': PERFORMANCE,
  'knightlab.com': ESSENTIAL,
  'komoona.com': ADVERTISING,
  'kontera.com': ADVERTISING,
  'korrelate.net': ANALYTICS,
  'krxd.net': ANALYTICS,
  'kxcdn.com': ESSENTIAL,
  'ladsp.com': ADVERTISING,
  'launchdarkly.com': ESSENTIAL,
  'leadboxer.com': ADVERTISING,
  'leadintelligence.co.uk': ADVERTISING,
  'leadmanagerfx.com': ADVERTISING,
  'leafletjs.com': ESSENTIAL,
  'leasewebcdn.com': ESSENTIAL,
  'leboncoin.fr': PERFORMANCE,
  'legolas-media.com': ADVERTISING,
  'lengow.com': PERFORMANCE,
  'lenmit.com': ADVERTISING,
  'lessbuttons.com': SOCIAL_NETWORKING,
  'letsencrypt.org': ESSENTIAL,
  'levexis.com': ESSENTIAL,
  'lfstmedia.com': SOCIAL_NETWORKING,
  'lib.showit.co': ESSENTIAL,
  'licdn.com.': ADVERTISING,
  'liftdna.com': ADVERTISING,
  'ligadx.com': ADVERTISING,
  'lightboxcdn.com': ADVERTISING,
  'lightstep.com': ANALYTICS,
  'lightwidget.com': ESSENTIAL,
  'lijit.com': ADVERTISING,
  'likelihood.com': PERFORMANCE,
  'likeshop.me': PERFORMANCE,
  'line.me': ADVERTISING,
  'linkcious.com': ANALYTICS,
  'linkedin.com': SOCIAL_NETWORKING,
  'linkingmobile.com': ADVERTISING,
  'linksynergy.com': ADVERTISING,
  'listrakbi.com': ADVERTISING,
  'litix.io': ANALYTICS,
  'livebookings.com': PERFORMANCE,
  'liveburst.com': ADVERTISING,
  'livechat-static.com': PERFORMANCE,
  'liveclicker.net': ADVERTISING,
  'livefyre.com': PERFORMANCE,
  'livehelpnow.net': PERFORMANCE,
  'livejournal.net': SOCIAL_NETWORKING,
  'livetex.ru': PERFORMANCE,
  'livingmap.com': ESSENTIAL,
  'lkqd.net': ADVERTISING,
  'locayta.com': ESSENTIAL,
  'lockerdome.com': ANALYTICS,
  'logentries.com': ESSENTIAL,
  'loginradius.com': SOCIAL_NETWORKING,
  'lognormal.net': ANALYTICS,
  'logo-net.co.uk': ANALYTICS,
  'longtailvideo.com': ADVERTISING,
  'loop11.com': ANALYTICS,
  'looper.com': PERFORMANCE,
  'loopme.me': ADVERTISING,
  'loyaltygateway.com': ADVERTISING,
  'loyaltypoint.pl': ADVERTISING,
  'lp4.io': ANALYTICS,
  'lpsnmedia.net': PERFORMANCE,
  'lrcdn.net': ADVERTISING,
  'luckyorange.net': ANALYTICS,
  'luigisbox.com': ESSENTIAL,
  'lumatag.co.uk': ANALYTICS,
  'luminate.com': ADVERTISING,
  'lypn.net': ANALYTICS,
  'lytics.io': ADVERTISING,
  'm-decision.com': ANALYTICS,
  'm2.ai': ADVERTISING,
  'm6r.eu': ADVERTISING,
  'macromill.com': ADVERTISING,
  'magnetic.is': ADVERTISING,
  'mailchimp.com': ADVERTISING,
  'mailmunch.co': ADVERTISING,
  'mailplus.nl': ADVERTISING,
  'mapbox.com': PERFORMANCE,
  'maps-api-ssl.google.com': PERFORMANCE,
  'maps.google.com': PERFORMANCE,
  'maps.googleapis.com': PERFORMANCE,
  'maps.gstatic.com': PERFORMANCE,
  'maptive.com': PERFORMANCE,
  'marchex.io': ANALYTICS,
  'markandmini.com': ADVERTISING,
  'marketgid.com': ADVERTISING,
  'marketingautomation.services': ADVERTISING,
  'marketizator.com': ANALYTICS,
  'marketo.net': ANALYTICS,
  'marketwatch.com': PERFORMANCE,
  'matchwork.com': ESSENTIAL,
  'matheranalytics.com': ANALYTICS,
  'mathjax.org': ESSENTIAL,
  'mathtag.com': ADVERTISING,
  'matomo.cloud': ANALYTICS,
  'mautic.com': ADVERTISING,
  'maxmind.com': ESSENTIAL,
  'maxymiser.net': ANALYTICS,
  'mbww.com': ADVERTISING,
  'meclabsdata.com': ANALYTICS,
  'media6degrees.com': ADVERTISING,
  'mediabong.net': ADVERTISING,
  'mediade.sk': ADVERTISING,
  'mediaforge.com': ADVERTISING,
  'mediahawk.co.uk': ANALYTICS,
  'mediaiqdigital.com': ANALYTICS,
  'mediamath.com': ADVERTISING,
  'mediav.com': ADVERTISING,
  'mediavine.com': ADVERTISING,
  'medio.com': ANALYTICS,
  'medium.com': PERFORMANCE,
  'medtargetsystem.com': ADVERTISING,
  'megapopads.com': ADVERTISING,
  'meltwaternews.com': PERFORMANCE,
  'mentad.com': ADVERTISING,
  'mention-me.com': ADVERTISING,
  'mercent.com': ADVERTISING,
  'merchantequip.com': ESSENTIAL,
  'merchenta.com': PERFORMANCE,
  'mesp.com': ADVERTISING,
  'message-business.com': ADVERTISING,
  'metabroadcast.com': SOCIAL_NETWORKING,
  'metadsp.co.uk': PERFORMANCE,
  'metaffiliation.com': ADVERTISING,
  'metanetwork.net': ADVERTISING,
  'metoffice.gov.uk': PERFORMANCE,
  'metrigo.com': ADVERTISING,
  'miaozhen.com': ANALYTICS,
  'micpn.com': ANALYTICS,
  'microad.jp': ADVERTISING,
  'mightypop.ca': ADVERTISING,
  'miisolutions.net': ESSENTIAL,
  'minicabit.com': PERFORMANCE,
  'mirror.co.uk': PERFORMANCE,
  'ml314.com': ADVERTISING,
  'mlno6.com': ADVERTISING,
  'mlveda.com': ESSENTIAL,
  'mnet-ad.net': ADVERTISING,
  'moatpixel.com': ADVERTISING,
  'mobify.net': ESSENTIAL,
  'mobilenations.com': SOCIAL_NETWORKING,
  'mobimanage.com': PERFORMANCE,
  'mobivatebulksms.com': ADVERTISING,
  'momondo.dk': PERFORMANCE,
  'momondo.net': PERFORMANCE,
  'momondogroup.com': PERFORMANCE,
  'monarchads.com': ADVERTISING,
  'monetate.net': ANALYTICS,
  'money.yandex.ru': ESSENTIAL,
  'monkeyfrogmedia.com': PERFORMANCE,
  'moovweb.net': ESSENTIAL,
  'mopinion.com': ANALYTICS,
  'mouse3k.com': ANALYTICS,
  'mouseflow.com': ANALYTICS,
  'mousestats.com': ANALYTICS,
  'moxielinks.com': ADVERTISING,
  'mozilla.org': ESSENTIAL,
  'mparticle.com': ESSENTIAL,
  'mplxtms.com': ESSENTIAL,
  'mpstat.us': ANALYTICS,
  'ms-cdn.multiscreensite.com': ESSENTIAL,
  'msedge.net': ESSENTIAL,
  'msgfocus.com': ADVERTISING,
  'mshcdn.com': SOCIAL_NETWORKING,
  'msocsp.com': ESSENTIAL,
  'mts.googleapis.com': PERFORMANCE,
  'mtvnservices.com': PERFORMANCE,
  'murdoog.com': ADVERTISING,
  'mwdev.co.uk': ADVERTISING,
  'mxcdn.net': ADVERTISING,
  'mxpnl.com': ANALYTICS,
  'mxptint.net': ADVERTISING,
  'mybuys.com': ANALYTICS,
  'myfonts.net': ESSENTIAL,
  'mymovies.net': PERFORMANCE,
  'mynativeplatform.com': ADVERTISING,
  'mynewsdesk.com': ESSENTIAL,
  'myspace.com': SOCIAL_NETWORKING,
  'mythingsmedia.net': ADVERTISING,
  'myvisualiq.net': ANALYTICS,
  'mywebsitebuilder.com': PERFORMANCE,
  'mzstatic.com': PERFORMANCE,
  'namogoo.com': ESSENTIAL,
  'nanigans.com': ADVERTISING,
  'nanorep.com': PERFORMANCE,
  'narrative.io': ADVERTISING,
  'nativeads.com': ADVERTISING,
  'natoms.com': ADVERTISING,
  'navdmp.com': ADVERTISING,
  'navistechnologies.info': PERFORMANCE,
  'naytev.com': ANALYTICS,
  'nccgroup-webperf.com': ANALYTICS,
  'nccgroup.trust': ESSENTIAL,
  'needle.com': ANALYTICS,
  'nend.net': ADVERTISING,
  'neodatagroup.com': ADVERTISING,
  'net.net': ADVERTISING,
  'net.pl': ADVERTISING,
  'netdna-ssl.com': ESSENTIAL,
  'netlifyusercontent.com': ESSENTIAL,
  'netmng.com': ADVERTISING,
  'netop.com': PERFORMANCE,
  'netshelter.net': ADVERTISING,
  'networksolutions.com': ESSENTIAL,
  'newapproachmedia.co.uk': ADVERTISING,
  'newbooking.azds.com': ESSENTIAL,
  'newrelic.com': ANALYTICS,
  'news-static.com': SOCIAL_NETWORKING,
  'newsharecounts.com': SOCIAL_NETWORKING,
  'newsinc.com': PERFORMANCE,
  'newsquestdigital.co.uk': PERFORMANCE,
  'nexac.com': ADVERTISING,
  'nexage.com': ADVERTISING,
  'nexcesscdn.net': PERFORMANCE,
  'nexeps.com': ADVERTISING,
  'nflximg.net': PERFORMANCE,
  'ninemsn.com.au': ESSENTIAL,
  'nitrosell.com': PERFORMANCE,
  'nmm.de': PERFORMANCE,
  'nochex.com': ESSENTIAL,
  'nonprofitsoapbox.com': PERFORMANCE,
  'northernandshell.co.uk': PERFORMANCE,
  'nosto.com': ANALYTICS,
  'notifyapp.io': ADVERTISING,
  'nowinteract.com': ANALYTICS,
  'npmcdn.com': ESSENTIAL,
  'nr-data.net': ESSENTIAL,
  'nrelate.com': PERFORMANCE,
  'ns-cdn.com': ADVERTISING,
  'nscontext.eu': PERFORMANCE,
  'nt.vc': ANALYTICS,
  'ntoklo.com': ANALYTICS,
  'ntv.io': ANALYTICS,
  'nuggad.net': ADVERTISING,
  'numerointeractive.com': ADVERTISING,
  'nxtck.com': ADVERTISING,
  'nzaza.com': ADVERTISING,
  'o.ss2.us': ESSENTIAL,
  'ocsp.usertrust.com': ESSENTIAL,
  'odistatic.net': PERFORMANCE,
  'offerpop.com': ADVERTISING,
  'ogigl.com': ADVERTISING,
  'ohmyad.co': ADVERTISING,
  'ojrq.net': ADVERTISING,
  'okasconcepts.com': ESSENTIAL,
  'okta.com': ANALYTICS,
  'olark.com': PERFORMANCE,
  'ometria.com': ANALYTICS,
  'omguk.com': ESSENTIAL,
  'omniroot.com': ESSENTIAL,
  'omtrdc.net': ANALYTICS,
  'onaudience.com': ADVERTISING,
  'oneall.com': ANALYTICS,
  'onboard.triptease.io': ESSENTIAL,
  'onefeed.co.uk': ADVERTISING,
  'onesignal.com': ESSENTIAL,
  'onet.pl': ADVERTISING,
  'onetag-sys.com': ADVERTISING,
  'onicframework.com': ESSENTIAL,
  'online-metrix.net': ESSENTIAL,
  'onmodulus.net': PERFORMANCE,
  'onscroll.com': ADVERTISING,
  'onstate.co.uk': ADVERTISING,
  'ontame.io': ANALYTICS,
  'onyourmap.com': ESSENTIAL,
  'oodalab.com': ADVERTISING,
  'ooyala.com': ADVERTISING,
  'open.spotifycdn.com': ESSENTIAL,
  'openstreetmap.org': PERFORMANCE,
  'opentok.com': PERFORMANCE,
  'openx.net': ADVERTISING,
  'ophan.co.uk': ANALYTICS,
  'opinionbar.com': ANALYTICS,
  'opinionstage.com': ANALYTICS,
  'opta.net': PERFORMANCE,
  'optilead.co.uk': ANALYTICS,
  'optimahub.com': ESSENTIAL,
  'optimatic.com': ADVERTISING,
  'optimizely.com': ANALYTICS,
  'optimonk.com': ADVERTISING,
  'optimost.com': ADVERTISING,
  'optimove.net': ANALYTICS,
  'optmd.com': ADVERTISING,
  'optmstr.com': ADVERTISING,
  'optorb.com': ADVERTISING,
  'ordergroove.com': ADVERTISING,
  'oriel.io': ADVERTISING,
  'ostkcdn.com': ESSENTIAL,
  'otracking.com': ANALYTICS,
  'owneriq.net': ADVERTISING,
  'ozcart.com.au': ESSENTIAL,
  'p-td.com': PERFORMANCE,
  'p161.net': ADVERTISING,
  'pagefair.net': ADVERTISING,
  'pages05.net': ADVERTISING,
  'pagesense.io': ANALYTICS,
  'pagesuite-professional.co.uk': ADVERTISING,
  'pajmc.com': ADVERTISING,
  'pardot.com': ADVERTISING,
  'passenger.taxicaller.net': PERFORMANCE,
  'payments-amazon.com': ESSENTIAL,
  'paypalobjects.com': ESSENTIAL,
  'paysafecard.com': ESSENTIAL,
  'pbbl.co': ADVERTISING,
  'pcibooking.net': ESSENTIAL,
  'pdst.fm': ADVERTISING,
  'peer1.com': PERFORMANCE,
  'peer39.net': ADVERTISING,
  'peerius.com': ANALYTICS,
  'peermapcontent.affino.com': ANALYTICS,
  'pendo.io': ANALYTICS,
  'peppercorp.com': ADVERTISING,
  'perfectaudience.com': ADVERTISING,
  'perfectmarket.com': ADVERTISING,
  'performax.cz': ADVERTISING,
  'performgroup.com': PERFORMANCE,
  'periscope.tv': PERFORMANCE,
  'persuasionapi.com': ANALYTICS,
  'petametrics.com': ANALYTICS,
  'photobucket.com': PERFORMANCE,
  'photorank.me': PERFORMANCE,
  'picadmedia.com': ADVERTISING,
  'piclike.us': ADVERTISING,
  'picreel.com': ANALYTICS,
  'pictela.net': ANALYTICS,
  'pingdom.net': ANALYTICS,
  'pinterest.com': SOCIAL_NETWORKING,
  'pisces-penton.com': ESSENTIAL,
  'pistonheads.com': SOCIAL_NETWORKING,
  'pixlee.com': SOCIAL_NETWORKING,
  'pixnet.net': SOCIAL_NETWORKING,
  'pki.goog': ESSENTIAL,
  'placed.com': ANALYTICS,
  'placehold.it': ESSENTIAL,
  'planning-inc.co.uk': ANALYTICS,
  'playbuzz.com': PERFORMANCE,
  'player.vimeo.com': PERFORMANCE,
  'playground.xyz': ADVERTISING,
  'pleenq.com': ADVERTISING,
  'plentific.com': PERFORMANCE,
  'plista.com': ADVERTISING,
  'plus.google.com': SOCIAL_NETWORKING,
  'pmc.com': PERFORMANCE,
  'po.st': ESSENTIAL,
  'pointp.in': ESSENTIAL,
  'pointroll.com': ADVERTISING,
  'polaracademy.me': ADVERTISING,
  'poll.fm': ADVERTISING,
  'polldaddy.com': ANALYTICS,
  'popads.net': ADVERTISING,
  'populisengage.com': ADVERTISING,
  'postcodeanywhere.co.uk': ESSENTIAL,
  'postimg.org': PERFORMANCE,
  'postrelease.com': ADVERTISING,
  'poweredbyeden.com': ADVERTISING,
  'poweringnews.com': SOCIAL_NETWORKING,
  'powerlinks.com': ADVERTISING,
  'powerreviews.com': ANALYTICS,
  'powr.io': ESSENTIAL,
  'ppjol.net': ADVERTISING,
  'prebid.org': ESSENTIAL,
  'prefixbox.com': PERFORMANCE,
  'premiumtv.co.uk': PERFORMANCE,
  'pressarea.com': ESSENTIAL,
  'pressjack.com': ESSENTIAL,
  'pretio.in': ADVERTISING,
  'prezi.com': ESSENTIAL,
  'pricegrabber.com': PERFORMANCE,
  'pricerunner.com': PERFORMANCE,
  'primefuse.com': PERFORMANCE,
  'printfriendly.com': ESSENTIAL,
  'privymktg.com': ADVERTISING,
  'pro-market.net': ADVERTISING,
  'profitshare.ro': ADVERTISING,
  'programattik.com': ADVERTISING,
  'projects.fm': PERFORMANCE,
  'projectwatchtower.com': ADVERTISING,
  'proper.io': PERFORMANCE,
  'propertyweek.com': PERFORMANCE,
  'proposalpath.com': ESSENTIAL,
  'prospecteye.com': ADVERTISING,
  'provenpixel.com': ADVERTISING,
  'providesupport.com': PERFORMANCE,
  'proweb.net': PERFORMANCE,
  'proximic.com': ADVERTISING,
  'psplugin.com': PERFORMANCE,
  'pswec.com': ANALYTICS,
  'psyma.com': ADVERTISING,
  'pubfactory.com': PERFORMANCE,
  'public-trust.com': ESSENTIAL,
  'publicidad.tv': ADVERTISING,
  'publisherdesk.com': ADVERTISING,
  'publishme.se': PERFORMANCE,
  'publishthis.com': ADVERTISING,
  'pubmatic.com': ADVERTISING,
  'pubmine.com': ADVERTISING,
  'pubnation.com': ADVERTISING,
  'pulseinsights.com': ANALYTICS,
  'pulsemgr.com': ADVERTISING,
  'purch.com': ADVERTISING,
  'purecars.com': ADVERTISING,
  'purechat.com': PERFORMANCE,
  'pushcrew.com': ADVERTISING,
  'pusherapp.com': ESSENTIAL,
  'pxi.pub': ESSENTIAL,
  'pxlad.io': PERFORMANCE,
  'q1mediahydraplatform.com': ADVERTISING,
  'qeryz.com': ANALYTICS,
  'qmerce.com': ANALYTICS,
  'qmodal.azurewebsites.net': ANALYTICS,
  'qodeinteractive.com': PERFORMANCE,
  'qrius.me': SOCIAL_NETWORKING,
  'qservz.com': ADVERTISING,
  'qsstats.com': ADVERTISING,
  'qualaroo.com': ANALYTICS,
  'qualtrics.com': ANALYTICS,
  'quantummetric.com': ANALYTICS,
  'quartalflife.com': PERFORMANCE,
  'qubitproducts.com': ANALYTICS,
  'questionmarket.com': ADVERTISING,
  'questionpro.com': ANALYTICS,
  'quora.com': ADVERTISING,
  'quovadisglobal.com': ESSENTIAL,
  'qzzr.com': ANALYTICS,
  'r1-cdn.net': ADVERTISING,
  'raac33.net': ANALYTICS,
  'rackcdn.com': ESSENTIAL,
  'rambler.ru': ESSENTIAL,
  'rampanel.com': ANALYTICS,
  'rapidapi.com': ESSENTIAL,
  'rapidssl.com': ESSENTIAL,
  'ravelin.com': ESSENTIAL,
  'rawgit.com': ESSENTIAL,
  'raygun.io': ESSENTIAL,
  'rbl.ms': ADVERTISING,
  'rcsadv.it': ADVERTISING,
  'rdcdn.com': ADVERTISING,
  'reachforce.com': ADVERTISING,
  'reachmee.com': PERFORMANCE,
  'reactful.com': ANALYTICS,
  'reactx.com': ADVERTISING,
  'realtime.co': ESSENTIAL,
  'realytics.net': ANALYTICS,
  'recaptcha.net': ESSENTIAL,
  'receiptful.com': ESSENTIAL,
  'recobell.io': ANALYTICS,
  'recollect.net': ESSENTIAL,
  'recommend.pro': ANALYTICS,
  'recruitmentplatform.com': PERFORMANCE,
  'redditstatic.com': SOCIAL_NETWORKING,
  'redintelligence.net': ADVERTISING,
  'redirecting2.net': ADVERTISING,
  'reevoo.com': ANALYTICS,
  'refersion.com': ADVERTISING,
  'refinedads.com': ADVERTISING,
  'reflow.tv': ADVERTISING,
  'relestar.com': ADVERTISING,
  'remarketingpixel.com': ADVERTISING,
  'remintrex.com': ADVERTISING,
  'republer.com': ADVERTISING,
  'res-x.com': ANALYTICS,
  'research-int.se': ANALYTICS,
  'researchnow.com': ANALYTICS,
  'researchonline.org.uk': PERFORMANCE,
  'reson8.com': ANALYTICS,
  'resources.fairfax.com.au': PERFORMANCE,
  'responsetap.com': ANALYTICS,
  'responsys.net': ADVERTISING,
  'resrc.it': ESSENTIAL,
  'retargeter.com': ADVERTISING,
  'revcontent.com': PERFORMANCE,
  'revee.com': ADVERTISING,
  'revenueconduit.com': ESSENTIAL,
  'revenuemantra.com': ADVERTISING,
  'reviews.co.uk': ANALYTICS,
  'reviews.io': ANALYTICS,
  'revivve.com': ADVERTISING,
  'revjet.com': ADVERTISING,
  'revolvermaps.com': ANALYTICS,
  'revsci.net': ADVERTISING,
  'revv.co': ESSENTIAL,
  'rewardstyle.com': ADVERTISING,
  'rezync.com': ADVERTISING,
  'richrelevance.com': ANALYTICS,
  'riskified.com': ESSENTIAL,
  'rkdms.com': ADVERTISING,
  'rlcdn.com': ANALYTICS,
  'rmtag.com': ADVERTISING,
  'rnengage.com': PERFORMANCE,
  'rnkr-static.com': PERFORMANCE,
  'romancart.com': ESSENTIAL,
  'rondavu.com': ADVERTISING,
  'roomkey.com': PERFORMANCE,
  'rovicorp.com': ANALYTICS,
  'rss2json.com': ESSENTIAL,
  'rssinclude.com': SOCIAL_NETWORKING,
  'rtb-media.me': ADVERTISING,
  'rtbidder.net': ADVERTISING,
  'rtm.com': ADVERTISING,
  'rtrk.co.nz': ADVERTISING,
  'rubikloud.com': ANALYTICS,
  'ruleranalytics.com': ANALYTICS,
  'rummblelabs.com': ANALYTICS,
  'rundsp.com': ADVERTISING,
  'rutarget.ru': ADVERTISING,
  'rxthdr.com': ADVERTISING,
  's-9.us': PERFORMANCE,
  's2Betrieb.de': ADVERTISING,
  'sabio.co.uk': PERFORMANCE,
  'sagepay.com': ESSENTIAL,
  'sail-track.com': ANALYTICS,
  'sailthru.com': ADVERTISING,
  'sajari.com': ESSENTIAL,
  'salesforce.com': ADVERTISING,
  'salesforceliveagent.com': PERFORMANCE,
  'salesloft.com': ANALYTICS,
  'sam4m.com': ADVERTISING,
  'samba.tv': PERFORMANCE,
  'samplicio.us': ANALYTICS,
  'sara.media': PERFORMANCE,
  'sas.com': ADVERTISING,
  'saymedia.com': ADVERTISING,
  'sc-static.net': ANALYTICS,
  'scanalert.com': ESSENTIAL,
  'scanscout.com': ADVERTISING,
  'scarabresearch.com': ANALYTICS,
  'scdn.co': PERFORMANCE,
  'scorecardresearch.com': ADVERTISING,
  'scoutanalytics.net': ADVERTISING,
  'scribblelive.com': ADVERTISING,
  'scroll.com': ESSENTIAL,
  'scrvt.com': PERFORMANCE,
  'scupio.com': ADVERTISING,
  'searchanise.com': ANALYTICS,
  'searchforce.net': ADVERTISING,
  'searchignite.com': ADVERTISING,
  'searchmarketing.com': ADVERTISING,
  'searchspring.net': ESSENTIAL,
  'secomapp.com': ESSENTIAL,
  'sectigo.com': ESSENTIAL,
  'securedvisit.com': ADVERTISING,
  'securestudies.com': ANALYTICS,
  'securitymetrics.com': ESSENTIAL,
  'seewhy.com': ADVERTISING,
  'segmanta.com': ADVERTISING,
  'segment.io': ANALYTICS,
  'segmentstream.com': ANALYTICS,
  'segmint.net': ANALYTICS,
  'sekindo.com': PERFORMANCE,
  'selectmedia.asia': PERFORMANCE,
  'sellpoints.com': ANALYTICS,
  'semasio.net': ANALYTICS,
  'sentifi.com': SOCIAL_NETWORKING,
  'sentry-cdn.com': ESSENTIAL,
  'servebom.com': PERFORMANCE,
  'serverbid.com': ADVERTISING,
  'servicetick.com': ANALYTICS,
  'serving-sys.com': ADVERTISING,
  'servmetric.com': ANALYTICS,
  'sesamestats.com': ANALYTICS,
  'sevenrooms.com': ESSENTIAL,
  'sharebutton.co': SOCIAL_NETWORKING,
  'sharethis.com': SOCIAL_NETWORKING,
  'sharethrough.com': ADVERTISING,
  'shld.net': PERFORMANCE,
  'shop.pe': SOCIAL_NETWORKING,
  'shop2market.com': ADVERTISING,
  'shopapps.in': SOCIAL_NETWORKING,
  'shopatron.com': PERFORMANCE,
  'shopgate.com': ESSENTIAL,
  'shopifycdn.com': ESSENTIAL,
  'shopifysvc.com': PERFORMANCE,
  'shopimind.com': ADVERTISING,
  'shopkeepertools.com': ESSENTIAL,
  'shopmania.com': PERFORMANCE,
  'shopstorm.com': ESSENTIAL,
  'sidereel.com': ANALYTICS,
  'siftscience.com': ESSENTIAL,
  'signifyd.com': ESSENTIAL,
  'silktide.com': PERFORMANCE,
  'simplaex.net': ADVERTISING,
  'simplestream.com': PERFORMANCE,
  'simpli.fi': ADVERTISING,
  'simplytechnology.net': ADVERTISING,
  'singleplatform.com': ESSENTIAL,
  'site24x7rum.com': ANALYTICS,
  'siteblindado.com': ESSENTIAL,
  'siteimproveanalytics.com': ESSENTIAL,
  'sitemeter.com': ANALYTICS,
  'sitescout.com': ADVERTISING,
  'sitetagger.co.uk': ESSENTIAL,
  'skimlinks.com': ADVERTISING,
  'skimresources.net': ADVERTISING,
  'skyglue.com': ANALYTICS,
  'skyscanner.net': PERFORMANCE,
  'sli-spark.com': ESSENTIAL,
  'slideshare.com': PERFORMANCE,
  'slidesharecdn.com': SOCIAL_NETWORKING,
  'slpht.com': SOCIAL_NETWORKING,
  'smaato.net': ADVERTISING,
  'smartadserver.com': ADVERTISING,
  'smartassistant.com': PERFORMANCE,
  'smartclip.net': ADVERTISING,
  'smarterclick.co.uk': ADVERTISING,
  'smarterremarketer.net': ANALYTICS,
  'smartertrack.com': PERFORMANCE,
  'smartlook.com': ANALYTICS,
  'smartstream.tv': ADVERTISING,
  'smartsuppcdn.com': PERFORMANCE,
  'smartwebportal.co.uk': PERFORMANCE,
  'smartzer.com': ADVERTISING,
  'smartzonessva.com': ADVERTISING,
  'smh.com.au': PERFORMANCE,
  'snack-media.com': PERFORMANCE,
  'snap.licdn.com': ESSENTIAL,
  'snapengage.com': PERFORMANCE,
  'snapwidget.com': PERFORMANCE,
  'snidigital.com': ADVERTISING,
  'sniperlog.ru': ADVERTISING,
  'socdm.com': ADVERTISING,
  'sociablelabs.com': ADVERTISING,
  'socialannex.com': PERFORMANCE,
  'socialshopwave.com': SOCIAL_NETWORKING,
  'sociomantic.com': ADVERTISING,
  'sodahead.com': ANALYTICS,
  'softwebzone.com': PERFORMANCE,
  'sojern.com': ADVERTISING,
  'sokrati.com': ADVERTISING,
  'solocpm.com': ADVERTISING,
  'sonobi.com': ADVERTISING,
  'sooqr.com': ESSENTIAL,
  'sophus3.com': ANALYTICS,
  'sorensonmedia.com': PERFORMANCE,
  'soticservers.net': PERFORMANCE,
  'soundcloud.com': PERFORMANCE,
  'soundest.net': ADVERTISING,
  'sparkflow.net': ADVERTISING,
  'specificclick.net': ADVERTISING,
  'specless.tech': ADVERTISING,
  'speedcurve.com': ANALYTICS,
  'speedshiftmedia.com': ADVERTISING,
  'spiceworks.com': ANALYTICS,
  'spongecell.com': ADVERTISING,
  'spotify.com': PERFORMANCE,
  'spotim.market': SOCIAL_NETWORKING,
  'spotx.tv': ADVERTISING,
  'springserve.com': ADVERTISING,
  'squarespace-cdn.com': ESSENTIAL,
  'squarespace.com': PERFORMANCE,
  'squixa.net': ESSENTIAL,
  'srip.net': ESSENTIAL,
  'srtk.net': ADVERTISING,
  'ss2.us': ESSENTIAL,
  'sspicy.ru': ADVERTISING,
  'sstatic.net': SOCIAL_NETWORKING,
  'stackadapt.com': ADVERTISING,
  'stackla.com': SOCIAL_NETWORKING,
  'stackpile.io': ESSENTIAL,
  'stailamedia.com': ADVERTISING,
  'stamped.io': ANALYTICS,
  'statcounter.com': ANALYTICS,
  'statful.com': ANALYTICS,
  'static-assets.clock-software.com': ESSENTIAL,
  'static-cdn.website-editor.net': ESSENTIAL,
  'static.cdn-website.com': ESSENTIAL,
  'static.parastorage.com': ESSENTIAL,
  'static.showit.co': ESSENTIAL,
  'static.wixstatic.com': ESSENTIAL,
  'static1.squarespace.com': ESSENTIAL,
  'staticflickr.com': PERFORMANCE,
  'stats.wp.com': ANALYTICS,
  'statuspage.io': ESSENTIAL,
  'steelhousemedia.com': ADVERTISING,
  'steepto.com': ADVERTISING,
  'stellaservice.com': ANALYTICS,
  'stickyadstv.com': ADVERTISING,
  'storify.com': SOCIAL_NETWORKING,
  'stormcontainertag.com': ESSENTIAL,
  'stormiq.com': ANALYTICS,
  'storygize.net': ADVERTISING,
  'strands.com': ESSENTIAL,
  'stratus.sc': PERFORMANCE,
  'streamrail.net': ADVERTISING,
  'strikead.com': ADVERTISING,
  'stripe.com': ESSENTIAL,
  'stripe.network': ESSENTIAL,
  'stroeerdigitalmedia.de': ADVERTISING,
  'struq.com': ADVERTISING,
  'stumbleupon.com': PERFORMANCE,
  'sub2tech.com': ANALYTICS,
  'sublimevideo.net': PERFORMANCE,
  'successfultogether.co.uk': ADVERTISING,
  'sumo.b-cdn.net': ADVERTISING,
  'sumologic.com': ESSENTIAL,
  'sunriseintegration.com': ESSENTIAL,
  'survata.com': ANALYTICS,
  'surveygizmo.eu': ANALYTICS,
  'surveymonkey.com': ANALYTICS,
  'survicate.com': ANALYTICS,
  'sweettooth.io': ADVERTISING,
  'swiftypecdn.com': ESSENTIAL,
  'switchadhub.com': ADVERTISING,
  'switchads.com': ADVERTISING,
  'swoop.com': ADVERTISING,
  'symcd.com': ESSENTIAL,
  'syndication.twitter.com': SOCIAL_NETWORKING,
  'synergetic.ag': ADVERTISING,
  'synthetix.com': PERFORMANCE,
  't.co': SOCIAL_NETWORKING,
  't4ft.de': ADVERTISING,
  'taboolasyndication.com': ADVERTISING,
  'tag4arm.com': ANALYTICS,
  'tagboard.com': SOCIAL_NETWORKING,
  'tagcommander.com': ESSENTIAL,
  'taggstar.com': ADVERTISING,
  'tagmanager.coremetrics.com': ESSENTIAL,
  'tagsrvcs.com': ESSENTIAL,
  'tailtarget.com': ADVERTISING,
  'taleo.net': PERFORMANCE,
  'tapad.com': ADVERTISING,
  'tapsense.com': ADVERTISING,
  'targito.com': ADVERTISING,
  'tawk.to': PERFORMANCE,
  'tcdn.com.br': ADVERTISING,
  'tctm.co': ANALYTICS,
  'teads.tv': ADVERTISING,
  'tealiumiq.com': ESSENTIAL,
  'technoratimedia.com': ADVERTISING,
  'telephonesky.com': ESSENTIAL,
  'tellaparts.com': ANALYTICS,
  'template-help.com': PERFORMANCE,
  'terminus.services': ADVERTISING,
  'thawte.com': ESSENTIAL,
  'thcdn.com': PERFORMANCE,
  'theadex.com': ADVERTISING,
  'thebestday.com': SOCIAL_NETWORKING,
  'thebrighttag.com': ESSENTIAL,
  'thefilter.com': ANALYTICS,
  'thefind.com': PERFORMANCE,
  'thelocalpeople.co.uk': PERFORMANCE,
  'themessagecloud.com': ANALYTICS,
  'thinglink.com': ESSENTIAL,
  'thirdpresence.com': ADVERTISING,
  'tidaltv.com': ADVERTISING,
  'tidiochat.com': PERFORMANCE,
  'tildacdn.com': PERFORMANCE,
  'time2perf.com': ADVERTISING,
  'timeinc.net': PERFORMANCE,
  'tinypass.com': ADVERTISING,
  'tinyurl.com': ESSENTIAL,
  'tmcs.net': PERFORMANCE,
  'tmvtp.com': ANALYTICS,
  'tns-counter.ru': ANALYTICS,
  'tomandco.uk': PERFORMANCE,
  'toptable.co.uk': PERFORMANCE,
  'touchcommerce.com': PERFORMANCE,
  'toutapp.com': ADVERTISING,
  'track-mv.com': PERFORMANCE,
  'trackalyzer.com': ANALYTICS,
  'trackcmp.net': ADVERTISING,
  'trackdiscovery.net': ADVERTISING,
  'trackedweb.net': ANALYTICS,
  'tradedoubler.com': ADVERTISING,
  'tradelab.fr': ADVERTISING,
  'trafficfactory.biz': ADVERTISING,
  'traffichunt.com': ADVERTISING,
  'trafficjunky.net': ADVERTISING,
  'transifex.com': ESSENTIAL,
  'travelex.co.uk': ESSENTIAL,
  'travelocity.ca': PERFORMANCE,
  'travelocity.com': PERFORMANCE,
  'travelzoo.com': PERFORMANCE,
  'treasuredata.com': ANALYTICS,
  'trialfire.com': ANALYTICS,
  'tribalfusion.com': ADVERTISING,
  'tribl.io': ADVERTISING,
  'triggeredmessaging.com': ADVERTISING,
  'triggit.com': ADVERTISING,
  'tripadvisor.co.uk': PERFORMANCE,
  'trovus.co.uk': ANALYTICS,
  'tru.am': SOCIAL_NETWORKING,
  'truconversion.com': ANALYTICS,
  'truedash.com': ANALYTICS,
  'truefitcorp.com': ANALYTICS,
  'truoptik.com': ADVERTISING,
  'trust-guard.com': ESSENTIAL,
  'trustarc.com': ESSENTIAL,
  'truste.com': ESSENTIAL,
  'trustedshops.com': ESSENTIAL,
  'trustev.com': ESSENTIAL,
  'trustpilot.com': ANALYTICS,
  'trustwave.com': ESSENTIAL,
  'trustx.org': ADVERTISING,
  'trychameleon.com': ADVERTISING,
  'tryzens-analytics.com': ANALYTICS,
  'tsyndicate.com': ADVERTISING,
  'ttgtmedia.com': PERFORMANCE,
  'tubemogul.com': ADVERTISING,
  'tumblr.com': SOCIAL_NETWORKING,
  'turbobytes.net': ESSENTIAL,
  'turn.com': ADVERTISING,
  'turnto.com': ESSENTIAL,
  'tutorialize.me': PERFORMANCE,
  'tvgenius.net': PERFORMANCE,
  'tvsquared.com': ADVERTISING,
  'tweetmeme.com': ANALYTICS,
  'twenga.co.uk': PERFORMANCE,
  'twentythree.net': PERFORMANCE,
  'twitch.tv': PERFORMANCE,
  'twitframe.com': ESSENTIAL,
  'twitter.com': SOCIAL_NETWORKING,
  'twyn.com': ADVERTISING,
  'tynt.com': ADVERTISING,
  'typeform.com': PERFORMANCE,
  'typekit.net': ESSENTIAL,
  'typepad.com': PERFORMANCE,
  'typography.com': ESSENTIAL,
  'u5e.com': ANALYTICS,
  'uadx.com': ADVERTISING,
  'ubertags.com': ESSENTIAL,
  'uciservice.com': PERFORMANCE,
  'ucs.su': ESSENTIAL,
  'udmserve.net': ADVERTISING,
  'ui-portal.de': ADVERTISING,
  'uicdn.com': PERFORMANCE,
  'uimserv.net': PERFORMANCE,
  'uk-plc.net': PERFORMANCE,
  'ulogix.ru': ADVERTISING,
  'umbel.com': ANALYTICS,
  'unanimis.co.uk': ADVERTISING,
  'undertone.com': ADVERTISING,
  'unidays.world': ADVERTISING,
  'uniqodo.com': ADVERTISING,
  'univide.com': ADVERTISING,
  'unknowntray.com': ADVERTISING,
  'unrulymedia.com': ADVERTISING,
  'up-value.de': ADVERTISING,
  'upbuild.io': ADVERTISING,
  'uploads-ssl.webflow.com': ESSENTIAL,
  'uplynk.com': PERFORMANCE,
  'upsellit.com': ANALYTICS,
  'usabilitytools.com': ANALYTICS,
  'usablenet.net': ESSENTIAL,
  'useitbetter.com': ANALYTICS,
  'userneeds.dk': ANALYTICS,
  'userreplay.net': ANALYTICS,
  'userreport.com': ANALYTICS,
  'usersnap.com': PERFORMANCE,
  'userzoom.com': ANALYTICS,
  'v12finance.com': ESSENTIAL,
  'v12group.com': ANALYTICS,
  'vdna-assets.com': ADVERTISING,
  'vdopia.com': ADVERTISING,
  'vdoserv.com': ADVERTISING,
  've.com': ADVERTISING,
  'vee24.com': PERFORMANCE,
  'veeseo.com': PERFORMANCE,
  'vehicle-images.dealerinspire.com': ESSENTIAL,
  'vehicle-photos-published.vauto.com': ESSENTIAL,
  'veinteractive.com': ADVERTISING,
  'venatusmedia.com': ADVERTISING,
  'veoxa.com': ADVERTISING,
  'verestads.net': PERFORMANCE,
  'vergic.com': PERFORMANCE,
  'verisign.com': ESSENTIAL,
  'vertamedia.com': ADVERTISING,
  'veruta.com': ANALYTICS,
  'viamichelin.com': PERFORMANCE,
  'videohub.tv': ADVERTISING,
  'videoplayerhub.com': ADVERTISING,
  'videostat.com': ADVERTISING,
  'vidible.tv': ADVERTISING,
  'vidpulse.com': ANALYTICS,
  'vidyard.com': ESSENTIAL,
  'viewwonder.com': ADVERTISING,
  'viglink.com': ADVERTISING,
  'vimeo.com': ANALYTICS,
  'vimeocdn.com': PERFORMANCE,
  'vindicosuite.com': ADVERTISING,
  'vioapi.com': PERFORMANCE,
  'viralninjas.com': ADVERTISING,
  'virool.com': ADVERTISING,
  'virtualearth.net': ESSENTIAL,
  'visiblemeasures.com': ADVERTISING,
  'visualdna.com': ADVERTISING,
  'visualrevenue.com': ADVERTISING,
  'visualstudio.com': ESSENTIAL,
  'visualwebsiteoptimizer.com': ANALYTICS,
  'vivocha.com': PERFORMANCE,
  'vizu.com': ANALYTICS,
  'vizury.com': ADVERTISING,
  'vizzit.se': ANALYTICS,
  'vk.com': SOCIAL_NETWORKING,
  'vmmpxl.com': ADVERTISING,
  'vmweb.net': ADVERTISING,
  'voicefive.com': ANALYTICS,
  'volvelle.tech': ADVERTISING,
  'vouchedfor.co.uk': ANALYTICS,
  'voxmedia.com': PERFORMANCE,
  'vwo.com': ANALYTICS,
  'w55c.net': ADVERTISING,
  'walkme.com': PERFORMANCE,
  'warpcache.net': ESSENTIAL,
  'wayfair.com': ANALYTICS,
  'wcfbc.net': ANALYTICS,
  'wdfl.co': ANALYTICS,
  'web-call-analytics.com': ANALYTICS,
  'webabacus.com': ANALYTICS,
  'webcollage.net': PERFORMANCE,
  'webdissector.com': ANALYTICS,
  'webelapp.com': ADVERTISING,
  'webforensics.co.uk': ANALYTICS,
  'webink.com': ESSENTIAL,
  'webkul.com': ESSENTIAL,
  'webmarked.net': ESSENTIAL,
  'weborama.fr': ADVERTISING,
  'webphone.net': PERFORMANCE,
  'webselect.net': PERFORMANCE,
  'websitealive7.com': PERFORMANCE,
  'websiteprotection.com': ESSENTIAL,
  'websitetestlink.com': PERFORMANCE,
  'webspectator.com': ADVERTISING,
  'websta.me': ANALYTICS,
  'webthinking.co.uk': PERFORMANCE,
  'webtuna.com': ANALYTICS,
  'webtype.com': ESSENTIAL,
  'webvideocore.net': PERFORMANCE,
  'where.com': ADVERTISING,
  'whitespacers.com': ADVERTISING,
  'whoson.com': PERFORMANCE,
  'wickedreports.com': ADVERTISING,
  'widearea.co.uk': PERFORMANCE,
  'widespace.com': ADVERTISING,
  'widget.intercom.io': PERFORMANCE,
  'widgets.wp.com': PERFORMANCE,
  'willyfogg.com': PERFORMANCE,
  'windows.net': ADVERTISING,
  'windowsupdate.com': ESSENTIAL,
  'wisepops.com': ESSENTIAL,
  'wishabi.net': ANALYTICS,
  'wishpond.net': ADVERTISING,
  'wistia.net': PERFORMANCE,
  'withcubed.com': ADVERTISING,
  'wixapps.net': PERFORMANCE,
  'wmps.com': ADVERTISING,
  'wnmedia.co.uk': PERFORMANCE,
  'woopra.com': ANALYTICS,
  'woosmap.com': ESSENTIAL,
  'workcast.net': PERFORMANCE,
  'worldpay.com': ESSENTIAL,
  'wowanalytics.co.uk': ANALYTICS,
  'wowcher.co.uk': ADVERTISING,
  'wp.pl': ADVERTISING,
  'wpengine.com': PERFORMANCE,
  'wrbm.com': PERFORMANCE,
  'wsimg.com': ESSENTIAL,
  'wsj.net': PERFORMANCE,
  'wsmcdn.audioeye.com': ESSENTIAL,
  'wt-eu02.net': ANALYTICS,
  'wtp101.com': ADVERTISING,
  'wufoo.com': ESSENTIAL,
  'wurfl.io': ANALYTICS,
  'www.gstatic.com': ESSENTIAL,
  'www.sc.pagesA.net': ANALYTICS,
  'wywyuserservice.com': ADVERTISING,
  'wzrkt.com': ANALYTICS,
  'xboxlive.com': ADVERTISING,
  'xclusive.ly': ANALYTICS,
  'xg4ken.com': ADVERTISING,
  'xlcdn.com': PERFORMANCE,
  'xmlshop.biz': PERFORMANCE,
  'xplosion.de': ADVERTISING,
  'xsellapp.com': ANALYTICS,
  'y-track.com': ANALYTICS,
  'yabidos.com': ESSENTIAL,
  'yadro.ru': ANALYTICS,
  'yahoo.com': ADVERTISING,
  'yahoo.net': ADVERTISING,
  'yashi.com': ADVERTISING,
  'yastatic.net': ESSENTIAL,
  'yelp.com': PERFORMANCE,
  'yfrog.com': PERFORMANCE,
  'yieldlab.net': ADVERTISING,
  'yieldmo.com': ADVERTISING,
  'yieldoptimizer.com': ADVERTISING,
  'yieldpartners.com': ADVERTISING,
  'yldbt.com': ADVERTISING,
  'yopify.com': ESSENTIAL,
  'yotpo.com': ADVERTISING,
  'yottaa.net': PERFORMANCE,
  'youplay.se': ADVERTISING,
  'youramigo.com': ESSENTIAL,
  'youtube-nocookie.com': PERFORMANCE,
  'youtube.com': PERFORMANCE,
  'ytimg.com': PERFORMANCE,
  'yudu.com': PERFORMANCE,
  'yumenetworks.com': ADVERTISING,
  'ywxi.net': PERFORMANCE,
  'zafu.com': ANALYTICS,
  'zaius.com': ADVERTISING,
  'zampda.net': ADVERTISING,
  'zanox.ws': ADVERTISING,
  'zapper.com': ESSENTIAL,
  'zarget.com': ANALYTICS,
  'zdbb.net': ADVERTISING,
  'zedo.com': ADVERTISING,
  'zemanta.com': ADVERTISING,
  'zenaps.com': ADVERTISING,
  'zencdn.net': PERFORMANCE,
  'zenoviaexchange.com': ADVERTISING,
  'zergnet.com': PERFORMANCE,
  'zerogrey.com': PERFORMANCE,
  'zmags.com': ADVERTISING,
  'zn3vgszfh.fastestcdn.net': ANALYTICS,
  'zoover.co.uk': ANALYTICS,
  'zopim.com': PERFORMANCE,
  'zopim.io': PERFORMANCE,
  'zorosrv.com': ADVERTISING,
  'zqtk.net': ANALYTICS,
  'ztat.net': PERFORMANCE,
}
