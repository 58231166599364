import Categories, {
  ESSENTIAL,
} from 'constants/categories'

import * as LocalStorage from './localStorage'

const TERMLY_COOKIE_CONSENT = 'TERMLY_COOKIE_CONSENT'


/**
 * @name CookieConsents
 */
export default {
  /**
   * Returns true if `otherConsents` are equal to the stored consents.
   *
   * @param {Object} consents
   *
   * @returns {Boolean}
   */
  equals(consents) {
    const currentConsents = this.getConsentSettings() || {}

    return Categories.every((category) => {
      return ( consents[category] === currentConsents[category] )
    })
  },

  /**
   * Gets the consent object from the store that is younger than `ttl`. If `ttl` is
   * omitted or is 0, the object is returned regardless of its age.
   *
   * @param {Number} [ttl] - time to live in seconds
   *
   * @returns {Object, null} the object will be of the shape {category: <boolean>}
   */
  getConsents(ttl) {
    return LocalStorage.getItem(TERMLY_COOKIE_CONSENT, ttl)
  },

  /**
   * Similar to `getConsents()`, but returns the `createdAt` timestamp alongside the
   * `value` that contains the consents object.
   *
   * @param {Number} [ttl] - time to live in seconds
   *
   * @returns { {createdAt, consents}, null} the `value` object will be of the shape {category: <boolean>}
   */
  getConsentsWithTimestamp(ttl) {
    const item = LocalStorage.getItem(TERMLY_COOKIE_CONSENT, ttl, {
      includeCreatedAt: true,
    })

    return {
      createdAt: item?.createdAt,
      consents: item?.value,
    }
  },

  /**
   * Stores the supplied object as a mapping of categories to their respective
   * boolean consent values (e.g.: { advertising: true, social_engineering: false })
   *
   * @param {Object} value
   */
  setConsents(value) {
    LocalStorage.setItem(TERMLY_COOKIE_CONSENT, value)
  },

  /**
   * Returns true if there is an extant consent (i.e. set sometime in the past)
   *
   * @returns {Boolean}
   */
  hasConsents() {
    return Boolean(this.getConsents())
  },

  /**
   * @param {String, String[]} categories
   *
   * @returns {Boolean}
   */
  isAnyConsented(...categories) {
    const consentedCategories = this.getConsentedCategories()

    // We don't want to short-circuit if there are no explicitly-
    // consented categories, because if we do, we will return the
    // wrong result if `categories` contains ESSENTIAL

    return categories.flat().some((category) => {
      if ( category === ESSENTIAL ) {
        return true
      }

      return consentedCategories.has(category)
    })
  },

  /**
   * @param {String, String[]} categories
   *
   * @returns {Boolean}
   */
  isAnyDeclined(...categories) {
    const consentSettings = this.getConsentSettings()

    if ( !consentSettings ) {
      return false
    }

    return categories.flat().some((category) => {
      if ( category === ESSENTIAL ) {
        return false
      }

      return !consentSettings[category]
    })
  },

  /**
   * Returns true if every category in the consent store has a true value
   *
   * @returns {Boolean}
   */
  isAllConsented(consents) {
    const consentData = consents || this.getConsents()

    if ( !consentData ) {
      return false
    }

    return Categories.every((category) => Boolean(consentData[category]))
  },

  /**
   * Returns true if every category in the consent store has a true value
   *
   * @returns {Boolean}
   */
  isAllDeclined(consents) {
    const consentData = consents || this.getConsents()

    if ( !consentData ) {
      return true
    }

    return Categories.every((category) => {
      // We can never decline an essential script, so we effectively
      // ignore it here
      if ( category === ESSENTIAL ) {
        return true
      }

      return !Boolean(consentData[category])
    })
  },

  /**
   * Returns a set of the names of all the categories to which the user
   * has granted consent, or an empty set if there are none.
   *
   * @returns {Set}
   */
  getConsentedCategories() {
    const consentData = this.getConsents()

    if ( !consentData ) {
      return new Set()
    }

    return Object.keys(consentData).reduce((acc, key) => {
      if ( Categories.has(key) && consentData[key] ) {
        return acc.add(key)
      }

      return acc
    }, new Set())
  },

  /**
   * Returns the currently-stored consent settings.
   *
   * @returns {Object} category names, each mapped to a boolean value
   */
  getConsentSettings() {
    const consentData = this.getConsents()

    if ( !consentData ) {
      return null
    }

    const entries = Object.entries(consentData)
      .filter(([key, _value]) => Categories.has(key))

    return Object.fromEntries(entries)
  },
}
