import 'utils/global'

import sendGCMConsentDefaults from 'utils/sendGCMConsentDefaults'

import Termly from 'termly-namespace'

import ClientInfo from 'constants/client-info'
import CurrentRegionSettings from 'constants/current-region-settings'
import WebsiteConfig from 'constants/website-config'

import getConfig from 'utils/getConfig'
import initialize from 'utils/initialize'
import initializeDisplayPreferencesButtons from 'utils/initializeDisplayPreferencesButtons'
import releaseResources from 'utils/releaseResources'
import { initTCF } from 'utils/tcf-gvl'

import { installAutoBlocker } from './auto-blocker'


// eslint-disable-next-line prefer-arrow-callback, no-unexpected-multiline
(function() {
  const {
    apiBase,
    isLatestScript,
    masterConsentsOrigin,
    useAutoBlock,
    websiteUUID,
  } = getConfig(document.currentScript)

  if ( !websiteUUID ) {
    throw new Error('No website UUID found')
  }

  if ( isLatestScript ) {
    CurrentRegionSettings.init(WebsiteConfig.website, ClientInfo)
  } else {
    const updateInstructionsURL = 'https://support.termly.io/en/articles/8952694-termly-cmp-embed-script-versions'
    console.warn('[Termly] Your Termly CMP script is outdated. Please see %s for update instructions.',
                 updateInstructionsURL)
  }

  // No matter what happens, we need to set consent defaults for GTM as early as
  // possible...except for people who really, really, really don't want them sent.
  if ( window.TERMLY_FORCE_DISABLE_GCM ) {
    console.debug('[Termly] Not sending GCM consent defaults '
                  + '(found window.TERMLY_FORCE_DISABLE_GCM = %o)',
                  window.TERMLY_FORCE_DISABLE_GCM)
  } else {
    sendGCMConsentDefaults()
  }

  // If isInitialized() returns undefined, it means we don't have all the config
  // we need to make this determination right now...we have to fire off a request
  // to retrieve it, which means assuming that we're enabled and then releasing
  // resources later (i.e., we're operating in legacy mode because the page has
  // requested /embed.min.js instead of the new URL).
  if ( CurrentRegionSettings.isInitialized() ) {
    if ( !CurrentRegionSettings.isWidgetEnabled() ) {
      console.debug('[Termly] ResourceBlocker has been disabled for this region')

      releaseResources()

      initializeDisplayPreferencesButtons({
        isEnabled: false,
      })

      return
    }
  }

  Termly.apiBase = apiBase

  const init = () => {
    // TODO: This really should be conditional...we only need it in the EU.
    Termly.tcf = initTCF()

    // N.B. Auto-blocking will *only* work if the page has a hard-coded
    // <script> tag to this compiled file, AND this script is the first
    // on the page, e.g.:
    //
    //   <head>
    //     <title>Some Page</title>
    //
    //     <script
    //       type="text/javascript"
    //       src="http://app.termly.io/embed.min.js"
    //     ></script>
    //
    //     <!-- All other scripts below this point -->
    //   </head>
    //
    // The old way of doing things will NOT work:
    //
    //   (function () {
    //     var s = document.createElement('script');
    //     s.type = 'text/javascript';
    //     s.src = 'http://localhost.termly.io:8007/embed.min.js';
    //     s.id = '759df629-4ff4-49f5-b2dd-8212e691ea80';
    //     s.setAttribute("data-name", "termly-embed-banner");
    //     var x = document.getElementsByTagName('script')[0];
    //     x.parentNode.insertBefore(s, x);
    //   })();
    //
    if ( useAutoBlock ) {
      console.debug('[Termly] AutoBlocker is enabled for this website')

      // We need auto-blocker to run immediately; we cannot afford to let
      // it dynamically load (i.e. import()) because if we do that, it
      // won't be able to catch every script on the page. Fortunately,
      // auto-blocker is really small, so it's not too much of a hit to
      // deliver it to those who don't use it.
      console.debug('[Termly] Installing AutoBlocker overrides')
      installAutoBlocker()
    }

    if ( masterConsentsOrigin ) {
      import('utils/initMasterConsentsOriginSync')
        .then(({ default: initMasterConsentsOriginSync }) => {
          initMasterConsentsOriginSync(masterConsentsOrigin)
        })
    }

    initialize({
      apiBase,
      websiteUUID,
    })
  }

  init()

  // In SSR pages (e.g., under NextJS), a URL change will not trigger
  // re-execution of an embedded script. So, we have to provide this
  // function to rerun the initialization of ResourceBlocker.
  window.Termly.initialize = init
})()
