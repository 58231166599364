const TERMLY_API_CACHE = 'TERMLY_API_CACHE'

const SUPPORTS_LOCAL_STORAGE = supportsLocalStorage()

/**
 * Fetches the datum keyed by `key` from the cache. If the value at `key`
 * is older than `ttl` seconds, `null` is returned instead.
 *
 * @param {String} key
 * @param {Number} [ttl] - maximum allowable age in seconds. Infinite if omitted.
 *
 * @returns {*,null}
 */
export function getItem(key, ttl, options = {}) {
  if ( !SUPPORTS_LOCAL_STORAGE ) {
    return null
  }

  const cache = window.localStorage.getItem(TERMLY_API_CACHE)
  if ( !cache ) {
    return null
  }

  const parsedCache = JSON.parse(cache)
  const item = parsedCache[key]
  if ( !item ) {
    return null
  }

  const currentTimestamp = Date.now()
  if ( !ttl || (currentTimestamp - item.createdAt < ttl * 1000) ) {
    return ( options.includeCreatedAt ) ? item : item.value
  }

  return null
}

/**
 * @param {String} key
 * @param {*} value
 */
export function setItem(key, value) {
  if ( !SUPPORTS_LOCAL_STORAGE ) {
    return
  }

  const cache = window.localStorage.getItem(TERMLY_API_CACHE)
  const parsedCache = ( cache ) ? JSON.parse(cache) : {}
  const createdAt = Date.now()

  window.localStorage.setItem(TERMLY_API_CACHE, JSON.stringify({
    ...parsedCache,
    [key]: {
      createdAt,
      value,
    },
  }))
}

export function clearAPICache() {
  if ( !SUPPORTS_LOCAL_STORAGE ) {
    return
  }

  localStorage.removeItem(TERMLY_API_CACHE)
}

function supportsLocalStorage() {
  const key = 'qoweadssdf'
  const value = 'oauhfghhg'

  // It seems that the simple act of testing whether
  // window.localStorage is defined is enough to trigger "access
  // denied" errors if the browser is configured to disallow 3p cookie
  // storage. So, we have to to be a little more thorough...let's make
  // sure that it not only exists, but that it can actually be used.
  try {
    window.localStorage.setItem(key, value)

    const retrievedValue = window.localStorage.getItem(key)
    window.localStorage.removeItem(key)

    return ( retrievedValue === value )
  } catch (error) {
    console.error('Termly cannot store your consent settings because `window.localStorage` is not defined. Is your browser set to block third-party cookies?')

    return false
  }
}
