/**
 * @param {Object} element
 *
 * @returns {Boolean} true if the element is an instance of HTMLScriptElement
 */
export function isScript(element) {
  return element instanceof HTMLScriptElement
}

/**
 * @param {Object} element
 *
 * @returns {Boolean} true if the element is an instance of HTMLIFrameElement
 */
export function isIframe(element) {
  return element instanceof HTMLIFrameElement
}
