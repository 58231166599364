export const TERMLY_STATISTIC = 'TERMLY_STATISTIC'


// TODO: guard against null/undefined
export const isCookiePolicy = (name) => name.toLowerCase().indexOf('cookie') > -1

// TODO: seriously? This is a thing?
export const setDocDict = (docDict, uuid, notify, version, showBanner) => {
  docDict[uuid] = {
    notify,
    version,
    showBanner,
  }
}

export const filterDocs = (documents) => {
  const needPreviewList = ['Terms', 'Cookie', 'Privacy']
  const previewDocs = documents && documents.filter((doc) => {
    return needPreviewList.indexOf(doc.name.split(' ')[0]) > -1 && doc.total_published_count > 0
  })

  return previewDocs
}
