import {
  createContext,
  useContext,
} from 'react'

const ConsentConfigContext = createContext({})


export default ConsentConfigContext

export function useConsentConfig() {
  return useContext(ConsentConfigContext)
}
