//
// TODO: write tests for this someday
//
export default function getWhitelist(categories, cookies) {
  const whitelist = categories
    ? Object.entries(cookies)
      .filter(([key]) => categories[key])
      .map(([key, value]) => value).reduce((acc, val) => acc.concat(val), [])
    : cookies.essential

  if ( !whitelist ) {
    return []
  }

  return whitelist.map(({ name, domain, tracker_type }) => ({
    name,
    provider: domain,
    type: tracker_type,
  }))
}
