import { lazy } from 'react'


export default function lazyLoad(importComponent) {
  return lazy(async () => {
    try {
      return await importComponent()
    } catch (error) {
      console.error('[Termly] A lazily-loaded component failed to load. This usually happens '
                    + 'when a recent update of Termly software renames or removes a file. '
                    + 'Try reloading the page. If that does not work, please contact Termly for help: '
                    + 'https://termly.io/contact-us\n\n%s',
                    error)

      return {
        __esModule: true,
        default: () => null,
      }
    }
  })
}
