const MIN_IFRAME_HEIGHT = 134
const MIN_IFRAME_WIDTH = 196


export default function isBigEnoughToReplace(referenceNode) {
  return (
    referenceNode.offsetWidth >= MIN_IFRAME_WIDTH
      && referenceNode.offsetHeight >= MIN_IFRAME_HEIGHT
  )
}
