import cleanWebsiteUUID from './cleanWebsiteUUID'


// In the early days, websiteUUID used to be configured via the script
// tag's `id` attribute. The reasons for this are not documented, but
// I'm guessing that it has something to do with the fact that Google
// Tag Manager completely strips out any and all `data-*` attributes
// when trying to use a simple, static `<script>` tag.
//
// When we introduced auto-blocker, we added `data-website-uuid` in
// order to make it consistent with `data-auto-block`, but we couldn't
// get rid of `id` because there's no way to force clients to
// update. This also meant that GTM users had to use a
// dynamically-generated script tag (i.e., using
// `document.createElement('script')`, etc.).
//
// But now, we're moving to a dynamically-generated JS bundle, which
// means that we need all of this configuration to be available to the
// backend service that creates the bundle, so we have to move to
// using a query string. That's all well and good, but we're stuck
// with this legacy garbage forever.
//
// The moral of the story is, "Think long and hard about how you're
// going to configure your app when it relies upon static markup
// that's under the control of your clients; because there's no going
// back."
//
export default function getLegacyConfig({ dataset, id }) {
  const globalConfig = window.TERMLY_CONFIG || {}

  const masterConsentsOrigin =  dataset.masterConsentsOrigin || globalConfig.masterConsentsOrigin

  const useAutoBlock = ( dataset.autoBlock === void 0 )
    ? ( globalConfig.useAutoBlock === true )
    : ( dataset.autoBlock === 'on' )

  const websiteUUID = dataset.websiteUuid || id || globalConfig.websiteUUID

  return {
    isLatestScript: false,
    masterConsentsOrigin,
    useAutoBlock,
    websiteUUID: cleanWebsiteUUID(websiteUUID),
  }
}

