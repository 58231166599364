import CookieConsents from 'utils/cookieConsents'


/**
 * @param {Object} args
 * @param {Object} args.cookieConsent
 *
 * @returns {Boolean} true if consents have changed and if any
 *   consent is being flipped from true (accept) to false (reject)
 *   *and* there's at least one element in the DOM with that category.
 */
export default function needsFullPageReload({ consentMode, cookieConsent }) {
  if ( CookieConsents.equals(cookieConsent) ) {
    return false
  }

  const filterChangesRequiringReload = makeFilterForConsentMode(consentMode)

  return Object.entries(cookieConsent)
    .filter(filterChangesRequiringReload)
    .some(([category]) => {
      // We only want to reload if there are extant DOM elements for
      // the given category, otherwise, a reload would essentially be
      // a noop.
      return document.querySelector(`[data-categories*='${ category }']`)
    })
}

function makeFilterForConsentMode(consentMode) {
  const oldConsents = CookieConsents.getConsents() || {}

  if ( consentMode === 'opt_in' ) {
    // In 'opt_in' mode, nothing is loaded by default. So, if the user
    // rejects consent, there's nothing to do. If he accepts, we need
    // only unblock the scripts in situ--a reload is unnecessary.
    //
    // The only time we need to reload is if consent was once granted
    // but is now being declined. In that case, since we can't unload
    // running code, we have to reload the entire page.
    return ([category, isConsented]) => {
      return ( !isConsented && oldConsents[category] )
    }
  }

  // In 'opt_out' mode, all scripts are loaded by default, so if the
  // user accepts, there is nothing to do. If he rejects consent, a
  // reload is required.
  return ([category, isConsented]) => {
    // Techically, all we really need here is `return !isConsented`,
    // but that would would require assuming that we're doing a
    // `CoookieConsents.equals()` check before we get here (otherwise,
    // going from "decline" to "decline" would result in a reload). I
    // don't want to make that assumption, so I'm doing the check
    // against the oldConsents objet. Paranoia is good.
    //
    // N.B.: I'm using `!== false` because in `opt_out`, an undefined
    // consent is the same as `true`. If we wanted to be really
    // verbose about this, we could say the following:
    // return ( !isConsented && (oldConsents[category] === void 0 || oldConsents[category] === true) )
    return ( !isConsented && (oldConsents[category] !== false) )
  }
}
