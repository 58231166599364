import React from 'react'
import PropTypes from 'prop-types'

import { ReactQueryClientProvider } from '@termly_web/common'

import ResourceBlockerIntlProvider from 'components/ResourceBlockerIntlProvider'

import LocalizedAppContent from './LocalizedAppContent'


export default function App({ children, consentConfig, website }) {
  return (
    <ReactQueryClientProvider>
      <ResourceBlockerIntlProvider
        supportedLocales={ consentConfig.language_setting }
      >
        <LocalizedAppContent
          consentConfig={ consentConfig }
          website={ website }
        >
          { children }
        </LocalizedAppContent>
      </ResourceBlockerIntlProvider>
    </ReactQueryClientProvider>
  )
}

App.propTypes = {
  children: PropTypes.node,
  consentConfig: LocalizedAppContent.propTypes.consentConfig,
  website: LocalizedAppContent.propTypes.website,
}
