import { CookieCategories } from '@termly_web/common'

export const {
  ADVERTISING,
  ANALYTICS,
  ESSENTIAL,
  PERFORMANCE,
  SOCIAL_NETWORKING,
  UNCLASSIFIED,
} = CookieCategories

const ALL_CATEGORIES = CookieCategories.map((category) => category.id)

export const ALLOW_ALL_CONSENT = ALL_CATEGORIES.reduce((cats, category) => ({
  ...cats,
  [category]: true,
}), {})

const CATEGORY_VALUES = new Set(ALL_CATEGORIES)


export default class Categories {
  static get values() {
    return ALL_CATEGORIES
  }

  static every(visitor) {
    return ALL_CATEGORIES.every(visitor)
  }

  static has(category) {
    return CATEGORY_VALUES.has(category)
  }
}
