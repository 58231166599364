import statisticViewApiCall from './statisticViewApiCall'


let url

export default function spaUrlMonitor() {
  window.requestAnimationFrame(() => {
    if ( url === window.location.href ) {
      return
    }

    statisticViewApiCall(['unique_user_view', 'page_view'])

    url = location.href
  })
}
