import findHostnameConfig from './findHostnameConfig'
import findPathnameConfig from './findPathnameConfig'
import findQueryStringConfig from './findQueryStringConfig'


/**
 * Configuration is hierarchical, and is structured as:
 *
 * HOSTNAMES
 *   PATHS
 *     QUERY_STRINGS
 *
 * @typedef {Object} CategoryConfig
 *
 * @example
 * {
 *   // Paths and query strings are ignored
 *   'a-host.com': 'CAT1',
 *
 *   'b-host.com': {
 *     // Equivalent to having a simple string value
 *     _default: 'CAT2',
 *   },
 *
 *   'c-host.com': {
 *     // PATHS
 *
 *     // Used when the path doesn't match any config
 *     _default: 'default category for c-host.com',
 *
 *     '/gtag/jsx': 'category for this exact path',
 *     '/gtag/jsx/': 'this is not the same as the one above',
 *
 *     '/gtag/js': {
 *       // QUERY_STRINGS
 *
 *       // Used when the query string doesn't match any of the
 *       // following configs.
 *       _default: 'category for /gtag/js*',
 *
 *       // Param value is ignored
 *       foo: 'category to use for `/gtag/js?foo`',
 *       bar: {
 *         // Param value is ignored
 *         _default: 'category to use for `/gtag/js?bar`',
 *       },
 *
 *       // To test param values, use a Map
 *       id: new Map([
 *         ['_default', 'DEFAULT_ID'],
 *         ['exactValue', 'category to use when `id=exactValue`'],
 *         [/^UA-.+?-1$/, 'category to use when `id=UA-xxxxxx-1`],
 *       ]),
 *     },
 *   },
 * },
 */

/**
 * @param {CategoryConfig} config
 *
 * @returns {Function}
 */
export default function makeGetCategoryForURL(config) {
  if ( !config ) {
    throw new Error('No config provided')
  }

  // Each function in this pipeline must take { config, url } and
  // return { config, url }. The `url` is to be considered immutable
  // and passed through, whereas the `config` that each function
  // receives is the configuration selected by the previous function
  // in the pipe.
  const pipeline = pipe(findHostnameConfig,
                        findPathnameConfig,
                        findQueryStringConfig)

  /**
   * @param {String|URL} url
   *
   * @returns {String|undefined} the category that corresponds to `url`
   */
  return function getCategoryForURL(url) {
    return pipeline({
      config,
      url: new URL(url),
    }).config
  }
}

function pipe(...functions) {
  return (result) => {
    return functions.reduce((acc, fn) => fn(acc), result)
  }
}
