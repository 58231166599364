import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'

import PropTypes from 'prop-types'

import DisplayManagerContext from './DisplayManagerContext'

const NULL_UI = {}


export default function DisplayManager({ children }) {
  const [uiStack, setUIStack] = useState([])

  const pushActiveUI = useCallback((name, props) => {
    setUIStack((stack) => stack.concat({
      name,
      props,
    }))
  }, [setUIStack])

  const mergeActiveUIProps = useCallback((props) => {
    setUIStack((stack) => {
      const activeUI = stack.pop()

      activeUI.props = {
        ...activeUI.props,
        ...props,
      }

      return [
        ...stack,
        activeUI,
      ]
    })
  }, [setUIStack])

  const popActiveUI = useCallback(() => {
    setUIStack((stack) => stack.slice(0, -1))
  }, [setUIStack])

  const closeAllUI = useCallback(() => {
    setUIStack([])
  }, [setUIStack])

  const value = useMemo(() => {
    const lastIdx = uiStack.length - 1
    const activeUI = uiStack[lastIdx] || NULL_UI

    return {
      activeUI,
      closeAllUI,
      mergeActiveUIProps,
      popActiveUI,
      pushActiveUI,
    }
  }, [closeAllUI, mergeActiveUIProps, popActiveUI, pushActiveUI, uiStack])

  return (
    <DisplayManagerContext.Provider
      value={ value }
    >
      { children }
    </DisplayManagerContext.Provider>
  )
}

DisplayManager.propTypes = {
  children: PropTypes.node,
}
