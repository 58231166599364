import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import URL from 'utils/url'


export default function useWebsiteGVL({ lang, websiteUUID }, options) {
  const url = URL.tcfGVL({
    lang,
    websiteUUID,
  })

  const cacheKey = ['website-gvl', websiteUUID]

  const {
    data = {},
    ...others
  } = useQuery(cacheKey,
               () => axios.get(url),
               options)

  return {
    ...others,
    websiteGVL: data.data,
  }
}

