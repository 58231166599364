import Termly from 'termly-namespace'

import Overrides from './overrides'

import { urlIsExempt } from './utils/exemptList'


export default function installAutoBlocker() {
  if ( !isFirstScript() ) {
    console.warn('[Termly] Termly ResourceBlocker is not the first script on the page. You might ' +
                 'not be blocking unapproved content.')
  }

  Termly.setAutoBlockerOverrides(Overrides)

  Overrides.install()
}

// ==============================================================================

function isFirstScript() {
  const script = document.currentScript
  const allScripts = [...document.getElementsByTagName('script')]

  if ( script === allScripts[0] ) {
    return true
  }

  const thisIdx = allScripts.indexOf(script)

  return allScripts.slice(0, thisIdx).every((priorScript) => {
    if ( priorScript.hasAttribute('data-termly-config') ) {
      return true
    }

    const src = priorScript.getAttribute('src')
    return ( src && urlIsExempt(src) )
  })
}
