import applyConsentStringsToNodes from './applyConsentStringsToNodes'


export default function applyConsentStringsToDOM({ acString, tcString }) {
  // We can't use querySelectorAll() here because that method returns
  // a static list of nodes; we need them to be live so we can modify
  // them in situ.
  applyConsentStringsToNodes(document.getElementsByTagName('img'), {
    acString,
    tcString,
  })
}
