import mutationObserver from './mutation-observer'

import makeCreateElement from './makeCreateElement'
import makeCreateAttribute from './makeCreateAttribute'

const ORIGINAL_CREATE_ELEMENT = Document.prototype.createElement

const {
  set: ORIGINAL_IMAGE_SRC_PROPERTY,
} = Object.getOwnPropertyDescriptor(Image.prototype, 'src')



export default {
  install() {
    installTermlyConsentEventListener()
    installMutationObserver()
    overrideCreateElement()
    overrideImageSrcAttribute()
  },

  remove() {
    removeTermlyConsentEventListener()
    removeMutationObserver()
    restoreCreateElement()
    restoreImageSrcAttribute()
  },
}

function installTermlyConsentEventListener() {
  window.addEventListener('termlyConsent', onTermlyConsent)
}

function removeTermlyConsentEventListener() {
  window.removeEventListener('termlyConsent', onTermlyConsent)
}


function installMutationObserver() {
  // ==============================================================================
  //
  // The browser affords us two opportunies to intercept the creation of elements:
  //   STEP 1. As the HTML document is being parsed for the first time, and
  //   STEP 2. When any executed scripts on the page attempt to create elements.
  //
  //
  // STEP 1: As the document is being parsed for the first time, a
  // collection of mutations will be created which we can then inspect
  // and take action about the created elements.  This makes it possible
  // to "nip in the bud" any elements which we know are from undesirable
  // sources, based upon their respective `src` attributes:
  //
  //     <script src="http://evilsite.com/evilscript.js"></script>
  //
  //     <iframe src="http://evilsite.com/evil-document.html"></iframe>
  //
  // We only want to intercept a small number of HTML tags
  // (e.g. <script>, <iframe> and <img>, though this list may not be
  // exhaustive), but there is a particular case of <script> tags that
  // we cannot block using mutation observers--those that lack a `src`
  // attribute, e.g.:
  //
  //     <script>
  //       const elem = document.createElement('script')
  //       elem.src = 'http://evilsite.com/evilscript.js'
  //       $('head').appendChild(elem)
  //     </script>
  //
  //     <script>
  //       const CRUCIAL_APP_DATA = { a: 1, b: 2, c: 3 }
  //     </script>
  //
  // In a MutationObserver, we have no way of knowing if the <script> is
  // desirable or not, so we cannot do anything about it at this
  // level. We have to fall back on STEP 2.
  //
  mutationObserver.observe(document.documentElement, {
    childList: true,
    subtree: true,
  })
}

function removeMutationObserver() {
  mutationObserver.disconnect()
}

function overrideCreateElement() {
  // This override covers attempts by JS code to create elements on
  // the fly.
  //
  // The practice of overriding functions on native prototypes is
  // evil, but unfortunately it's the sort of evil in which we must
  // dabble because on some platforms (*cough*Wix*cough*) there is a
  // rather nasty bug that causes exceptions to be raised when we
  // attempt to assign the override to
  // `window.document.createElement`, and an even more nasty
  // intentional "bug" inflicted upon us by webpack that makes the use
  // of Object.defineProperty() impossible.
  Document.prototype.createElement = makeCreateElement(ORIGINAL_CREATE_ELEMENT)
}

function restoreCreateElement() {
  Document.prototype.createElement = ORIGINAL_CREATE_ELEMENT
}

function overrideImageSrcAttribute() {
  // TODO: Interestingly enough, simply overriding Image.prototype seems
  // to be sufficient to override all images, even if they're created
  // via `document.createElement('img')`. I'm wondering if we can
  // generalize this a bit and use it within the `createElement()`
  // override, where that file executes its own
  // `Object.defineProperties()`.
  Object.defineProperties(window.Image.prototype, {
    src: makeCreateAttribute('src'),
  })
}

function restoreImageSrcAttribute() {
  Object.defineProperties(window.Image.prototype, {
    src: ORIGINAL_IMAGE_SRC_PROPERTY,
  })
}

function onTermlyConsent() {
  // This doesn't make sense to me.
  //
  // A `termlyConsent` event is fired by the dashboard whenever
  // settings are changed. When this happens, we stop listening for
  // DOM changes, stop listening for 'termlyConsent' events, and we
  // stop randomly sending cookies back to the server. Why?? What
  // if the user makes further changes?
  //
  // I sent an e-mail to Aaron Chuo on 6 October 2020 asking for
  // insight into this issue, but as of yet I've received no answer.
  //
  mutationObserver.disconnect()
  removeTermlyConsentEventListener()
}
