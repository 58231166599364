import {
  getACString,
  getTCString,
} from '../tcfStore'

import applyConsentStringsToNodes from '../applyConsentStringsToNodes'


export default function mutationObserverCallback(mutations) {
  const acString = getACString()
  const tcString = getTCString()

  mutations.forEach(({ addedNodes }) => {
    applyConsentStringsToNodes(addedNodes, {
      acString,
      tcString,
    })
  })
}
