import EventAPI from 'utils/event-api'

import { getConsentQuota } from './calculateIsOverConsentQuota'


export default function dispatchInitializedEvent({ location, regionSetting, website }) {
  EventAPI.dispatch('initialized', {
    consents_count: website.consents_count,
    consents_quota: getConsentQuota(website.provisions),
    has_cookie_report: website.has_cookie_report,
    is_over_consent_quota: website.over_consent_quota,
    location: {
      continent: location.continent_code,
      country: location.country_code2,
      state: location.state,
    },
    region_settings: regionSetting,
    website_last_modified_at: new Date(website.updated_at_timestamp * 1000).toISOString(),
  })
}
