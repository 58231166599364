import { SCRIPT_BLOCKED_TYPE } from '../../constants/elementTypes'

import setBlockerAttributes from '../setBlockerAttributes'


/**
 * Changes the 'type' of the supplied element to a non-executable type.
 *
 * @param {HTMLScriptElement} node
 */
export function scriptBlocker(node) {
  setBlockerAttributes(node)

  // Firefox solution
  //
  // TODO: Is this really doing anything for us? See
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/beforescriptexecute_event
  // It's not clear to me why the original developers thought this was necessary
  const beforeScriptExecuteHandler = (e) => {
    if (node.getAttribute('type') === SCRIPT_BLOCKED_TYPE) {
      e.preventDefault()
    }
    node.removeEventListener('beforescriptexecute', beforeScriptExecuteHandler)
  }

  node.addEventListener('beforescriptexecute', beforeScriptExecuteHandler)
}

/**
 * @param {HTMLIframeElement} node
 *
 * Moves the 'src' attribute to 'data-src' and re-inserts the element
 * into the DOM
 */
export function iframeBlocker(node) {
  setBlockerAttributes(node)

  // If the DOM is complete, the changes made above would be sufficient.
  // However, if the DOM is not complete (which is most likely going
  // to be the case when this code is invoked), then we need to take
  // the iframe and _re-insert_ it into the DOM. Then, and only then will
  // the changes take effect.
  node.parentNode.insertBefore(node, node.nextSibling)
}
