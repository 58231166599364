import { PREF_CLASS_NAME } from './constants'

import installMutationObserver from './installMutationObserver'
import createClickHandlerInstaller from './createClickHandlerInstaller'


export default function initializeDisplayPreferencesButtons({ handleClick, isEnabled }) {
  const installClickHandler = createClickHandlerInstaller({
    isEnabled,
    handleClick,
  })

  document.querySelectorAll(`.${ PREF_CLASS_NAME }`)
    .forEach((elem) => installClickHandler(elem))

  installMutationObserver({
    className: PREF_CLASS_NAME,
    install: installClickHandler,
    root: document.documentElement,
  })
}
