import {
  isIframe,
  isScript,
} from './tagUtils'


/**
 * @param {HTMLElement} element
 *
 * @returns {Function}
 */
export default function makeSetAttribute(element) {
  /**
   * @param {String} name - the name of the attribute to set
   * @param {String|Number} value - the new value of the attribute
   */
  return function setAttribute(name, value) {
    if ( isScript(element) ) {
      HTMLScriptElement.prototype.setAttribute.call(element, name, value)
      return
    }

    if ( isIframe(element) ) {
      HTMLIFrameElement.prototype.setAttribute.call(element, name, value)
      return
    }

    element[name] = value
  }
}
