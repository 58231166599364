import replaceConsentMacros from './replaceConsentMacros'

const PARAM_SUBSTRINGS_OF_INTEREST = [
  'addtl_consent=${',
  'gdpr=${',
  'gdpr_consent=${',
]


export default function applyConsentStringsToNodes(nodes, { acString, tcString }) {
  Array.from(nodes).forEach((node) => {
    if ( node.tagName !== 'IMG' ) {
      return
    }

    // We use .getAttribute() here instead of .src so that we get the
    // attribute as it is in the DOM, not after being interpreted in
    // the context of the current location.
    const src = node.getAttribute('src')

    if ( !hasMacroParams(src) ) {
      return
    }

    const newSrc = replaceConsentMacros(src, {
      acString,
      tcString,
    })

    node.setAttribute('src', newSrc)
  })
}

// We're assuming that the overwhelming majority of img tags on a given
// page will *not* have macros the need to be interpolated. So, we're
// just doing the quickest and dirtiest of tests at this level to see
// if the image URL _seems_ like it might need work done on it. It's
// no big deal if we get past this gatekeeper and replaceConsentMacros()
// winds up not doing any real work.
//
function hasMacroParams(src) {
  if ( !src ) {
    return false
  }

  return PARAM_SUBSTRINGS_OF_INTEREST.some((param) => {
    // Again, quick-and-dirty is the doctor's order, here. We don't
    // need to go all Rambo and start pulling out the RegExp.
    return src.includes(param)
  })
}
