import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import Termly from 'termly-namespace'

import GACMContext from './GACMContext'

import ACString from './lib/ac-string'
import makeACModel from './lib/makeACModel'

import useACModel from './hooks/useACModel'
import useGACMVendorsQuery from './hooks/useGACMVendorQuery'


// TODO: Consolidate with GACMConsentsProvider? See the TODO in
// that component.
//
export default function GACMProvider({ children, websiteUUID }) {
  const {
    vendors,
  } = useGACMVendorsQuery({
    websiteUUID,
  }, {
    // TODO: I'm not thrilled with the use of Termly.tcf here, but I'm
    // lacking the creative "juice" right now to come up with a better
    // solution.
    enabled: Boolean(Termly.tcf),
  })

  const vendorIDs = useMemo(() => {
    return vendors?.map(({ id }) => id)
  }, [vendors])

  const acModel = useACModel({
    vendorIDs,
  })

  const value = useMemo(() => ({
    vendors,

    acceptAll() {
      acModel.setAll()
    },

    buildACModel({ acString }) {
      return makeACModel({
        acString,
        vendorIDs,
      })
    },

    declineAll() {
      acModel.unsetAll()
    },

    getStoredACString() {
      return Termly.tcf?.getACString()
    },

    initModelFromACString(acString) {
      ACString.decode(acString, acModel)
    },

    makeACString(model = acModel) {
      return ACString.encode(model)
    },
  }), [acModel, vendors, vendorIDs])

  return (
    <GACMContext.Provider
      value={ value }
    >
      { children }
    </GACMContext.Provider>
  )
}

GACMProvider.propTypes = {
  websiteUUID: PropTypes.string.isRequired,

  children: PropTypes.node,
}
