import React, {
  Suspense,
} from 'react'

import ReactDOM from 'react-dom'

import { TERMLY_CODE_SNIPPET_SUPPORT } from 'constants/domIds'

import ConsentPromptApp from 'components/ConsentPromptApp'
import GPCHandlerApp from 'components/GPCHandlerApp'


export default function render(app) {
  ReactDOM.render(app, document.getElementById(TERMLY_CODE_SNIPPET_SUPPORT))
}

export function renderConsentPromptApp({ consentConfig, website }) {
  render(
    <Suspense
      fallback={ null }
    >
      <ConsentPromptApp
        consentConfig={ consentConfig }
        website={ website }
      />
    </Suspense>
  )
}


export function renderGPCHandlerApp({ consentConfig, website }) {
  render(
    <GPCHandlerApp
      consentConfig={ consentConfig }
      website={ website }
    />
  )
}
