import Termly from 'termly-namespace'

import CookieConsents from 'utils/cookieConsents'
import { ONE_YEAR_IN_SECS } from 'utils/time'

import isCookiePolicyVersionUpdated from './isCookiePolicyVersionUpdated'


export default function shouldRenderReminder({ cookiePolicy }) {
  if ( !cookiePolicy ) {
    return false
  }

  if ( Termly.tcf?.isConsentableContentVersionChanged ) {
    return true
  }

  return ( isCookieConsentExpired() || isCookiePolicyVersionUpdated(cookiePolicy) )
}

function isCookieConsentExpired() {
  return ( !CookieConsents.getConsents(ONE_YEAR_IN_SECS) )
}
