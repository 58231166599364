import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'
import { TCString } from '@iabtechlabtcf/core'

import Termly from 'termly-namespace'

import noop from 'utils/noop'

import {
  makeSaveConsent,
  makeTCModel,
} from 'utils/tcf-gvl'

import { useGVL } from '../GVLProvider'

import TCFContext from './TCFContext'

import GVLPresenter from './lib/GVLPresenter'


// TODO: Consolidate with TCFConsentsProvider? See the TODO in
// that component.
//
export default function TCFProvider({ children, lang }) {
  const { gvl } = useGVL()

  const value = useMemo(() => {
    if ( !gvl ) {
      console.debug('[Termly] No GVL found for this website. TCFProvider is providing noop functions.')

      return {
        acceptAll: noop,
        declineAll: noop,
        getStoredTCString: noop,
        gvl: void 0,
        makeTCString: noop,
        saveConsent: noop,
        tcModel: void 0,
      }
    }

    const saveConsent = makeSaveConsent({
      ccVersion: Termly.tcf.ccVersion,
      cmpApi: Termly.tcf.cmpApi,
    })

    // TODO: Move GVLPresenter into GVLProvider? That would allow us to
    // stop passing `lang` as a prop into TCFProvider
    const gvlPresenter = new GVLPresenter({
      lang,
      vendorList: gvl,
    })

    const tcModel = makeTCModel(gvl)

    return {
      gvl: gvlPresenter,

      saveConsent,
      tcModel,

      acceptAll() {
        tcModel.setAll()
      },

      declineAll() {
        tcModel.unsetAll()
      },

      getStoredTCString() {
        return Termly.tcf?.getTCString()
      },

      makeTCString() {
        return TCString.encode(tcModel)
      },
    }
  }, [gvl, lang])

  return (
    <TCFContext.Provider
      value={ value }
    >
      { children }
    </TCFContext.Provider>
  )
}

TCFProvider.propTypes = {
  children: PropTypes.node,
  lang: PropTypes.string,
}
