import spaUrlMonitor from './spaUrlMonitor'


export default function registerEvents() {
  document.body.addEventListener('click', () => {
    spaUrlMonitor()
  })

  document.addEventListener('keydown', (event) => {
    if ( event.key === 'Enter' ) {
      spaUrlMonitor()
    }
  })
}
