import Cookies from 'js-cookie'
import { v4 as uuid } from 'uuid'

const DEFAULT_EXPIRY = 30


export const cookieName = '_termly_new_user'
export const csrfCookieName = 'csrf_token'
export const csrfHeaderName = 'Csrf-Token'

// TODO: Remove eslint disabler and convert args to an object
// eslint-disable-next-line max-params
export function setCookie(value, name = cookieName, expires = DEFAULT_EXPIRY, path = '/') {
  return Cookies.set(name, value, {
    expires,
    path,
    Partitioned: true,
  })
}

export function getCookie(name = cookieName) {
  return Cookies.getJSON(name) || false
}

export function delCookie(name = cookieName, path = '/') {
  Cookies.remove(name, { path })
}

export function setCsrfCookie() {
  const sessionOptions = {
    expires: DEFAULT_EXPIRY,
    secure: true,
    Partitioned: true,
  }

  if ( Cookies.get('iframe_allowed') === 'true' ) {
    sessionOptions.sameSite = 'none'
  }

  Cookies.set(csrfCookieName, uuid(), sessionOptions)
}

export function getCsrfCookie() {
  return Cookies.get(csrfCookieName)
}
