import selectRegionSetting from 'utils/selectRegionSetting'


export default {
  _settings: void 0,

  init(website, clientInfo) {
    if ( !website || !clientInfo ) {
      return
    }

    this._settings = selectRegionSetting(website.region_settings, {
      continent: clientInfo.continent_code,
      country: clientInfo.country_code2,
      state: clientInfo.state,
    })
  },

  getSettings() {
    return this._settings
  },

  getGoogleConsentModeSettings() {
    return this.getSettings()?.google_consent_mode_settings
  },

  isInitialized() {
    return Boolean(this.getSettings())
  },

  isOptOut() {
    return ( this.getSettings()?.consent_mode === 'opt_out' )
  },

  isWidgetEnabled() {
    return Boolean(this.getSettings()?.enable_widget)
  },
}
