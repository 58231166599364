import Termly from 'termly-namespace'

import ConsentDefaults from 'constants/consentDefaults'

import GTM from 'utils/gtm'
import enableConsentScripts from 'utils/enableConsentScripts'


export default function releaseResources() {
  console.debug('[Termly] Releasing resources')

  // This is a little counter-intuitive, but when we disable
  // resource blocker for a region, we want it to behave as if the
  // client never installed it.  However, there may be
  // manually-blocked scripts on the page, so we want to unblock
  // those before we bomb out. This allows client sites to behave as
  // if no CMP were in place in some regions, but have the required
  // functionality in others.
  enableConsentScripts()

  // We also have to set the defaults here because if we don't, then
  // the GCM Template in GTM will not store the consents we're about
  // to send in the next step.
  Termly.setDefaultConsents(ConsentDefaults.OPT_OUT)

  // GCM *also* needs to be told to unblock stuff, but we don't want to
  // fully enable GCM for subsequent...hence the force option.
  GTM.send(ConsentDefaults.OPT_OUT, {
    forceCMP: true,
  })
}
