import isBlockableIframe from '../isBlockableIframe'
import replaceIframe from '../replaceIframe'


export default function findAndReplaceIframes({ consentConfig, supportedLocales }) {
  const iframes = Array.from(document.querySelectorAll('iframe'))

  iframes
    .filter(isBlockableIframe)
    .forEach((iframe) => {
      replaceIframe({
        consentConfig,
        iframe,
        supportedLocales,
      })
    })
}
