import initializeDisplayPreferencesButtons from 'utils/initializeDisplayPreferencesButtons'

import createPreferenceAppLauncher from './createPreferenceAppLauncher'


export default function initializePreferencesLink({ regionSetting, website }) {
  const launcher = createPreferenceAppLauncher({
    consentConfig: regionSetting,
    website,
  })

  const enableWidget = regionSetting.enable_widget

  initializeDisplayPreferencesButtons({
    handleClick: launcher,
    isEnabled: enableWidget,
  })

  // This is the legacy click handler for the preferences link/button. Since this
  // was how we used to instruct clients to set up this link, chances are that we
  // are forever going to be stuck with this, and we'll never know when it'll be
  // safe to kill it.
  //
  // In the old way of doing things, we simply wouldn't set the function on the
  // window object if the widget were disabled. I'd prefer that we just assign a
  // noop to it, but I don't want to break our customer's sites just in case some
  // of them have (inadvisedly) decided to wrap logic around the existence of this
  // function.
  if ( enableWidget ) {
    window.displayPreferenceModal = launcher
  }
}
