import {
  createContext,
  useContext,
} from 'react'

const WebsiteContext = createContext({})


export default WebsiteContext

export function useWebsite() {
  return useContext(WebsiteContext)
}
