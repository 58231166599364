import noop from 'utils/noop'

const isBlockedBySrc = isBlockedBy('src')
const isBlockedByHref = isBlockedBy('href')

const TAG_TESTS = {
  EMBED: isBlockedBySrc,
  IFRAME: isBlockedBySrc,
  IMG: isBlockedBySrc,
  LINK: isBlockedByHref,
  OBJECT: isBlockedBySrc,
  SCRIPT: isScriptBlocked,
}

/**
 * @param {HTMLElement} element
 *
 * @returns {Boolean} true if the element bears all the hallmarks of manual blocking
 */
export default function isElementManuallyBlocked(element) {
  if ( !(element instanceof Element) ) {
    throw new Error('Argument to isElementManuallyBlocked() must be an Element.' +
                    `Got ${ element }`)
  }

  if ( !element.dataset.categories ) {
    return false
  }

  const testElement = TAG_TESTS[element.tagName] || noop

  return testElement(element)
}

function isScriptBlocked(element) {
  return ( element.getAttribute('type') === 'text/plain' )
}

function isBlockedBy(attrName) {
  return (element) => {
    return Boolean(element.dataset[attrName] && !element.getAttribute(attrName))
  }
}
