import { useMemo } from 'react'

import Termly from 'termly-namespace'

import makeACModel from '../../lib/makeACModel'


export default function useACModel({ vendorIDs }) {
  return useMemo(() => {
    const storedACString = Termly.tcf?.getACString()

    return makeACModel({
      acString: storedACString,
      vendorIDs,
    })
  }, [vendorIDs])
}
