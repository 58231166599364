import Termly from 'termly-namespace'


// When we save the consents to the backend, it returns a response
// that we cache in localStorage. While we're rolling out the
// `consentOrigin` feature, we need to maintain absolute backwards
// compatibility so that if we have to roll back for whatever reason,
// users who have granted consent to the new consent origin will not
// lose their settings.
//
export default function convertConsentSaveResponse(response) {
  if ( !Termly.consentOrigin ) {
    return response
  }

  return {
    ...response.is_consent_granted_selections,
    ac_string: response.ac_string,
    document_version_id: response.document_version_id,
    event_type: response.display_style,
    gpc: response.gpc_enabled,
    tc_string: response.tc_string,
    template_version_id: response.document_version_id,
    visitor: {
      uuid: response.visitor.id,
    },
    visitor_id: response.visitor.id,
  }
}
