import Termly from 'termly-namespace'

import isElementManuallyBlocked from 'utils/isElementManuallyBlocked'

import { urlIsExempt } from '../utils/exemptList'

// N.B.: the trailing colon on each protocol is *crucial*
const BLOCKABLE_PROTOCOLS = new Set([
  'http:',
  'https:',
])


export default function isBlockableNode(node, src = '') {
  if ( Termly.isAutoBlockerPaused() ) {
    return false
  }

  if ( isElementManuallyBlocked(node) ) {
    return false
  }

  // data-autoblock-ignore
  if ( node.dataset.autoblockIgnore ) {
    return false
  }

  const attrSrc = src || node.getAttribute('src')
  if ( !attrSrc ) {
    return false
  }

  // The URL object will resolve relative links for us. We don't want
  // to block resources from our own host.
  const url = new URL(attrSrc, window.location)

  if ( !BLOCKABLE_PROTOCOLS.has(url.protocol) ) {
    return false
  }

  if ( url.host === window.location.host ) {
    return false
  }

  if ( Termly.tcf?.isDomainHandledByTCF(url.host) ) {
    return false
  }

  return !urlIsExempt(attrSrc)
}
