import mutationObserverCallback from './mutationObserverCallback'


export default function installTCFMutationObserver() {
  const observer = new MutationObserver(mutationObserverCallback)

  observer.observe(document.documentElement, {
    childList: true,
    subtree: true,
  })
}
