/**
 * Inspects `url.searchParams` to see if any key-value pairs match `config`.
 *
 * @param {Object} args
 * @param {Object} args.config - values may be {String, Object, Map}
 * @param {URL} args.url
 *
 * @returns {Object}
 */
export default function findQueryStringConfig({ config, url }) {
  if ( !config || typeof config === 'string' ) {
    return {
      config,
      url,
    }
  }

  for ( const [key, value] of url.searchParams ) {
    const paramConfig = config[key]

    if ( !paramConfig ) {
      continue
    }

    if ( typeof paramConfig === 'string' ) {
      return {
        config: paramConfig,
        url,
      }
    }

    const entries = ( paramConfig.entries ) ? paramConfig.entries() : Object.entries(paramConfig)

    for ( const [pattern, category] of entries ) {
      if ( pattern.test && pattern.test(value) || pattern === value ) {
        return {
          config: category,
          url,
        }
      }
    }

    return {
      config: ( paramConfig.get ) ? paramConfig.get('_default') : paramConfig._default,
      url,
    }
  }

  return {
    config: config._default,
    url,
  }
}
