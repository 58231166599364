import React from 'react'
import PropTypes from 'prop-types'

import ConsentConfigContext from './ConsentConfigContext'


export default function ConsentConfigProvider({ children, consentConfig }) {
  return (
    <ConsentConfigContext.Provider
      value={ consentConfig }
    >
      { children }
    </ConsentConfigContext.Provider>
  )
}

ConsentConfigProvider.propTypes = {
  children: PropTypes.node,
  consentConfig: PropTypes.shape({}),
}
