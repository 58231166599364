import axios from 'axios'

import URL from 'utils/url'

import { getWebsiteUuid } from 'utils/state'


export default function statisticViewApiCall(eventTypes) {
  const postURL = URL.statistic()

  return axios
    .post(postURL, {
      website_uuid: getWebsiteUuid(),
      event_types: eventTypes,
    })
    .catch(({ message }) => {
      console.error('Encountered %o when trying to post statistics to %s',
                    message,
                    postURL)
    })
}
