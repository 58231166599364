import axios from 'axios'

import ClientInfo from 'constants/client-info'
import WebsiteConfig from 'constants/website-config'

import URL from 'utils/url'

import onAllApiRespond from './onAllApiRespond'


export default function startProcess() {
  // N.B.: Order corresponds directly to the `.then()` handler, below
  const requests = [
    getWebsiteInfo(),
    getClientInfo(),
  ]

  return Promise.all(requests)
    .then(([websiteResult, countryResult]) => {
      onAllApiRespond({
        location: countryResult.data,
        websiteConfig: websiteResult.data,
      })
    })
    .catch((err) => {
      console.error('[Termly] error fetching configuration: %o', err)
    })
}

function getClientInfo() {
  // This'll be provided by the JS bundle API, but we have to maintain the
  // fallback behavior for those who are still using embed.min.js directly.
  if ( ClientInfo ) {
    return Promise.resolve({
      data: ClientInfo,
    })
  }

  return axios.get(URL.getCountry())
}

function getWebsiteInfo() {
  if ( WebsiteConfig?.website ) {
    return Promise.resolve({
      data: WebsiteConfig,
    })
  }

  // This is the call made with the legacy URL (/embed.min.js). We're
  // remapping it a bit to add a `website` key to make it forwards-
  // compatible with the new bundled delivery.
  return axios.get(URL.website())
    .then(({ data: website }) => ({
      data: {
        website,
      },
    }))
}
