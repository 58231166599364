import axios from 'axios'
import { TCString } from '@iabtechlabtcf/core'

import Termly from 'termly-namespace'

import ConsentDefaults from 'constants/consentDefaults'

import GTM from 'utils/gtm'
import URL from 'utils/url'
import enableConsentScripts from 'utils/enableConsentScripts'
import { makeTCModel } from 'utils/tcf-gvl'


export default async function consentAllForScanner() {
  if ( Termly.tcf ) {
    const { data: gvl } = await axios.get(URL.fullTcfGVL())

    const tcModel = makeTCModel(gvl)
    tcModel.setAll()

    const tcString = TCString.encode(tcModel)

    Termly.tcf.cmpApi.update(tcString)
  }

  enableConsentScripts()

  GTM.send(ConsentDefaults.OPT_OUT)
}
