import { useQuery } from '@tanstack/react-query'

import makeGetStrings from './lib/makeGetStrings'
import retry from './lib/retry'


export default function useLocaleStringsFile({ locale: originalLocale, urlTemplate }) {
  const locale = originalLocale.toLowerCase()

  const getStrings = makeGetStrings({
    locale,
    urlTemplate,
  })

  const {
    data = {},
    ...query
  } = useQuery(['useLocaleStringsFile', locale],
               getStrings,
               {
                 refetchOnWindowFocus: false,
                 retry,
                 staleTime: Infinity,
               })

  return {
    ...query,
    localeStrings: data.data,
  }
}
