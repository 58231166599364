import { defineMessages } from 'react-intl'


export default defineMessages({
  accept: {
    defaultMessage: 'Enable',
    id: 'blocked-content.enable',
  },

  managePrefs: {
    defaultMessage: 'Preferences',
    id: 'blocked-content.prefs',
  },

  toAllowContent: {
    defaultMessage: 'Manage preferences to always allow this content.',
    id: 'blocked-content.to-allow-content',
  },

  thirdPartyNotice: {
    defaultMessage: 'This content is served by a third party, <b>{ site }</b>. If enabled, <b>{ site }</b> may collect information about your activity.',
    id: 'blocked-content.notice',
  },

  title: {
    defaultMessage: 'Enable this content?',
    id: 'blocked-content.title',
  },
})
