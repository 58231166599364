import {
  createContext,
  useContext,
} from 'react'

const DisplayManagerContext = createContext()


export default DisplayManagerContext

export function useDisplayManager() {
  return useContext(DisplayManagerContext)
}
