// TODO?: Move this into utils/cookieConsents.js

import axios from 'axios'

import Termly from 'termly-namespace'

import CookieConsents from 'utils/cookieConsents'
import URL from 'utils/url'

import {
  setCookie,
  getCookie,
  uuidCookie,
} from 'utils/cookie'

import GTM from 'utils/gtm'
import enableConsentScripts from 'utils/enableConsentScripts'

import buildPostPayload from './buildPostPayload'
import convertConsentSaveResponse from './convertConsentSaveResponse'
import needsFullPageReload from './needsFullPageReload'
import triggerGetUpdatedCookieWhitelistByTermly from './triggerGetUpdatedCookieWhitelistByTermly'


export default function updateCookieConsent(args) {
  const {
    acString,
    cookiePolicy,
    eventType,
    consentMode,
    cookieConsent,
    tcString,
  } = args

  if ( !cookiePolicy ) {
    return () => {
      console.info('Not saving Termly consents: No cookie policy found.')
    }
  }

  const needsReload = needsFullPageReload({
    cookieConsent,
    consentMode,
  })

  return function saveCookieConsent() {
    const userCookie = getCookie(uuidCookie)

    const payload = buildPostPayload({
      acString,
      cookieConsent,
      displayStyle: eventType,
      documentVersionID: cookiePolicy.published_document_version_id,
      tcString,
      templateVersionID: cookiePolicy.accepted_template_version,
      userID: userCookie,
    })

    axios
      .post(URL.saveCookieConsent(), payload)
      .then(({ data }) => {
        const consents = convertConsentSaveResponse(data)

        const visitorID = consents.visitor_id

        // TODO: why are we invoking this again?
        if ( !getCookie(uuidCookie) ) {
          setCookie(uuidCookie, visitorID)
        }

        CookieConsents.setConsents(consents)
        GTM.send(cookieConsent)

        triggerGetUpdatedCookieWhitelistByTermly({
          consentMode,
          consentState: CookieConsents.getConsentSettings(),
          cookieConsent,
          cookies: Termly.cookies,
          visitorId: visitorID,
        })

        if ( needsReload ) {
          window.location.reload()
        } else {
          const consentedCategories = Object.entries(cookieConsent)
            .filter(([category, isConsented]) => isConsented)
            .map(([category, isConsented]) => category)

          enableConsentScripts(consentedCategories)
        }
      })
  }
}
