/**
 * Attempts to find a hostname match in the top-level keys of `config`. If a match is found,
 * the returned `config` will be the value associated with the matched hostname, which may
 * be a string or an object.
 *
 * @param {Object} args
 * @param {Object} args.config - undefined if no match is found
 * @param {URL} args.url
 *
 * @returns {Object}
 */
export default function findHostnameConfig({ config, url }) {
  const findConfig = (hostname) => {
    if ( !hostname ) {
      return
    }

    if ( config[hostname] ) {
      return config[hostname]
    }

    const firstDotIndex = hostname.indexOf('.')

    if ( firstDotIndex < 0 ) {
      return
    }

    return findConfig(hostname.slice(firstDotIndex + 1))
  }

  return {
    config: findConfig(url.hostname),
    url,
  }
}

