import { isAutoBlocked } from '../utils/getters'
import { isBlockable } from '../utils/blockingList'


/**
 * @param {Element} node
 * @param {String} [src=node.getAttribute('src')] the value of the `src`
 *   attribute that is about to be set on `node`. This is necessary when
 *   creating an Image object and assigning a URL to its `src` attribute.
 *
 * @returns {Boolean} true if the element needs to be blocked, false otherwise
 */
export default function needsBlocking(node, src) {
  if ( isAutoBlocked(node) ) {
    return false
  }

  const attrSrc = src || node.getAttribute('src')

  // The URL object will resolve relative links for us. We don't want
  // to block resources from our own host.
  const url = new URL(attrSrc, window.location)

  return isBlockable(url, node.dataset.categories)
}
