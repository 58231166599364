const LISTENERS = {
  consent: [],
  initialized: [],
}

export default {
  dispatch(event, payload) {
    _getListeners(event)?.forEach((callback) => callback(payload))
  },

  on(event, callback) {
    if ( typeof callback !== 'function' ) {
      console.warn('[Termly] Callback %o is not a function. Ignoring.', callback)
      return
    }

    _getListeners(event)?.push(callback)
  },

  off(event, callback) {
    const listeners = _getListeners(event)

    if ( !listeners ) {
      return
    }

    LISTENERS[event] = listeners.filter((fn) => {
      return ( fn !== callback )
    })
  },
}

function _getListeners(event) {
  const listeners = LISTENERS[event]

  if ( !listeners ) {
    console.warn('[Termly] %o is not a known event. Known events are: %s',
                 event,
                 Object.keys(LISTENERS).sort())
    return
  }

  return listeners
}
