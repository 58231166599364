import makeGetFile from './makeGetFile'


export default function makeGetStrings({ locale, urlTemplate }) {
  const getFile = makeGetFile(urlTemplate)

  return async () => {
    try {
      return await getFile(locale)
    } catch (error) {
      const [language, region] = locale.split('-')

      if ( !region ) {
        throw error
      }

      console.debug('Could not find translations for %o, trying %o',
                    locale,
                    language)

      return getFile(language)
    }
  }
}
