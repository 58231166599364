import {
  getItem,
  setItem,
} from 'utils/localStorage'

const AC_STRING_KEY = 'gacm_ac_string'
const CCF_KEY = 'consentable_content_version'
const TCF_SETTINGS_KEY = 'tcf_settings'
const TC_STRING_KEY = 'tcf_tc_string'


export function clearTCFStore() {
  saveTCString('')
  saveTCFConsentableContentVersion('')
}

export function getACString() {
  return getItem(AC_STRING_KEY) || ''
}

export function getTCString() {
  // The string default value means "GDPR applies, but we have no TC string." If we
  // were to pass along `null` it would mean "GDPR does not apply. We've made the
  // assumption that if we're here in this code, the determination has already been
  // made that GDPR applies and that the user has opted into TCF support.
  return getItem(TC_STRING_KEY) || ''
}

export function saveACString(acString) {
  setItem(AC_STRING_KEY, acString)
}

export function saveTCString(tcString) {
  setItem(TC_STRING_KEY, tcString)
}

export function getTCFConsentableContentVersion() {
  return getItem(CCF_KEY)
}

export function saveTCFConsentableContentVersion(version) {
  return setItem(CCF_KEY, version)
}

export function getTCFSettings() {
  return getItem(TCF_SETTINGS_KEY)
}

export function saveTCFSettings(settings) {
  return setItem(TCF_SETTINGS_KEY, settings)
}
