import {
  USES_IAB_TCF,
  GVL_METADATA,
} from './constants'


export default function getGVLMetadata() {
  if ( !USES_IAB_TCF ) {
    return
  }

  return GVL_METADATA
}
