import { DISABLED_PREF_CLASS_NAME } from './constants'


export default function createClickHandlerInstaller({ handleClick, isEnabled }) {
  const disabled = ( !isEnabled || !handleClick )

  const onclick = (e) => {
    if ( disabled ) {
      return
    }

    e.preventDefault()
    e.stopPropagation()

    handleClick()
  }

  return (elem) => {
    elem.onclick = onclick
    elem.disabled = disabled

    if ( disabled ) {
      elem.classList.add(DISABLED_PREF_CLASS_NAME)
    } else {
      elem.classList.remove(DISABLED_PREF_CLASS_NAME)
    }
  }
}
