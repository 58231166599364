import ACModel from './ac-model'

const AC_STRING_SPEC_VERSION = 2
const DISCLOSED_VALUES_PREFIX = 'dv.'
const SEPARATOR = '~'
const VENDOR_DELIMITER = '.'


export default {
  decode(acString, acModel) {
    const model = acModel || new ACModel()

    if ( acString ) {
      const {
        consented,
        disclosed,
      } = parseACString(acString)

      consented.forEach((vendorID) => model.set(vendorID))
      disclosed.forEach((vendorID) => model.unset(vendorID))
    }

    return model
  },

  encode(acModel) {
    if ( !acModel.size ) {
      return
    }

    const vendorIDs = acModel.reduce(consentReducer, {
      consented: [],
      disclosed: [],
    })

    const consented = makeConsentedString(vendorIDs.consented)
    const disclosed = makeDisclosedString(vendorIDs.disclosed)

    return [
      AC_STRING_SPEC_VERSION,
      consented,
      disclosed,
    ].filter(Boolean).join(SEPARATOR)
  },
}

function consentReducer(collector, { isConsented, vendorID }) {
  const key = ( isConsented ) ? 'consented' : 'disclosed'

  const values = collector[key].concat(vendorID)

  return {
    ...collector,
    [key]: values,
  }
}

function makeConsentedString(vendorIDs) {
  return joinSortedIDs(vendorIDs)
}

function makeDisclosedString(vendorIDs) {
  if ( !vendorIDs.length ) {
    return
  }

  return DISCLOSED_VALUES_PREFIX + joinSortedIDs(vendorIDs)
}

function joinSortedIDs(vendorIDs) {
  return vendorIDs
    .sort((a, b) => a - b)
    .join(VENDOR_DELIMITER)
}

function parseACString(acString) {
  const [
    stringVersion,
    ...vendorIDs
  ] = acString.split(SEPARATOR)

  if ( Number(stringVersion) !== AC_STRING_SPEC_VERSION ) {
    throw new Error('Invalid AC string version found. ' +
                    `Was "${ stringVersion }, expected "${ AC_STRING_SPEC_VERSION }"`)
  }

  const initialValue = {
    consented: [],
    disclosed: [],
  }

  return vendorIDs.reduce((collector, item) => {
    const key = ( item.startsWith(DISCLOSED_VALUES_PREFIX) ) ? 'disclosed' : 'consented'

    const values = item.split(VENDOR_DELIMITER)
      .map(Number)
      .filter(Boolean)

    return {
      ...collector,
      [key]: values,
    }
  }, initialValue)
}
