import IntlProvider, {
  useIntl,
} from './IntlProvider'

export default IntlProvider

export {
  useIntl,
}

export { default as selectLocale } from './lib/selectLocale'
