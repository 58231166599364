import {
  createContext,
  useContext,
} from 'react'

const GACMContext = createContext({})


export default GACMContext

export function useGACM() {
  return useContext(GACMContext)
}
