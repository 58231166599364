import { TERMLY_STATISTIC } from './index'

import {
  genTltplId,
  getCookie,
} from './cookie'

import { clearAPICache } from './localStorage'


// If localStorage has information in it from another website, we need
// to wipe it clean and start afresh; otherwise, the backend will blow
// up [TER-12158].
//
export default function sanitizeLocalStorage(websiteUuid) {
  const previousValue = getCookie(TERMLY_STATISTIC)

  if ( !previousValue ) {
    return
  }

  const newValue = genTltplId(websiteUuid)

  if ( previousValue !== newValue ) {
    console.debug('[Termly] Clearing API cache because tltpl changed (%o vs. %o) for website UUID %o',
                  previousValue,
                  newValue,
                  websiteUuid)

    clearAPICache()
  }
}
