export default function installMutationObserver({ className, install, root }) {
  new MutationObserver((mutations) => {
    mutations.forEach(({ addedNodes }) => {
      addedNodes.forEach((node) => {
        if ( node.nodeType !== Node.ELEMENT_NODE ) {
          return
        }

        if ( !node.className ) {
          return
        }

        if ( node.classList.contains(className) ) {
          install(node)
        }
      })
    })
  }).observe(root, {
    childList: true,
    subtree: true,
  })
}
