import getNamesForIDs from './getNamesForIDs'
import getPrivacyPolicyURL from './getPrivacyPolicyURL'


export default class GVLPresenter {
  constructor({ lang, vendorList }) {
    this.lang = lang
    this.gvl = vendorList
  }

  getPurposeByID(purposeID) {
    return this.gvl.purposes[purposeID]
  }

  getPurposes() {
    return Object.values(this.gvl.purposes)
  }

  getPurposesVendorCounts() {
    return this._purposeVendorCounts
      ||= this._collectVendorCountsForVendorAttribute('purposes')
  }

  getSpecialFeatureByID(specialFeatureID) {
    return this.gvl.specialFeatures[specialFeatureID]
  }

  getSpecialFeatures() {
    return Object.values(this.gvl.specialFeatures)
  }

  getSpecialFeaturesVendorCounts() {
    return this._specialFeaturesVendorCounts
      ||= this._collectVendorCountsForVendorAttribute('specialFeatures')
  }

  getVendorByID(vendorID) {
    const vendor = this.gvl.vendors[vendorID]

    if ( !vendor ) {
      return
    }

    const dataDeclaration = getNamesForIDs(vendor.dataDeclaration,
                                           this.gvl.dataCategories)

    const features = getNamesForIDs(vendor.features, this.gvl.features)

    const purposes = getNamesForIDs(vendor.purposes, this.gvl.purposes)

    const specialFeatures = getNamesForIDs(vendor.specialFeatures,
                                           this.gvl.specialFeatures)

    const specialPurposes = getNamesForIDs(vendor.specialPurposes,
                                           this.gvl.specialPurposes)


    const privacyPolicyURL = getPrivacyPolicyURL({
      lang: this.lang,
      vendorURLs: vendor.urls,
    })

    return {
      cookieMaxAgeSeconds: vendor.cookieMaxAgeSeconds,
      cookieRefresh: vendor.cookieRefresh,
      dataDeclaration,
      dataRetention: vendor.dataRetention,
      features,
      id: vendor.id,
      name: vendor.name,
      privacyPolicyURL,
      purposes,
      specialFeatures,
      specialPurposes,
      storageDisclosures: vendor.storageDisclosures,
      usesCookies: vendor.usesCookies,
      usesNonCookieAccess: vendor.usesNonCookieAccess,
    }
  }

  getVendors() {
    return Object.values(this.gvl.vendors)
  }

  getVendorsByPurposeID(purposeID) {
    return this.getVendors()
      .filter(({ purposes }) => {
        // Lovely...O(1) again
        return purposes.includes(purposeID)
      })
  }

  getVendorsBySpecialFeatureID(specialFeatureID) {
    return this.getVendors()
      .filter(({ specialFeatures }) => {
        // Lovely...O(1) again
        return specialFeatures.includes(specialFeatureID)
      })
  }

  getVendorCount() {
    return this.getVendors().length
  }

  //
  // 'PRIVATE' METHODS
  //

  _collectVendorCountsForVendorAttribute(key) {
    const counts = {}

    // I'm guessing that real-world scenarios will involve fewer vendors
    // than there are purposes (which is currently 11). If this proves to
    // be backwards, we may want to invert the order of these loops.
    for ( const vendor of this.getVendors() ) {
      for ( const id of vendor[key] ) {
        if ( counts[id] === void 0 ) {
          counts[id] = 1
        } else {
          counts[id] += 1
        }
      }
    }

    return counts
  }
}
