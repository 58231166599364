import findAndReplaceIframes from  './findAndReplaceIframes'
import isBlockableIframe from './isBlockableIframe'
import replaceIframe from './replaceIframe'


export default function replaceAndWatchForIframes({ consentConfig, supportedLocales }) {
  findAndReplaceIframes({
    consentConfig,
    supportedLocales,
  })

  // Now that we've replaced all iframes that *currently* exist in the DOM,
  // we need to "future proof" the DOM to watch for additional iframes that
  // may be added later.
  new MutationObserver((mutations) => {
    mutations.forEach(({ addedNodes }) => {
      Array.from(addedNodes)
        .filter(isBlockableIframe)
        .forEach((iframe) => {
          replaceIframe({
            consentConfig,
            iframe,
            supportedLocales,
          })
        })
    })
  }).observe(document.documentElement, {
    childList: true,
    subtree: true,
  })
}
