// eslint-disable-next-line import/no-named-as-default
import Axios from 'axios'

import Cookie from '../cookie'

import requestInterceptor from './requestInterceptor'

const BASE_URL = '/api'

const client = Axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  xsrfCookieName: Cookie.csrfCookieName,
  xsrfHeaderName: Cookie.csrfHeaderName,
})

client.interceptors.request.use(requestInterceptor)

/**
 * @returns {Object} an Axios singleton instance
 *
 * @see https://axios-http.com/docs/instance
 */
export default client
