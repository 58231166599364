/**
 * @param {Object[]} regionSettings - contains at least a `region` property
 * @param {String} [countryCode]
 * @param {String} [state]
 *
 * @returns {Object} the element from `regionSettings` that best fits the countryCode and state
 */
export default function selectRegionSetting(regionSettings, { continent, country = '', state = ''}) {
  if ( !regionSettings ) {
    return {}
  }

  const settingsByRegion = regionSettings.reduce((collector, item) => ({
    ...collector,
    [item.region]: item,
  }), {})

  if ( country === 'US' ) {
    const lcState = state.toLowerCase()
    return settingsByRegion[lcState] || settingsByRegion.us
  }

  if ( continent === 'EU' ) {
    return settingsByRegion.eu
  }

  const lcCountry = country.toLowerCase()

  return settingsByRegion[lcCountry] || settingsByRegion.global
}
