import Termly from 'termly-namespace'


export default function buildPostPayload(args) {
  const {
    acString,
    cookieConsent,
    displayStyle,
    documentVersionID,
    tcString,
    templateVersionID,
    userID,
  } = args

  if ( !Termly.consentOrigin ) {
    return {
      ac_string: acString,
      event_type: displayStyle,
      document_version_id: documentVersionID,
      tc_string: tcString,
      template_version_id: templateVersionID,
      ...cookieConsent,
      ...(!!userID && { uuid: userID }),
    }
  }

  const {
    do_not_sell: doNotSell,
    gpc,
    ...grantedSelections
  } = cookieConsent

  // The consent service requires these fields to be set...we can't
  // just leave 'em undefined, so we cast them to booleans.
  const ccpaDoNotSell = Boolean(doNotSell)
  const gpcEnabled = Boolean(gpc)
  const visitor = makeVisitor(userID)

  return {
    ac_string: acString,
    ccpa_do_not_sell: ccpaDoNotSell,
    display_style: displayStyle,
    document_version_id: documentVersionID,
    gpc_enabled: gpcEnabled,
    is_consent_granted_selections: grantedSelections,
    tc_string: tcString,
    template_version_id: templateVersionID,
    ...visitor,
  }
}

function makeVisitor(id) {
  if ( !id ) {
    return
  }

  return {
    visitor: {
      id,
    },
  }
}
