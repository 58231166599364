const REGEX = /^[A-Za-z0-9-]+$/


export default function cleanWebsiteUUID(websiteUUID) {
  if ( !REGEX.test(websiteUUID) ) {
    console.warn('[Termly] Rejecting website UUID %o because it contains invalid characters',
                 websiteUUID)
    return
  }

  return websiteUUID
}
