import Locale from './locale'

const ESSENTIAL = 'essential'
const PERFORMANCE = 'performance'
const ANALYTICS = 'analytics'
const ADVERTISING = 'advertising'
const SOCIAL_NETWORKING = 'social_networking'
const UNCLASSIFIED = 'unclassified'

// N.B.: Order here determines display order
// TODO: get stop using `name` key, use `message` instead
const CATEGORIES = [
  {
    id: ESSENTIAL,
    message: Locale.essential,
  },
  {
    id: ADVERTISING,
    message: Locale.advertising,
  },
  {
    id: ANALYTICS,
    message: Locale.analytics,
  },
  {
    id: PERFORMANCE,
    message: Locale.performance,
  },
  {
    id: SOCIAL_NETWORKING,
    message: Locale.social_networking,
  },
  {
    id: UNCLASSIFIED,
    message: Locale.unclassified,
  },
]


/**
 * @name CookieCategories
 */
export default {
  ADVERTISING,
  ANALYTICS,
  ESSENTIAL,
  PERFORMANCE,
  SOCIAL_NETWORKING,
  UNCLASSIFIED,

  isValidCategory(categoryID) {
    return CATEGORIES.some(({ id }) => id === categoryID)
  },

  map(fn) {
    return CATEGORIES.map(fn)
  },

  // TODO: Once things are cleaned up a bit more, maybe we should
  // switch this to a function that takes the name and the count and
  // returns the string. The current use case I'm refactoring handles
  // the conversion to a string at a lower level than I want to touch
  // right now.
  //
  getCategoryMessageForValue(value) {
    const message = Locale[`${ value }WithCount`]

    if ( message ) {
      return message
    }

    throw new Error(`Unrecognized category value: ${ JSON.stringify(value) }`)
  },

  reduce(callbackFn, initialValue) {
    return CATEGORIES.reduce(callbackFn, initialValue)
  },
}
