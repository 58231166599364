import { Client } from '@termly_web/common'


export default function makeGetFile(urlTemplate) {
  return (locale) => {
    const url = urlTemplate.replace(':locale', locale)

    return Client.get(url, {
      baseURL: '/',
      withCredentials: false,
    })
  }
}
