import React, {
  useEffect,
} from 'react'

import App from 'components/App'

import useSaveConsent from 'hooks/useSaveConsent'


// When a GPC "signal" is detected, we want to basically pretend that
// the user has clicked the "do not sell" checkbox and declined
// consent of all the things. Unforunately, *all* of the code that
// handles these operations finds its home in Providers and hooks
// which, of course, cannot be run outside of the context of a React
// component. So, rather than take a machete to all of our consent
// code, extract it into a library (or libraries) and run the risk of
// breaking stuff, I've decided to take the much safer, though perhaps
// unconventional, approach of creating this component which invokes
// the necessary hook-based functions, but does not render a UI.
//
export default function GPCHandlerApp({ consentConfig, website }) {
  return (
    <App
      consentConfig={ consentConfig }
      website={ website }
    >
      <Content />
    </App>
  )
}

GPCHandlerApp.propTypes = {
  consentConfig: App.propTypes.consentConfig,
  website: App.propTypes.website,
}

// We have to use this private component because all of the providers
// that useSaveContent() relies upon are set up in App
//
function Content() {
  const { declineAll } = useSaveConsent()

  useEffect(() => {
    declineAll({
      do_not_sell: true,
      gpc: true,
    })
  }, [declineAll])

  return null
}
