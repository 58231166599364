import {
  GVL,
  TCModel,
  TCString,
} from '@iabtechlabtcf/core'

import {
  CMP_ID,
  CMP_VERSION,
  USES_SERVICE_SPECIFIC_SCOPE,
} from './constants'

import { getTCString } from './tcfStore'


export default function makeTCModel(globalVendorList) {
  // N.B.: instantiating this has the unfortunate side-effect of
  // creating window.__tcfapi. Or, perhaps more accurately, it
  // replaces the expected stub implementation of __tcfapi() with a
  // fully-functional implementation
  const gvl = new GVL(globalVendorList)

  const tcModel = new TCModel(gvl)
  tcModel.cmpId = CMP_ID
  tcModel.cmpVersion = CMP_VERSION
  tcModel.isServiceSpecific = USES_SERVICE_SPECIFIC_SCOPE

  const tcString = getTCString()

  if ( tcString ) {
    return TCString.decode(tcString, tcModel)
  }

  return tcModel
}
