import { ESSENTIAL } from 'constants/categories'
import { GCM_TO_TERMLY } from 'constants/gcm'


/**
 * Note that omitted categories in `termlyConsents` will be treated as
 * "denied" values in the returned object.
 *
 * @param {Object} [termlyConsents] - mapping of Termly category to
 *   boolean consent value
 *
 * @returns {Object} mapping of GTM category to equivalent string value.
 */
export default function convertTermlyConsentsToGTM(termlyConsents = {}) {
  const gtmConsents = {}

  for ( const [gcmCategory, termlyCategory] of Object.entries(GCM_TO_TERMLY) ) {
    const isConsented = ( termlyCategory === ESSENTIAL ) ? true : termlyConsents[termlyCategory]

    gtmConsents[gcmCategory] = boolToName(isConsented)
  }

  return gtmConsents
}

function boolToName(bool) {
  return ( bool ) ? 'granted' : 'denied'
}
