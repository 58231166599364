import { ENGLISH } from 'constants/language'


export default function getPrivacyPolicyURL({ lang, vendorURLs }) {
  const urlsByLang = vendorURLs.reduce((collector, { langId, ...urls }) => ({
    ...collector,
    [langId]: urls,
  }), {})

  const urls = urlsByLang[lang] || urlsByLang[ENGLISH] || vendorURLs[0]

  return urls?.privacy
}
