// TODO: Seems a bit silly to have the constants in a file in another directory.
import exemptList from '../constants/exemptList'

/**
 * @param {String} url
 *
 * @returns {Boolean}
 */
export function urlIsExempt(url) {
  if ( !url ) {
    return false
  }

  const testURL = coerceIntoString(url)

  // TODO: Something tells me that simply matching on substring is a bad
  // idea. What if someone crafts a url like `http://foo.com/apis.google.com/js/snapple`?
  return exemptList.some((exempt) => {
    return testURL.includes(exempt)
  })
}

// In TER-8960, we ran across a situation where the url supplied to
// urlIsExempt() is a TrustedScriptURL, which does not have a
// `.includes()` method. Support for this type is really spotty
// (https://caniuse.com/mdn-api_trustedscripturl), so I am going to
// prefer feature checking over type checking because it's more
// obvious what I'm looking for and is more future proof.
function coerceIntoString(url) {
  if ( url.includes ) {
    return url
  }

  if ( url.toString ) {
    return url.toString()
  }

  // Since even Object has a toString() defined on it, and most normal
  // objects eventually inherit from Object, it should not be possible
  // to get to this point. But it *is* possible, so I'm going to be
  // paranoid and handle it anyway.
  console.debug('Supplied url (%o) does not have a .toString(). Coercing to an empty string',
                JSON.stringify(url))
  return ''
}
