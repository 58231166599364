/**
 * Attempts to find a pathname match in the top-level keys of `config`. If a match is found,
 * the returned `config` will be the value associated with the matched pathname, which may
 * be a string or an object.
 *
 * IMPORTANT: the `url.pathame` is treated literally...so '/foo/bar/' will NOT match
 * '/foo/bar' and visa versa.
 *
 * @param {Object} args
 * @param {Object} args.config - undefined if no match is found
 * @param {URL} args.url
 *
 * @returns {Object}
 */
export default function findPathnameConfig({ config, url }) {
  if ( !config || typeof config === 'string' ) {
    return {
      config,
      url,
    }
  }

  return {
    config: config[url.pathname] || config._default,
    url,
  }
}

