export const CMP_ID = 412
export const CMP_VERSION = 1
export const USES_SERVICE_SPECIFIC_SCOPE = true

//
// N.B.: Don't try to import the following from a lazily-loaded file, because
// __TERMLY_*__ constants are only defined in the primary file returned by
// `/resource-blocker/:uuid/tcf-gvl`
//
// If you add another __TERMLY_* variable, you'll need to add it to
// the .eslintrc.js so that the linter won't complain about it.
//

export const GVL_METADATA = ( typeof __TERMLY_GLOBAL_VENDOR_LIST_METADATA__ === 'undefined' )
  ? void 0
  : __TERMLY_GLOBAL_VENDOR_LIST_METADATA__

export const USES_IAB_TCF = ( typeof __TERMLY_WEBSITE_CONFIG__ === 'undefined' )
  ? false
  : __TERMLY_WEBSITE_CONFIG__.enabled_frameworks?.includes('tcf')

export const CONSENTABLE_CONTENT_VERSION =  ( typeof __TERMLY_CONSENTABLE_CONTENT_VERSION__ === 'undefined' )
  ? GVL_METADATA?.consentable_content_version
  : __TERMLY_CONSENTABLE_CONTENT_VERSION__

