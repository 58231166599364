import Categories, {
  ESSENTIAL,
} from 'constants/categories'


//
// TODO: write tests for this someday
//
export default function getConsentedCategories({ consentMode, cookieConsent }) {
  if ( !cookieConsent ) {
    return ( consentMode === 'opt_in' ) ? [ESSENTIAL] : Categories.values
  }

  return Object.entries(cookieConsent)
    .filter(([category, isConsented]) => Categories.has(category))
    .filter(([category]) => cookieConsent[category])
    .map(([category]) => category)
}

