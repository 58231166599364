import React from 'react'

import UINames from 'constants/ui-names'

import { useDisplayManager } from 'components/DisplayManager'

// The lazy wrappers make testing possible
import LazyConsentPrompt from './LazyConsentPrompt'
import LazyCookieDetails from './LazyCookieDetails'
import LazyGACMDetails from './LazyGACMDetails'
import LazyDocumentModal from './LazyDocumentModal'
import LazyPreferenceModal from './LazyPreferenceModal'
import LazyTCFDetails from './LazyTCFDetails'

const DISPLAY_MAP = {
  [UINames.COOKIE_DETAILS]: LazyCookieDetails,
  [UINames.DOC_MODAL]: LazyDocumentModal,
  [UINames.GACM_DETAILS]: LazyGACMDetails,
  [UINames.PREFERENCES]: LazyPreferenceModal,
  [UINames.PROMPT]: LazyConsentPrompt,
  [UINames.TCF_DETAILS]: LazyTCFDetails,
}


export default function ResourceBlocker() {
  const { activeUI } = useDisplayManager()

  const Component = DISPLAY_MAP[activeUI.name]

  if ( !Component ) {
    return null
  }

  return (
    <Component
      { ...activeUI.props }
    />
  )
}
